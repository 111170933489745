import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { isTokenExpired, refreshAccessToken, logoutUser } from "./Components/utils/auth";

const RequireAuth = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      let accessToken = Cookies.get("AccessToken");

      if (!accessToken || isTokenExpired(accessToken)) {
        const refreshed = await refreshAccessToken();
        if (!refreshed) {
          logoutUser(navigate); 
          return;
        }
        accessToken = Cookies.get("AccessToken");
      }
    };

   
    checkAuth();

    
    const intervalId = setInterval(async () => {
      let accessToken = Cookies.get("AccessToken");

      if (!accessToken || isTokenExpired(accessToken)) {
        const refreshed = await refreshAccessToken();
        if (!refreshed) {
          logoutUser(navigate); 
          clearInterval(intervalId); 
        }
      }
    }, 300000); 

    
    return () => clearInterval(intervalId);
  }, [navigate]);

  return children;
};

export default RequireAuth;
