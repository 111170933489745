import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Typography,
  message,
  Select,
} from "antd";

import React, { useEffect, useState } from "react";
import "./editModal.css";
import axios from "axios";
import Cookies from "js-cookie";
import { isTokenExpired, refreshAccessToken, logoutUser } from "../utils/auth";
import { useForm } from "antd/es/form/Form";
import CONSTANTS from "../../constants/variables";

const { Text } = Typography;
const { Option } = Select;
let accessToken = Cookies.get("AccessToken");

function EditPortfolioOverviewModel({
  visible,
  onClose,
  customer,
  viewOnly = false,
  CustomerId,
}) {
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});

  const updateData = async (values) => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const changedFields = Object.keys(values).reduce((acc, key) => {
        if (values[key] !== initialValues[key]) {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      const portfolioTypeId =
        portfolioList?.find(
          (portfolio) => portfolio.portfolio === values.model_portfolio
        )?.name || "";

      // If portfolio type ID is changed, add it to the payload
      if (changedFields.model_portfolio) {
        changedFields.portfolio_type_id = portfolioTypeId;
      }

      const response = await axios.post(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.update_customer_details`,
        {
          doc_id: CustomerId,
          data: {
            email: customer?.email || "",
            mobile: customer?.mobile || "",
            portfolio_type_id: portfolioTypeId,
            ...changedFields,
          },
        },
        {
          headers: headers,
        }
      );
      message.success(response?.data?.message?.msg);
      onClose();
    } catch (err) {
      message.error(err?.response?.data?.message?.msg);
    }
  };

  useEffect(() => {
    if (customer && form) {
      const initialFormValues = {
        portfolio_val: customer?.portfolio_val || 0,
        sip_amount: customer?.sip_amount || 0,
        risk_profile: customer?.risk_profile || "Not Assigned",
        model_portfolio: customer?.model_portfolio || "Not Assigned",
        invest_sip_into: customer?.invest_sip_into || "",
      };
      setInitialValues(initialFormValues); // Store initial values
      form.setFieldsValue(initialFormValues);
    }
  }, [customer, form]);

  // Fetching Portfolio List
  const [portfolioList, setPortfolioList] = useState([]);

  useEffect(() => {
    let accessToken = Cookies.get("AccessToken");
    const apiUrl =
      `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_all_portfolios`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setPortfolioList(response?.data?.message?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <Modal
      visible={visible}
      footer={null}
      title={"Portfolio Overview"}
      className="editModal edit"
      onCancel={onClose}
    >
      <Form
        layout="vertical"
        className="editForm"
        form={form}
        onFinish={updateData}
        initialValues={customer}
      >
        <Form.Item
          className="nameLabel"
          label="Portfolio Value"
          name="portfolio_val"
        >
          <Input
            placeholder="Enter your Portfolio Value"
            style={{ width: "100%" }}
            className="Input"
            readOnly
          />
        </Form.Item>
        <Form.Item className="nameLabel" label="SIP Amount" name="sip_amount">
          <Input
            placeholder="Enter your SIP Amount"
            style={{ width: "100%" }}
            className="Input"
            readOnly={viewOnly}
          />
        </Form.Item>
        <Form.Item
          className="nameLabel"
          label="Risk Profile"
          name="risk_profile"
        >
          <Select
            placeholder="Select your Risk Profile"
            style={{ width: "100%" }}
          >
            <Option value="Moderately Conservative">
              Moderately Conservative
            </Option>
            <Option value="Conservative">Conservative</Option>
            <Option value="Balanced">Balanced</Option>
            <Option value="Moderately Aggressive">Moderately Aggressive</Option>
            <Option value="Aggressive">Aggressive</Option>
            <Option value="Not Assigned">Not Assigned</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Model Portfolio"
          name="model_portfolio"
          className="nameLabel"
        >
          <Select
            placeholder="Select your Modal Portfolio"
            className="Input"
            onChange={(e) => {
              form.setFieldsValue({
                invest_sip_into: "model",
              });
            }}
          >
            {portfolioList.map((option) => (
              <Option value={option?.portfolio}>{option?.portfolio}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Invest SIP into"
          name="invest_sip_into"
          className="nameLabel"
        >
          <Select placeholder="Select SIP Option" className="Input">
            <Option value="existing">Exisiting Portfolio</Option>
            <Option value="model">Model Portfolio</Option>
          </Select>
        </Form.Item>

        {viewOnly ? (
          <></>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              padding: "0px 20px",
              marginTop: "2rem",
            }}
          >
            <Button
              style={{ borderColor: "#00A971", color: "#00A971", width: "40%" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              // onClick={updateData}
              style={{
                backgroundColor: "#00A971",
                borderColor: "#00A971",
                width: "40%",
              }}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
}

export default EditPortfolioOverviewModel;
