import React, { useEffect, useState } from "react";
import { ReactComponent as Portfolio } from "../../Image/assets/portfolioManagement.svg";
import { ReactComponent as Winsight } from "../../Image/assets/winsight.svg";
import { ReactComponent as Customers } from "../../Image/assets/customerNew.svg";
import { ReactComponent as Filters } from "../../Image/assets/filterManagement.svg";
import { ReactComponent as StockListing } from "../../Image/assets/stock lisiting.svg";
import { ReactComponent as RequestManager } from "../../Image/assets/request manager.svg";
import { ReactComponent as RightArrow } from "../../Image/assets/rightArrow.svg";
import { ReactComponent as LeftArrow } from "../../Image/assets/leftArrow.svg";
import { ReactComponent as Logout } from "../../Image/assets/Logout.svg";
import { ReactComponent as DocumentManager } from "../../Image/assets/Document Mangt.svg";
import styles from "./portfolio.module.scss";
import "./addPortfolioModal.css";
import { Button, Layout, Menu } from "antd";
import { Routes, useLocation, useNavigate } from "react-router-dom";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children, style) {
  return {
    key,
    icon,
    children,
    label,
  };
}

function PortfolioLayout(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("1");
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    // Sync the selectedKey based on the current location path
    switch (location.pathname) {
      case "/customers":
        setSelectedKey("1");
        break;
      case "/stock-analyser":
        setSelectedKey("2");
        break;
      case "/portfolio-management":
        setSelectedKey("3");
        break;
      case "/model-portfolio-details":
        setSelectedKey("3");
        break;
      case "/strategy-management":
        setSelectedKey("4");
        break;
      case "/filter-management":
        setSelectedKey("5");
        break;
      case "/create-filter-management":
        setSelectedKey("5");
        break;
      case "/request-manager":
        setSelectedKey("6");
        break;
      case "/document-manager":
        setSelectedKey("7");
        break;
      case "/attach-doc":
        setSelectedKey("8");
        break;
      // case "/initiate-adhoc-transaction":
      //   setSelectedKey("6");
      //   break;
      default:
        setSelectedKey("1"); // Default key
    }
  }, [location.pathname]);

  const items = [
    getItem("Customers", "1", <Customers />),
    getItem("Stock Listing", "2", <StockListing />),
    getItem("Portfolio Management", "3", <Portfolio />),
    // getItem(
    //   "Strategy Management",
    //   "4",
    //   selectedKey === "4" ? <StrategyGreen /> : <StrategyIcon />
    // ),
    getItem("Filter Management", "5", <Filters />),
    getItem("Request Manager", "6", <RequestManager style={{width:"26px", height:"22px"}} />),
    getItem("Document Manager", "7", <DocumentManager style={{width:"26px", height:"22px"}} />),
  ];

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);

    switch (e.key) {
      case "1":
        navigate("/customers");
        break;
      case "2":
        navigate("/stock-analyser");
        break;
      case "3":
        navigate("/portfolio-management");
        break;
      case "4":
        navigate("/strategy-management");
        break;
      case "5":
        navigate("/filter-management");
        break;
      case "6":
        navigate("/request-manager");
        break;
      case "7":
        navigate("/document-manager");
        break;
      case "8":
        navigate("/attach-doc");
        break;
      default:
        navigate("/customers");
    }
    setCollapsed(true);
  };

  const deleteCookie = (name) => {
    document.cookie = name + "=; Max-Age=0; path=/;"; // Set cookie expiry to past date to delete
  };

  const handleLogout = () => {
    deleteCookie("AccessToken");
    navigate("/");
  };

  return (
    <Layout className={styles.Portfolio_layout}>
      <Sider
        className={collapsed ? "sidebar" : "sidebar1"}
        collapsible
        collapsed={collapsed}
        trigger={null}
        
        width={240}
      >
        <div className="demo-logo-vertical" />
        <div
          className={styles.portfolio_logo}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Winsight className={styles.winsightLogo} />
          {!collapsed && <p className={styles.winsightLetter}>Winwize</p>}
        </div>

        <Menu
          //   theme="dark"
          mode="inline"
          selectedKeys={selectedKey}
          onClick={(e) => handleMenuClick(e)}
          items={items}
          className={!collapsed ? "menuItems" : "collapseItems"}
        />
        <div className={styles.footerIcons}>
          <Button
            type="text"
            icon={collapsed ? <RightArrow /> : <LeftArrow />}
            onClick={() => setCollapsed(!collapsed)}
            style={!collapsed ? { paddingLeft: "5px" } : { paddingLeft: "3px" }}
            className="logoutButton"
          >
            {!collapsed ? "Collapse Sidebar" : ""}
          </Button>
          <Button
            type="text"
            icon={<Logout />}
            onClick={handleLogout}
            className="logoutButton"
            style={{ paddingLeft: "3px" }}
          >
            {!collapsed ? "Logout" : ""}
          </Button>
        </div>
      </Sider>
      <Layout className={styles.container}>
        {/* <Header
          style={{
            padding: 0,
            background: "#FCFCFC",
            // background: colorBgContainer,
          }}
        /> */}
        <Content className={styles.content}>
          {props?.main}
          {/* {selectedKey === "1" && <div>cosutomer</div>}
          {selectedKey === "2" && <div>Sotck</div>}
          {selectedKey === "3" && props?.main}
          {selectedKey === "4" && <div>strategy</div>}
          {selectedKey === "5" && <div>filter</div>} */}
        </Content>
        {/* <Footer> */}
        {/* <p className={styles.footer_text}>Powered By Hutech Solutions</p> */}
        {/* </Footer> */}
      </Layout>
    </Layout>
  );
}

export default PortfolioLayout;
