import React, { useEffect, useState } from "react";
import styles from "./verifyEmail.module.scss";
import CONSTANTS from "../../constants/variables";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Logo from "./img/Vector.png";
import MailLogo from "./img/email.png";
import axios from "axios";
import { Flex, Input, Tooltip, message } from "antd";

function VerifyEmail({ setCurrentPage }) {
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const email = localStorage.getItem("email");

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  const handleResendEmail = async () => {
    const headers = {
      "X-Custom-Token": "frappe",
    };

    try {
      await axios.post(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.login.api.forgot_password`,
        {
          email,
        },
        {
          headers: headers,
        }
      );

      setTimer(60);
      setCanResend(false);
    } catch (error) {
      setError(true);
      console.error("Error resending email:", error);
    }
  };
  const handleVerifyClick = async () => {
    const headers = {
      "X-Custom-Token": "frappe",
    };

    try {
      const response = await axios.post(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.login.api.validate_otp`,
        {
          email,
          otp: otp,
        },
        {
          headers: headers,
        }
      );

      if (response.data && response.data.message.doc_id) {
        // setDocId(response.data.message.doc_id[0].name)
        const doc_id = response.data.message.doc_id;
        localStorage.setItem("doc_id", doc_id);
        setError(false);
        message.success("OTP Verified");
        setCurrentPage("setPassword");
        // alert('email verified sucessfully')
      } else {
        message.error("Invalid OTP");
        setError(true);
        // alert("email verification failed");
      }
    } catch (error) {
      setError(true);
      console.error("Error verifying OTP:", error);
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("Text").trim();
    if (/^\d{6}$/.test(pasteData)) {
      setOtp(pasteData);
    } else {
      message.error("Please paste a 6-digit OTP.");
    }
  };

  return (
    <div className={styles.main_div}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.left_content}>
            <div className={styles.logo}>
              <img src={Logo} />
              <p>Winsight</p>
            </div>
            <p className={styles.left_paragraph}>
              Manage Portfolios, Optimise Investments, & Drive Performance
            </p>
          </div>

          <div className={styles.small}></div>
          <div className={styles.big}></div>
        </div>

        <div className={styles.right}>
          {/* <img src={MailLogo} /> */}
          <h1>OTP Verification</h1>

          {canResend ? (
            <p>
              Enter the 6 digit verification code received on your Email {email}
            </p>
          ) : (
            <p>
              Enter the 6 digit verification code received on your Email {email}
            </p>
          )}

          <Input.OTP
            length={6}
            placeholder="Enter your OTP"
            value={otp}
            onChange={(value) => setOtp(value)}
            onPaste={handleOtpPaste}
            style={{
              // borderColor: error ? "red" : "",
              // marginBottom: "2rem",
              width: "40%",
              height: "48px",
            }}
            prefix={
              error && (
                <ExclamationCircleOutlined
                  style={{
                    color: "red",
                    height: "15px",
                    width: "15px",
                    marginRight: "5px",
                  }}
                />
              )
            }
            className={styles.otpClassName}
            // help={
            //   error &&
            //   "Invalid OTP"
            // }
          />
          {error && <div className={styles.invalid_otp}>Invalid OTP</div>}

          <button
            className={`${styles.login_btn} green_button`}
            onClick={handleVerifyClick}
          >
            VERIFY
          </button>
          <div className={styles.resend}>
            <span>Didn’t receive the email?</span>
            {canResend ? (
              <button className={styles.timer} onClick={handleResendEmail}>
                Click to resend OTP
              </button>
            ) : (
              <span className={styles.seconds}>{timer}sec</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
