import axios from "axios";
import Cookies from "js-cookie";
import CONSTANTS from "../../constants/variables";
import { CloseOutlined } from '@ant-design/icons'

export const isTokenExpired = (token, buffer = 120) => {
  if (!token) return true;

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const expiryTime = payload.exp * 1000;
    return Date.now() + buffer * 1000 > expiryTime;
  } catch (err) {
    console.error("Error decoding token:", err);
    return true;
  }
};

export const refreshAccessToken = async () => {
  const refreshToken = Cookies.get("RefreshToken");

  if (!refreshToken || isTokenExpired(refreshToken)) {
    return false;
  }

  try {
    const response = await axios.get(
      `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.api.refresh_access_token`,
      {
        headers: {
          "X-Custom-Token": "frappe",
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );

    const newAccessToken = response?.data?.message?.access_token;
    Cookies.set("AccessToken", newAccessToken);

    return true;
  } catch (err) {
    console.error("Failed to refresh access token", err);
    if (err.response && err.response.status === 401) {
      logoutUser();
    }
    return false;
  }
};

export const logoutUser = (navigate) => {
  Cookies.remove("AccessToken");
  Cookies.remove("RefreshToken");
  if (navigate) navigate("/");
};

// avalilable formats = "MM/DD/YYYY", "DD/MM/YYYY", "DD-MM-YYYY"and "YYYY-MM-DD"
export const dateFormat = (date, format = "DD-MM-YYYY") => {
  if (!date) return "";
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  switch (format) {
    case "MM/DD/YYYY":
      return `${month}/${day}/${year}`;
    case "DD/MM/YYYY":
      return `${day}/${month}/${year}`;
    case "YYYY-MM-DD":
      return `${year}-${month}-${day}`;
    default:
      return `${day}.${month}.${year}`;
  }
};

export const formatTime = (date, format = "DD-MM-YYYY") => {
  if (!date) return "";

  const d = new Date(date);
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");
  const seconds = String(d.getSeconds()).padStart(2, "0");

  switch (format) {
    case "HH:mm:ss":
      return `${hours}:${minutes}:${seconds}`;
    case "MM/DD/YYYY HH:mm:ss":
      return `${String(d.getMonth() + 1).padStart(2, "0")}/${String(
        d.getDate()
      ).padStart(2, "0")}/${d.getFullYear()} ${hours}:${minutes}:${seconds}`;
    case "DD/MM/YYYY HH:mm:ss":
      return `${String(d.getDate()).padStart(2, "0")}/${String(
        d.getMonth() + 1
      ).padStart(2, "0")}/${d.getFullYear()} ${hours}:${minutes}:${seconds}`;
    case "YYYY-MM-DD HH:mm:ss":
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
        2,
        "0"
      )}-${String(d.getDate()).padStart(
        2,
        "0"
      )} ${hours}:${minutes}:${seconds}`;
    default:
      return `${String(d.getDate()).padStart(2, "0")}.${String(
        d.getMonth() + 1
      ).padStart(2, "0")}.${d.getFullYear()}`;
  }
};

export function formatNumber(numString, digit) {
  if (!numString) return "";
  const num = parseFloat(numString);
  return num.toLocaleString("en-IN", {
    maximumFractionDigits: digit,
  });
}

// Add this function at the beginning of your file, outside the RequestManager component
export function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const showFilePreview = (fileUrl) => {
  const modal = document.createElement("div");
  Object.assign(modal.style, {
    position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)", display: "flex", alignItems: "center",
    justifyContent: "center", zIndex: "1000"
  });

  const isPDF = fileUrl.endsWith(".pdf");

  let fileElement;

  if (isPDF) {
    // Use embed for PDF files for better compatibility
    fileElement = document.createElement("embed");
    Object.assign(fileElement, {
      src: fileUrl,
      type: "application/pdf",
      style: "width: 80%; height: 80%;"
    });
  } else {
    // Use img for images
    fileElement = document.createElement("img");
    Object.assign(fileElement, {
      src: fileUrl,
      style: "max-width: 90%; max-height: 90%;"
    });
  }

  // Close button
  const closeButton = document.createElement("button");
  Object.assign(closeButton.style, {
    position: "absolute", top: "20px", right: "20px", padding: "10px",
    backgroundColor: "#fff", border: "none", cursor: "pointer", fontSize: "16px"
  });
  closeButton.innerHTML = '<span style="font-size: 24px;">&times;</span>'; // Simple '×' for close
  closeButton.onclick = () => document.body.removeChild(modal);

  modal.append(fileElement, closeButton);
  document.body.appendChild(modal);
};
