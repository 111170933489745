import React, { useEffect } from "react";
import styles from "./document.module.scss";
import "./document.css";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Row,
  Col,
  Typography,
  Card,
  Divider,
  Modal,
  message,
  Spin
} from "antd";

import {
  UploadOutlined,
  CloseOutlined ,
  FileTextOutlined ,
  EyeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import FooterBtn from "../Footerbtn/FooterBtn";
import { Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { showFilePreview } from "../utils/auth";


function AttachDocs() {
  const [isModalVisible, setIsModalVisible] = useState({
    aadhaarFile: null,
    panFile: null,
    agreementFile: null,
    replace: false,
    delete: false,
    newFile: null,
    fileType: null,
    
  });
  const { aadhaarFile, panFile, agreementFile, replace, fileType, newFile } =
    isModalVisible;
  
  const [aadharNumber, setAadharNumber] = useState('')
  const [panNumber, setpanNumber] = useState('')
  const [customerData, setCustomerData] = useState([])

  const [documentUrls, setDocumentUrls] = useState({
    aadhaarFile: { url: null, timestamp: null },
    panFile: { url: null, timestamp: null },
    agreementFile: { url: null, timestamp: null }
  });

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { id, doc_id, customer_name } = location.state || {};

  let accessToken = Cookies.get("AccessToken");
  const headers = {
    "X-Custom-Token": "frappe",
    Authorization: `Bearer ${accessToken}`,
  };

// console.log("trans", id, doc_id, customer_name)
  



   

  const handleUpload = (fileType) => (info) => {
    if (info.file.status === "uploading") return;

    

    if (isModalVisible[fileType]) {
      setIsModalVisible({
        ...isModalVisible,
        replace: true,
        delete: false,
        newFile: info.file.originFileObj,
        fileType,
      });
    } else {
      setIsModalVisible({
        ...isModalVisible,
        [fileType]: info.file.originFileObj,
      });
    }
  };

  const handleRemove = (fileType) => () => {
    setIsModalVisible({
      ...isModalVisible,
      replace: false,
      delete: true,
      fileType,
    });
  };

  

  const replaceDeleteHandler = async () => {
    const { fileType, replace, delete: isDelete, newFile } = isModalVisible;

    if (isDelete) {
      // Clear the file from state based on the fileType
      setIsModalVisible({
        ...isModalVisible,
        [fileType]: null,
        replace: false,
        delete: false,
        newFile: null,
        fileType: null,
      });
      
      // await fetchCustomerData()
      await updateDocuments();

      // Fetch updated customer data to reflect changes in the UI
      await fetchCustomerData();    
    } else if (replace && newFile) {
      // Handle replacement logic
      setIsModalVisible({
        ...isModalVisible,
        [fileType]: newFile,
        replace: false,
        delete: false,
        newFile: null,
        fileType: null,
      });

      // await updateDocuments();

      // Fetch updated customer data to reflect changes in the UI
      await fetchCustomerData();      

    }
  };

  const isFileInLocalState = (fileType) => {
    return isModalVisible[fileType];
  };
  

  const handlePreview = async (fileType) => {
    const file = isModalVisible[fileType];
    if (isFileInLocalState(fileType)) {
      // File is in local state, use it directly
      const fileObject = isModalVisible[fileType].originFileObj || isModalVisible[fileType];
      const fileURL = URL.createObjectURL(fileObject);
      window.open(fileURL, "_blank"); // Opens file in a new tab for preview
    } else {
      // File is not in local state, get URL from server
      try {
        const url = await getDocumentUrl(fileType);
        // url = url.replace("response-content-disposition=attachment", "response-content-disposition=inline");
        showFilePreview(url) // Opens file in a new tab for preview
      } catch (error) {
        message.error("Failed to load preview.");
      }
    }
  };

 
  
  // Modified handleDownload function
  const handleDownload = async (fileType) => {
    const file = isModalVisible[fileType];
    if (file) {
      // File is in local state, use local file object
      const fileObject = file.originFileObj || file;
      const fileURL = URL.createObjectURL(fileObject);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = file.name || `${fileType}.pdf`; // Customize file name if needed
      link.click(); // Trigger the download
    } else {
      // File is not in local state, get URL from server
      try {
        const url = await getDocumentUrl(fileType);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${fileType}.pdf`; // Customize file name if needed
        link.click(); // Trigger the download
      } catch (error) {
        message.error("Failed to download document.");
      }
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const uploadData = async() => {
      try {
        const payload = {
          data: {
            customer: id,
            aadhar_no: aadharNumber,
            pan_no: panNumber,
          },
        };
    
       
        payload.data.aadhar_doc = aadhaarFile ? await getBase64(aadhaarFile) : "";
        payload.data.pan_doc = panFile ? await getBase64(panFile) : "";
        payload.data.agreement_doc = agreementFile ? await getBase64(agreementFile) : "";

        const response = await axios.post(
          `https://advisory.winwizeresearch.in/api/method/stock_portfolio_management.portfolio_master.doctype.customer_documents.api.upload_customer_documents`,
          {
            data:payload.data
          },
          {
            headers:headers
          }
        )
        message.success(response?.data?.message?.msg || "Record created successfully")

      } catch(err) {
        message.error(err)
      }
  }


  const updateDocuments = async() => {
    setLoading(true);

    try {

      const data = {
          aadhar_no: aadharNumber,
          pan_no: panNumber,
          customer_id: id,
          
      }

      if (aadhaarFile || (isModalVisible.delete && isModalVisible.fileType === "aadhaarFile")) {
        data.aadhar_doc = {
          old_doc_ext: aadhaarFile?.type || "",
          new_doc: isModalVisible.delete && isModalVisible.fileType === "aadhaarFile"
            ? "" // Empty if deleted
            : isModalVisible.replace && isModalVisible.fileType === "aadhaarFile"
            ? await getBase64(isModalVisible.newFile)
            : aadhaarFile
            ? await getBase64(aadhaarFile)
            : "",
        };
      }
  
      if (panFile || (isModalVisible.delete && isModalVisible.fileType === "panFile")) {
        data.pan_doc = {
          old_doc_ext: panFile?.type || "",
          new_doc: isModalVisible.delete && isModalVisible.fileType === "panFile"
            ? "" // Empty if deleted
            : isModalVisible.replace && isModalVisible.fileType === "panFile"
            ? await getBase64(isModalVisible.newFile)
            : panFile
            ? await getBase64(panFile)
            : "",
        };
      }
  
      if (agreementFile || (isModalVisible.delete && isModalVisible.fileType === "agreementFile")) {
        data.agreement_doc = {
          old_doc_ext: agreementFile?.type || "",
          new_doc: isModalVisible.delete && isModalVisible.fileType === "agreementFile"
            ? "" // Empty if deleted
            : isModalVisible.replace && isModalVisible.fileType === "agreementFile"
            ? await getBase64(isModalVisible.newFile)
            : agreementFile
            ? await getBase64(agreementFile)
            : "",
        };
      }



      const response = await axios.post(
        `https://advisory.winwizeresearch.in/api/method/stock_portfolio_management.portfolio_master.doctype.customer_documents.api.update_customer_documents`,
        {
          doc_id: doc_id,
          data

        },
        {
          headers:headers
        }
      )
      message.success(response?.data?.message?.msg || "Record updated successfully")

    } catch(err) {

    } finally {
      setLoading(false); // Stop spinner
    }
  }


  const fetchCustomerData = async() => {
    try {

      const response = await axios.get(
        `https://advisory.winwizeresearch.in/api/method/stock_portfolio_management.portfolio_master.doctype.customer_documents.api.fetch_all_customers_documents?filters={"doc_id": "${id}"}`,
        {
          headers:headers
        }
      )
      setCustomerData(response?.data?.message?.data[0])

      setDocumentUrls({
        aadhaarFile: { url: response?.data?.message?.data[0]?.aadhar_url, timestamp: Date.now() },
        panFile: { url: response?.data?.message?.data[0]?.pan_url, timestamp: Date.now() },
        agreementFile: { url: response?.data?.message?.data[0]?.agreement_url, timestamp: Date.now() }
      });


    } catch(err) {
      console.log(err)
    }
  }

  const getDocumentUrl = async (fileType) => {
    const urlData = documentUrls[fileType];
    
    // Check if the URL has expired (older than 10 minutes)
    const isExpired = !urlData.url || (Date.now() - urlData.timestamp > 10 * 60 * 1000);
  
    if (isExpired) {
      // Call fetchCustomerData to get fresh URLs
      await fetchCustomerData();
  
      // Retrieve the updated URL
      const updatedUrlData = documentUrls[fileType];
      if (updatedUrlData && updatedUrlData.url) {
        return updatedUrlData.url;
      } else {
        throw new Error("URL not available.");
      }
    } else {
      // URL is still valid
      return urlData.url;
    }
  };
  
  

  useEffect(() => {
    fetchCustomerData()
  },[])


  const onSave = async() => {
    if (!customerData?.name) {
      await uploadData();
    } else {
      await updateDocuments();
    }

  }

  // console.log(aadhaarFile, !customerData?.name, "dataaaa")
  
  
  return (
    <>
      <BreadCrumb
        data={[
          {
            name: "Document Management",
            url: "/document-manager",
            active: false,
          },
          {
            name: "Attach Documents",
            url: "/attach-doc",
            active: true,
          },
        ]}
      />

      <div className={styles.attach_doc_div}>
        <p className={styles.header}>Attach Document</p>
        <p className={styles.customer_info}>
          Customer Name: <strong>{customer_name}</strong> | Customer Id:{" "}
          <strong>{id}</strong>
        </p>

        <Card className="attach-doc-card">
          {loading ? 
          <Spin tip="Loading..." size="large" className="customSpinner">
          <div style={{ minHeight: "200px" }}></div>
        </Spin> : (
            <Form layout="vertical" style={{}}>
            <Row gutter={16} className="attach-doc-row">
              <Col span={8}>
                <Form.Item className="label-head" label="Aadhaar">
                  <Input
                    className="attach_doc_input"
                    placeholder="Enter Aadhaar number"
                    value={customerData?.name ? customerData?.aadhar_no : aadharNumber}
                    onChange={(e) => {setAadharNumber(e.target.value)}}
                    
                  />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={5}>
                <p className="label-head">Upload Aadhaar</p>
                <p className="label-info">Only JPEG and PDF are accepted</p>

                <Upload
                  onChange={handleUpload("aadhaarFile")}
                  showUploadList={false}
                  
                >
                  <Button className="upload-input" icon={<UploadOutlined />}>
                    Upload document
                  </Button>
                </Upload>
              </Col>
              <Col span={8} className="uploaded-file-column">
                {(aadhaarFile || customerData?.aadhar_exists === "1") && (
                  <div className="uploaded-file">
                    <div style={{display:"flex", alignItems:"center"}}>
                      <FileTextOutlined  style={{ marginRight: 8, fontSize:"24px" }} />
                      <span>{ aadhaarFile?.name || "Aadhar"  || "No file available"}</span>
                     
                    </div>
                   <div>
                   <Button
                        type="text"
                        icon={<EyeOutlined />}
                        onClick={() => handlePreview("aadhaarFile")}
                        style={{color:"#0FA958"}}
                      />
                      {/* Download button */}
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownload("aadhaarFile")}
                        style={{color:"#0FA958"}}

                      />
                    <Button
                      type="text"
                      icon={<CloseOutlined  />}
                      onClick={handleRemove("aadhaarFile")}
                      style={{ color: "#DA3C45" }}
                    />
                   </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row gutter={16} className="attach-doc-row">
              <Col span={8}>
                <Form.Item className="label-head" label="PAN">
                  <Input className="attach_doc_input" placeholder="Enter PAN" value={customerData?.name ? customerData?.pan_no : panNumber} onChange={(e) => {setpanNumber(e.target.value)}} />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={5}>
                <p className="label-head">Upload PAN</p>
                <p className="label-info">Only JPEG and PDF are accepted</p>
                {/* <Typography.Text>Upload PAN</Typography.Text>
                <Typography.Paragraph>
                  Only JPEG and PDF are accepted
                </Typography.Paragraph> */}
                <Upload
                  onChange={handleUpload("panFile")}
                  showUploadList={false}
                >
                  <Button className="upload-input" icon={<UploadOutlined />}>
                    Upload document
                  </Button>
                </Upload>
              </Col>
              <Col span={8} className="uploaded-file-column">
                {(panFile || customerData?.pan_exists === "1") && (
                  <div className="uploaded-file">
                    <div style={{display:"flex", alignItems:"center"}}>
                      <FileTextOutlined  style={{ marginRight: 8, fontSize:"24px" }} />
                      <span>{customerData?.name? "Pan": panFile?.name || "No file available"}</span>
                    </div>
                  <div>
                  <Button
                        type="text"
                        icon={<EyeOutlined />}
                        onClick={() => handlePreview("panFile")}
                        style={{color:"#0FA958"}}
                      />
                      {/* Download button */}
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownload("panFile")}
                        style={{color:"#0FA958"}}

                      />
                    <Button
                      type="text"
                      icon={<CloseOutlined  />}
                      onClick={handleRemove("panFile")}
                      style={{ color: "#DA3C45", padding: "11px 11px" }}
                    />
                  </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row gutter={16} className="attach-doc-row">
              <Col span={8}>
                <Form.Item className="label-head" label="Agreement Document">
                  <Input
                    className="attach_doc_input"
                    disabled
                    placeholder="Upload agreement document"
                  />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={5}>
                <p className="label-head">Upload Agreement</p>
                <p className="label-info">Only JPEG and PDF are accepted</p>
                {/* <Typography.Text>Upload Agreement</Typography.Text>
                <Typography.Paragraph>
                  Only JPEG and PDF are accepted
                </Typography.Paragraph> */}
                <Upload
                  className="upload-input"
                  onChange={handleUpload("agreementFile")}
                  showUploadList={false}
                >
                  <Button className="upload-input" icon={<UploadOutlined />}>
                    Upload document
                  </Button>
                </Upload>
              </Col>
              <Col span={8}>
                {(agreementFile || customerData?.agreement_exists === "1") && (
                  <div className="uploaded-file">
                    <div style={{display:"flex", alignItems:"center"}}>
                      <FileTextOutlined  style={{ marginRight: 8, fontSize:"24px" }} />
                      <span>{customerData?.name ? "Agreement" : agreementFile?.name}</span>
                    </div>
                   <div>
                   <Button
                        type="text"
                        icon={<EyeOutlined />}
                        onClick={() => handlePreview("agreementFile")}
                        style={{color:"#0FA958"}}
                      />
                      {/* Download button */}
                      <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleDownload("agreementFile")}
                        style={{color:"#0FA958"}}

                      />
                    
                    <Button
                      type="text"
                      icon={<CloseOutlined  />}
                      onClick={handleRemove("agreementFile")}
                      style={{color:"#DA3C45"}}
                    />
                   </div>
                  </div>
                )}
              </Col>
            </Row>
            <Divider />
            <div style={{ paddingRight: "24px" }}>
              <FooterBtn onSave={onSave} name={customerData?.name}/>
            </div>
          </Form>
          )}
        </Card>
      </div>

      <Modal
        open={replace || isModalVisible.delete}
        title={
          replace ? "Replace Uploaded Document" : "Delete Uploaded Document"
        }
        okText={replace ? "Confirm" : "Delete"}
        cancelText="Cancel"
        onOk={replaceDeleteHandler}
        // onOk={customerStatusUpdate}
        onCancel={() =>
          setIsModalVisible({
            ...isModalVisible,
            replace: false,
            delete: false,
            fileType:null
          })
        }
        className="confirm_model"
        width={650}
      >
        <Divider style={{ margin: "14px 0px" }} />
        <p className={styles.deactivate_msg}>
          {replace
            ? " A document has already been uploaded. Uploading a new file will replace the previous one. Are you sure you want to continue?"
            : "Are you sure you want to delete this document? This action cannot be undone, and the document will be permanently removed."}
        </p>
      </Modal>
    </>
  );
}

export default AttachDocs;
