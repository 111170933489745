import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Typography,
  message,
  Select,
  Radio,
} from "antd";

import React, { useEffect, useState } from "react";
import "./editModal.css";
import axios from "axios";
import Cookies from "js-cookie";
import { isTokenExpired, refreshAccessToken, logoutUser } from "../utils/auth";
import { useForm } from "antd/es/form/Form";

const { Text } = Typography;
const { Option } = Select;

function EditAdhocModel({ openAdhocModal, setOpenAdhocModal }) {
  // const [form] = Form.useForm();

  const handleCancel = () => {
    setOpenAdhocModal(false);
  };

  // useEffect(() => {
  //     if (customerData && form) {
  //         // Check if customerData is populated and form is initialized
  //         form.setFieldsValue({
  //             customer_name: customerData?.customer_name || '',
  //             customer_id: customerData?.name || '',
  //             customer_email: customerData?.email || '',
  //             customer_mobile: customerData?.mobile || '',
  //         });
  //     }
  // }, [customerData, form]);


  return (
    <Modal
      open={openAdhocModal}
      footer={null}
      title={"Initiate Adhoc Transaction"}
      className="editModal"
      onCancel={handleCancel}
    >
      <Form
        layout="vertical"
        className="editForm"
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 24,
        }}
        //   form={form}
        //   initialValues={customerData}
      >
        <Form.Item
          label="Adhoc Request ID"
          name="adhoc_id"
          className="nameLabel"
          // readOnly={openEyeCustomerModal ? true : false}
        >
          <Input placeholder="Adhoc ID" className="Input" />
        </Form.Item>
        <Form.Item
          className="nameLabel"
          label="Transaction Type"
          // name="customer_id"
          // display={openEyeCustomerModal?"none":""}
        >
          <Radio.Group>
            <Radio value={1}>Buy</Radio>
            <Radio value={2}>Sell</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="nameLabel"
          label="Description"
          name="customer_email"
        >
          <Select
            placeholder="Enter Description"
            style={{ width: "100%" }}
            className="Input"
            options={[
              {
                value: "adhoc_transaction",
                label: "Adhoc Transaction",
              },
              {
                value: "sip_transaction",
                label: "SIP Transaction",
              },
              {
                value: "rebalance_transaction",
                label: "Rebalance Transaction",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          className="nameLabel"
          label="Invest Into"
          // name="customer_id"
          // display={openEyeCustomerModal?"none":""}
        >
          <Radio.Group>
            <Radio value={1}>Existing Portfolio Stocks</Radio>
            <Radio value={2}>Model Portfolio Stocks</Radio>
          </Radio.Group>
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item
              className="nameLabel"
              label="Amount"
              name="customer_mobile"
            >
              <Input
                placeholder="Enter"
                style={{ width: "100%" }}
                className="Input"
              />
            </Form.Item>
          </Col>

          <Col span={2}></Col>

          <Col span={11}>
            <Form.Item
              className="nameLabel"
              label="Nuber of Stocks"
              name="customer_mobile"
            >
              <Input
                placeholder="Enter"
                style={{ width: "100%" }}
                className="Input"
              />
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            padding: "0px 20px",
            marginTop: "4rem",
          }}
        >
          <Button
            style={{ borderColor: "#00A971", color: "#00A971", width: "40%" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{
              backgroundColor: "#00A971",
              borderColor: "#00A971",
              width: "40%",
            }}
          >
            Send Approval Request
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default EditAdhocModel;
