import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Approval from "./Components/Approval";
import Thankyou from "./Components/Thankyou";
import Login from "./Components/Login/login";
import PortfolioLayout from "./Components/Portfolio Management/portfolioLayout";
import ForgotPassword from "./Components/Login/forgotPassword";
import VerifyEmail from "./Components/Login/verifyEmail";
import SetPassword from "./Components/Login/setPassword";
import FilterManagement from "./Components/Filter Management/filterManagementList";
import { useState } from "react";
import "./global.css";
import PortfolioList from "./Components/Portfolio Management/portfolioList";
import Customers from "./Components/Customers/customers";
import CustomerDetails from "./Components/Customers/customerDetails";
import StockAnalyser from "./Components/Stock Analyser/stockAnalyser";
import Strategymanagement from "./Components/Strategy Management/strategymanagement";
import ModelPortfolioDetails from "./Components/Portfolio Management/portfolioViewDetails";
import CreateFilters from "./Components/Filter Management/createFilters";
import RequireAuth from "./requireAuth"; // Import the HOC
import ModalPortfolio from "./Components/Customers/modalPortfolio";
import RequestManager from "./Components/Request Manager/requestManager";
import NewRequest from "./Components/Request Manager/newRequest";
import DocumentManager from "./Components/Document Manager/documentManager";
import AttachDocs from "./Components/Document Manager/attachDocs";

function App() {
  const [currentPage, setCurrentPage] = useState("login");

  const renderPage = () => {
    switch (currentPage) {
      case "login":
        return <Login setCurrentPage={setCurrentPage} />;
      case "resetPassword":
        return <ForgotPassword setCurrentPage={setCurrentPage} />;
      case "verifyEmail":
        return <VerifyEmail setCurrentPage={setCurrentPage} />;
      case "setPassword":
        return <SetPassword setCurrentPage={setCurrentPage} />;
      default:
        return <Login setCurrentPage={setCurrentPage} />;
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/approve" element={<Approval />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/" element={renderPage()} />
        <Route
          path="/customers"
          element={
            <RequireAuth>
              <PortfolioLayout main={<Customers />} />
            </RequireAuth>
          }
        />
        <Route
          path="/customer-details"
          element={
            <RequireAuth>
              <PortfolioLayout main={<CustomerDetails />} />
            </RequireAuth>
          }
        />
        <Route
          path="/customer-details/:portfolio"
          element={
            <RequireAuth>
              <PortfolioLayout main={<CustomerDetails />} />
            </RequireAuth>
          }
        />
        <Route
          path="/customer-details/:transaction"
          element={
            <RequireAuth>
              <PortfolioLayout main={<CustomerDetails />} />
            </RequireAuth>
          }
        />
        <Route
          path="/stock-analyser"
          element={
            <RequireAuth>
              <PortfolioLayout main={<StockAnalyser />} />
            </RequireAuth>
          }
        />
        <Route
          path="/portfolio-management"
          element={
            <RequireAuth>
              <PortfolioLayout main={<PortfolioList />} />
            </RequireAuth>
          }
        />
        <Route
          path="/model-portfolio-details"
          element={
            <RequireAuth>
              <PortfolioLayout main={<ModelPortfolioDetails />} />
            </RequireAuth>
          }
        />
        <Route
          path="/strategy-management"
          element={
            <RequireAuth>
              <PortfolioLayout main={<Strategymanagement />} />
            </RequireAuth>
          }
        />
        <Route
          path="/filter-management"
          element={
            <RequireAuth>
              <PortfolioLayout main={<FilterManagement />} />
            </RequireAuth>
          }
        />
        <Route
          path="/create-filter-management"
          element={
            <RequireAuth>
              <PortfolioLayout main={<CreateFilters />} />
            </RequireAuth>
          }
        />
        <Route
          path="/modal-portfolio"
          element={
            <RequireAuth>
              <PortfolioLayout main={<CustomerDetails />} />
            </RequireAuth>
          }
        />
        {/* <Route
          path="/transaction-history"
          element={
            <RequireAuth>
              <PortfolioLayout main={<CustomerDetails />} />
            </RequireAuth>
          }
        /> */}
        <Route
          path="/request-manager"
          element={
            <RequireAuth>
              <PortfolioLayout main={<RequestManager />}/>
            </RequireAuth>
          }
        />
        <Route
          path="/document-manager"
          element={
            <RequireAuth>
              <PortfolioLayout main={<DocumentManager />}/>
            </RequireAuth>
          }
        />
        <Route
          path="/document-manager"
          element={
            <RequireAuth>
              <PortfolioLayout main={<DocumentManager />}/>
            </RequireAuth>
          }
        />
        <Route
          path="/attach-doc"
          element={
            <RequireAuth>
              <PortfolioLayout main={<AttachDocs />}/>
            </RequireAuth>
          }
        />
        {/* <Route
          path="/initiate-adhoc-transaction"
          element={
            <RequireAuth>
              <PortfolioLayout main={<NewRequest />}/>
            </RequireAuth>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
