import React, { useEffect, useState } from "react";
import styles from "./stockAnalyser.module.scss";
import { ReactComponent as FilterComponent } from "../../Image/assets/filterCombine.svg";
import { ReactComponent as EyeIcon } from "../../Image/assets/eyeicon.svg";
import { ReactComponent as EditIcon } from "../../Image/assets/editicon.svg";
import { ReactComponent as DeleteIcon } from "../../Image/assets/deleteicon.svg";
import { Button, Col, Input, Row, Table, Divider, Card, Spin } from "antd";
import { EditOutlined, LeftOutlined, SearchOutlined } from "@ant-design/icons";
import "./stockAnalyser.css";
import { render } from "@testing-library/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { dateFormat } from "../utils/auth";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import CONSTANTS from "../../constants/variables";

function StockAnalyser() {
  const navigate = useNavigate();
  const [filterList, setFilterList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stockDetails, setStockDetails] = useState([]);
  const [result, setResult] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [tableHeight, setTableHeight] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;

    const headerHeight = 210; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins

    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  const columns1 = [
    {
      title: "",
      key: "key",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1, // Add 1 to the index to start from 1
    },
    {
      title: "Stock Name",
      key: "stock_name",
      dataIndex: "stock_name",
      // ellipsis: true,
      fixed: "left",
      sorter: (a, b) => a.stock_name.localeCompare(b.stock_name),
      //   align: "left",
    },

    {
      title: "BSE Code",
      key: "bse_code",
      dataIndex: "bse_code",
    },
    {
      title: "NSE Code",
      key: "nse_code",
      dataIndex: "nse_code",
      // ellipsis: true,
    },
    {
      title: "Market Cap",
      dataIndex: "market_capitalization",
      key: "market_capitalization",
      width:110,
      sorter:(a,b) => a.market_capitalization - b.market_capitalization,
      render: (value) => {
        if (value > 20000) {
          return <div className="large">Large cap</div>;
        } else if (value >= 5000 && value <= 20000) {
          return <div className="mid">Mid cap</div>;
        } else if (value >= 500 && value <= 5000) {
          return <div className="small">Small cap</div>;
        } else if (value < 500) {
          return <div className="micro">Micro Cap</div>;
        } else {
          return "Value out of range";
        }
      },
    },
    {
      title: "Sector",
      key: "industry",
      dataIndex: "industry",
      width: 300,
      sorter: (a, b) => a.industry.localeCompare(b.industry),
      // ellipsis: true,
    },
    {
      title: "Current Price",
      key: "current_price",
      dataIndex: "current_price",
      sorter: (a, b) => a.current_price - b.current_price,
    },
    {
      title: "Market Cap",
      key: "market_capitalization",
      dataIndex: "market_capitalization",
    },
    {
      title: "P/E Ratio",
      key: "price_to_earning",
      dataIndex: "price_to_earning",
      render: (text) => (text ? text : 0),
    },
    {
      title: "PEG Ratio",
      key: "peg_ratio",
      dataIndex: "peg_ratio",
      render: (text) => (text ? text : 0),
    },
    {
      title: "EPS",
      key: "eps",
      dataIndex: "eps",
    },
    {
      title: "Average Earnings 3 Years",
      key: "average_earnings_5year",
      dataIndex: "average_earnings_5year",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Average ",
      key: "average_return_on_capital_employed_3years",
      dataIndex: "average_return_on_capital_employed_3years",
      render: (text) => (text ? text : 0),
    },

    {
      title: "Return on Capital Employed (ROCE) - 3 Years",
      key: "average_return_on_capital_employed_3years",
      dataIndex: "average_return_on_capital_employed_3years",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Return on Capital Employed (ROCE) - 5 Years",
      key: "average_return_on_capital_employed_5years",
      dataIndex: "average_return_on_capital_employed_5years",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Return on Equity (ROE) - 3 Years",
      key: "average_return_on_equity_3years",
      dataIndex: "average_return_on_equity_3years",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Return on Equity (ROE) - 5 Years",
      key: "average_return_on_equity_5years",
      dataIndex: "average_return_on_equity_5years",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Debt to Equity",
      key: "debt_to_equity",
      dataIndex: "debt_to_equity",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Dividend Yield",
      key: "dividend_yield",
      dataIndex: "dividend_yield",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },

    {
      title: "Expected Quarterly Operating Profit",
      key: "expected_quarterly_operating_profit",
      dataIndex: "expected_quarterly_operating_profit",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Free Cash Flow - 3 Years",
      key: "free_cash_flow_3years",
      dataIndex: "free_cash_flow_3years",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Free Cash Flow Last Year",
      key: "free_cash_flow_last_year",
      dataIndex: "free_cash_flow_last_year",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Free Cash Flow Preceding Year",
      key: "free_cash_flow_preceding_year",
      dataIndex: "free_cash_flow_preceding_year",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Operating Cash Flow (3 Years)",
      key: "operating_cash_flow_3years",
      dataIndex: "operating_cash_flow_3years",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Operating Cash Flow (5 Years)",
      key: "operating_cash_flow_5years",
      dataIndex: "operating_cash_flow_5years",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Operating Cash Flow (7 Years)",
      key: "operating_cash_flow_7years",
      dataIndex: "operating_cash_flow_7years",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Operating Profit Preceding Year",
      key: "operating_profit_preceding_year",
      dataIndex: "operating_profit_preceding_year",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Profit After Tax",
      key: "profit_after_tax",
      dataIndex: "profit_after_tax",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },

    {
      title: "Profit Growth",
      key: "profit_growth",
      dataIndex: "profit_growth",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Profit Growth (3 Years)",
      key: "profit_growth_3years",
      dataIndex: "profit_growth_3years",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Profit Growth (5 Years)",
      key: "profit_growth_5years",
      dataIndex: "profit_growth_5years",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Profit Growth (7 Years)",
      key: "profit_growth_7years",
      dataIndex: "profit_growth_7years",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Profit Growth (10 Years)",
      key: "profit_growth_10years",
      dataIndex: "profit_growth_10years",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Promoter Holding",
      key: "promoter_holding",
      dataIndex: "promoter_holding",
      render: (text) => text || 0,
      ellipsis: true,
    },
    {
      title: "Sales",
      key: "sales",
      dataIndex: "sales",
      render: (text) => (text ? text : 0),
    },
    {
      title: "Sales Growth (3 Years)",
      key: "sales_growth_3years",
      dataIndex: "sales_growth_3years",
      ellipsis: true,
      render: (text) => (text ? text : 0),
    },
    {
      title: "Price to Book Value",
      key: "price_to_book_value",
      dataIndex: "price_to_book_value",
      ellipsis: true,
      render: (text) => (text ? text : 0),
    },
    {
      title: "Price to Sales",
      key: "price_to_sales",
      dataIndex: "price_to_sales",
      ellipsis: true,
      render: (text) => (text ? text : 0),
    },
    {
      title: "Return on Assets",
      key: "return_on_assets",
      dataIndex: "return_on_assets",
      render: (text) => (text ? text : 0),
    },
    {
      title: "Return on Capital Employed",
      key: "return_on_capital_employed",
      dataIndex: "return_on_capital_employed",
      ellipsis: true,
      render: (text) => (text ? text : 0),
    },
    {
      title: "Return on Equity",
      key: "return_on_equity",
      dataIndex: "return_on_equity",
      ellipsis: true,
      render: (text) => (text ? text : 0),
    },
    {
      title: "Return Over 1 Year",
      key: "return_over_1year",
      dataIndex: "return_over_1year",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Return Over 3 Years",
      key: "return_over_3years",
      dataIndex: "return_over_3years",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "Sales Preceding Year",
      key: "sales_preceding_year",
      dataIndex: "sales_preceding_year",
      render: (text) => (text ? text : 0),
      ellipsis: true,
    },
    {
      title: "RSI",
      key: "rsi",
      dataIndex: "rsi",
      render: (text) => (text ? text : 0),
    },
    {
      title: "Volume (1 Month Avg)",
      key: "volume_1month_average",
      dataIndex: "volume_1month_average",
      ellipsis: true,
      render: (text) => (text ? text : 0),
    },
    {
      title: "YoY Quarterly Profit Growth",
      key: "yoy_quarterly_profit_growth",
      dataIndex: "yoy_quarterly_profit_growth",
      ellipsis: true,
      render: (text) => (text ? text : 0),
    },
    {
      title: "YoY Quarterly Sales Growth",
      key: "yoy_quarterly_sales_growth",
      dataIndex: "yoy_quarterly_sales_growth",
      ellipsis: true,
      render: (text) => (text ? text : 0),
    },
  ];

  let accessToken = Cookies.get("AccessToken");

  const getStocksDetailswithFilter = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.stocks.api.fetch_stocks_data?page=${pageNumber}&page_size=${pageSize}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    setLoading(true); // Start the loader
    try {
      const response = await axios.get(apiUrl, { headers });

      setStockDetails(response?.data?.message?.stocks);
      setResult(response?.data?.message);
    } catch (err) {
    } finally {
      setLoading(false); // Stop the loader
    }
  };

  useEffect(() => {
    getStocksDetailswithFilter();
  }, [pageNumber, pageSize]);

  const handleLoadMore = () => {
    setPageSize(pageSize + 20);
    getStocksDetailswithFilter();
  };

  const handleSearchBar = async (e) => {
    const searchQuery = e.target.value;

    if (searchQuery.trim() === "") {
      setPageSize(20);
      setPageNumber(1);
      getStocksDetailswithFilter();
      return;
    }

    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.stocks.api.fetch_stocks_data?page=${pageNumber}&page_size=${pageSize}&filters={"name": "${searchQuery}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    setLoading(true);
    try {
      const response = await axios.get(apiUrl, { headers });
      setStockDetails(response?.data?.message?.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <div className={styles.header}>
        <LeftOutlined onClick={() => navigate(-1)} />
        <FilterComponent />
        <span>Stock Listing</span>
      </div> */}
      <BreadCrumb
        data={[
          {
            name: "Stock Listing",
            url: "/stock-analyser",
            active: true,
          },
        ]}
      />
      <div className={styles.portfolio_div}>
        <Row className="head-row" gutter={16}>
          <Col span={13} md={13} sm={22} xs={24}>
            <p className={styles.portfolio_text}>Stock Listing</p>
          </Col>
          <Col span={2} md={2} sm={22} xs={24}></Col>
          <Col span={9} md={9} sm={22} xs={24}>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by stock name, NSE code"
              className="stock_listing_searchbar"
              onChange={handleSearchBar}
              style={{ color: "#A49F9F" }}
            />
          </Col>
          {/* <Col span={4}>
            <Button
              className="portfolio_button"
              // type="primary"
              // onClick={() => navigate("/create-filter-management")}
            >
              Create New
            </Button>
          </Col> */}
        </Row>
        {/* <div style={{ marginTop: "2rem"}}> */}
        <Card className="computedResultsCard">
          <span
            style={{
              display: "block",
              fontSize: "14px",
              color: "#1D1D1D",
              fontWeight: "500",
              padding: "3px 32px 0 32px",
            }}
          >
            Showing 1-{result?.page_size} of {result?.total_stocks} results
          </span>

          <span
            style={{
              display: "inline-block",
              fontSize: "12px",
              fontWeight: "400",
              color: "#84828A",
              paddingLeft: "32px",
              marginBottom: "0.5rem",
            }}
          >
            last updated on {dateFormat(result?.last_updated)}
            {/* {new Date(result?.last_updated).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
              timeZone: "Asia/Kolkata",
            })}{" "}
            IST */}
          </span>

          <Spin size="large" spinning={loading} className="loading">
            <Table
              virtual
              columns={columns1}
              className="stock_listing_table"
              dataSource={stockDetails}
              pagination={false}
              scroll={{
                y: tableHeight,
                x: 7900,
              }}
            />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={handleLoadMore} className="load-more">
                Load More
              </Button>
            </div>
          </Spin>

          {/* <div style={{display:"flex", justifyContent:"center"}}>
            <Button onClick={handleLoadMore} className="load-more">Load More</Button>

            </div> */}
        </Card>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
}

export default StockAnalyser;
