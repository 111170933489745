import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Typography,
  message,
  Select,
} from "antd";

import React, { useEffect, useState } from "react";
import "./editModal.css";
import axios from "axios";
import Cookies from "js-cookie";
import { isTokenExpired, refreshAccessToken, logoutUser } from "../utils/auth";
import { useForm } from "antd/es/form/Form";
import CONSTANTS from "../../constants/variables";

const { Text } = Typography;
const { Option } = Select;
let accessToken = Cookies.get("AccessToken");

function EditCustomerModel({
  visible,
  onClose,
  customer,
  viewOnly = false,
  CustomerId,
}) {
  const [form] = Form.useForm();

  const updateData = async (values) => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.post(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.update_customer_details`,
        {
          doc_id: CustomerId,
          data: {
            email: values.customer_email,
            mobile: values.customer_mobile,
            risk_profile: customer?.risk_profile || "",
            portfolio_type_id: customer?.portfolio_type?.name || "",
            invest_sip_into: customer?.invest_sip_into || "",
          },
        },
        {
          headers: headers,
        }
      );
      message.success(response?.data?.message?.msg);
      onClose();
    } catch (err) {
      message.error(err?.response?.data?.message?.msg);
    }
  };

  useEffect(() => {
    if (customer && form) {
      form.setFieldsValue({
        customer_name: customer?.customer_name || "",
        customer_id: customer?.name || "",
        customer_email: customer?.email || "",
        customer_mobile: customer?.mobile || "",
      });
    }
  }, [customer, form]);

  return (
    <Modal
      visible={visible}
      footer={null}
      title={"Basic Information"}
      className="editModal edit"
      onCancel={onClose}
    >
      <Form
        layout="vertical"
        className="editForm"
        form={form}
        onFinish={updateData}
        initialValues={customer}
      >
        <Form.Item label="Name" name="customer_name" className="nameLabel">
          <Input
            placeholder="Enter your name"
            className="Input"
            // readOnly={viewOnly || visible}
            readOnly
          />
        </Form.Item>
        <Form.Item className="nameLabel" label="Customer ID" name="customer_id">
          <Input
            placeholder="Enter your ID"
            style={{ width: "100%" }}
            className="Input"
            readOnly
          
          />
        </Form.Item>
        <Form.Item
          className="nameLabel"
          label="Email"
          name="customer_email"
          rules={[
            {
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Please enter a valid email!",
            },
          ]}
        >
          <Input
            placeholder="Enter your Email"
            style={{ width: "100%" }}
            className="Input"
            readOnly={viewOnly}
          />
        </Form.Item>
        <Form.Item
          className="nameLabel"
          label="Mobile"
          name="customer_mobile"
          rules={[
            {
              pattern: /^\d{10}$/,
              message: "Please enter a valid 10-digit mobile number!",
            },
          ]}
        >
          <Input
            placeholder="Enter your Mobile Number"
            style={{ width: "100%" }}
            className="Input"
            readOnly={viewOnly}
          />
        </Form.Item>
        {viewOnly ? (
          <></>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              padding: "0px 20px",
              marginTop: "2rem",
            }}
          >
            <Button
              style={{ borderColor: "#00A971", color: "#00A971", width: "40%" }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              // onClick={updateData}
              style={{
                backgroundColor: "#00A971",
                borderColor: "#00A971",
                width: "40%",
              }}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
}

export default EditCustomerModel;
