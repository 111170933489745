import React, { useEffect, useState } from 'react'
import "./document.css"
import BreadCrumb from '../BreadCrumb/BreadCrumb'
import styles from './document.module.scss'
import { Table, Tag, Badge, Card,Input } from 'antd';
import {SearchOutlined, CheckCircleFilled} from "@ant-design/icons"
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from "js-cookie";
import { render } from '@testing-library/react';



function DocumentManager() {
const navigate = useNavigate()
const [allCustomer, setAllCustomer] = useState([])
const [tableHeight, setTableHeight] = useState(0);
const [searchQuery, setSearchQuery] = useState('');
const [filteredCustomers, setFilteredCustomers] = useState([]);


let accessToken = Cookies.get("AccessToken");
  const headers = {
    "X-Custom-Token": "frappe",
    Authorization: `Bearer ${accessToken}`,
  };

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 170; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins
    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);



    const columns = [
        {
          title: 'Customer ID',
          dataIndex: 'customer_id',
          width:120,
          key: 'customer_id',
          render: (text,r) => <a onClick={() => {
            navigate(`/attach-doc`, {state:{id:text, doc_id: r?.name, customer_name : r?.customer_name}});
          }} 
          style={{color:"#1A97DF", textDecoration:"underline"}}>{text}</a>
        },
        {
          title: 'Customer Name',
          dataIndex: 'customer_name',
          key: 'customer_name',
          ellipsis:true,
        },
        {
          title: 'KYC Verification',
          dataIndex: 'kyc_verification_status',
          key: 'kyc_verification_status',
          render: (text) => {
            return (
             text ?  <div
             style={{
               backgroundColor: statusColors[text] || "#F5F5F5",
               color: statusTextColors[text] || "#000000",
               padding: "3px",
               borderRadius: "12px",
               textAlign: "center",
               fontSize:"11.63px",
               fontWeight:"500"
             }}
           >
             {text}
           </div> : <div></div>
            );
          },
        },
        {
            title: 'Aadhar',
            dataIndex: 'aadhar_no',
            key: 'aadhar_no',
            render : (text) => (
              text ? text : <p style={{color:"#84828A", fontSize:"10px"}}>Not Submitted</p>
            )
            
          },
          {
            title: 'PAN',
            dataIndex: 'pan_no',
            key: 'pan_no',
            render : (text) => (
              text ? text : <p style={{color:"#84828A", fontSize:"10px"}}>Not Submitted</p>
            )
            
          },
          {
            title: 'Aadhar Document',
            dataIndex: 'aadhar_exists',
            key: 'aadhar_exists',
            render: (status) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {status === "1" ? 
                  (<CheckCircleFilled style={{ color: "#0FA958" }} />) : 
                  (<CheckCircleFilled style={{ color: "#A49F9F" }} />)
                }
              </div>
            ),
          },
       
        {
          title: 'PAN Document',
          dataIndex: 'pan_exists',
          key: 'pan_exists',
          render: (status) => (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {status === "1" ? 
        (<CheckCircleFilled style={{ color: "#0FA958" }} />) : 
        (<CheckCircleFilled style={{ color: "#A49F9F" }} />)
      }
    </div>
          ),
         
        },
        
        {
          title: 'Agreement document',
          dataIndex: 'agreement_exists',
          key: 'agreement_exists',
          ellipsis:true,
          render: (status) => (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {status === "1" ? 
        (<CheckCircleFilled style={{ color: "#0FA958" }} />) : 
        (<CheckCircleFilled style={{ color: "#A49F9F" }} />)
      }
    </div>
          ),
        },
      ];

    

      const statusColors = {
       Completed: "#E3F6E5",
       Incomplete:"#FDEBEC",
       Pending:"#FDF2D9"
      };
    
      const statusTextColors = {
        Completed:"#0FA958",
        Incomplete:"#EF4D56",
        Pending:"#F49C05"
      };

      const fetchAllCustomer = async() => {

        try {

          const response = await axios.get(
            `https://advisory.winwizeresearch.in/api/method/stock_portfolio_management.portfolio_master.doctype.customer_documents.api.fetch_all_customers_documents`,
            {
              headers:headers
            }
          )
          setAllCustomer(response?.data?.message?.data)
          setFilteredCustomers(response?.data?.message?.data);
          

          

        } catch(err) {

        }

      }

      useEffect(() => {
        fetchAllCustomer()
      }, [])

      const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchQuery(value);
        
        if (value === "") {
          // If search query is empty, show all customers
          setFilteredCustomers(allCustomer);
        } else {
          // Otherwise, filter the list
          const filteredData = allCustomer.filter((customer) =>
            customer.customer_id.toLowerCase().includes(value) ||
            customer.customer_name.toLowerCase().includes(value)
          );
          setFilteredCustomers(filteredData);
        }
      };
    

  return (
    <>
        <BreadCrumb
        data={[
          {
            name: "Document Management",
            url: "/document-manager",
            active: true,
          },
        ]}
      />
      <div className={styles.document_div}>
        <p className={styles.page_header}>Document Management</p>

        <Card 
            className="table_card"
            extra={
                <Input
                  prefix={<SearchOutlined style={{ color: "#A49F9F" }} />}
                  placeholder="Search by Customer ID , Customer Name"
                  className="document_search_input"
                  onChange={handleSearch}
                />
              }
            >
        <Table 
            virtual
            className='document_table'
            columns={columns}
            dataSource={filteredCustomers} 
            pagination={{
              pageSize:10
            }}
            scroll={{
              y: tableHeight
             
            }}/>
        </Card>

      </div>
    </>
      

    
  )
}

export default DocumentManager