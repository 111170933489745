import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Typography,
  message,
  Select,
} from "antd";

import React, { useEffect, useState } from "react";
// import "./addPortfolioModal.css";
import axios from "axios";
import Cookies from "js-cookie";
import { isTokenExpired, refreshAccessToken, logoutUser } from "../utils/auth";
import CONSTANTS from "../../constants/variables";

const { Text } = Typography;
const { Option } = Select;

function EditPortfolioModal({
  openPortfolioModal,
  setOpenPortfolioModal,
  data,
  PortfolioId,
}) {
  const [form] = Form.useForm();
  const [totalWeightage, setTotalWeightage] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [options, setOptions] = useState([]);

  const categories = [
    { name: "Large-cap" },
    { name: "Mid-cap" },
    { name: "Small-cap" },
    { name: "Micro-cap" },
    { name: "Gold & Silver" },
    { name: "Dividend Stocks" },
    { name: "REIT & Debt" },
  ];

  const calculateTotalWeightage = (values) => {
    const total = categories.reduce((sum, _, index) => {
      const weight = parseFloat(values[`weightage_${index}`]) || 0;
      return sum + weight;
    }, 0);
    setTotalWeightage(total);
  };

  const onSave = async () => {
    let accessToken = Cookies.get("AccessToken");

    try {
      const values = await form.validateFields();

      const categoryData = categories
        .map((category, index) => ({
          category: category.name,
          weightage: parseFloat(values[`weightage_${index}`] || 0),
          no_of_stocks: parseInt(values[`stocks_${index}`] || 0),
        }))
        .filter(
          (cat) =>
            !(cat.weightage === 0 || isNaN(cat.weightage)) ||
            !(cat.no_of_stocks === 0 || isNaN(cat.no_of_stocks))
        );

      if (totalWeightage !== 100) {
        messageApi.open({
          type: "error",
          content: "Weightage should be equal to 100%",
          duration: 5,
        });
        return;
      }

      // Call the API
      const url =
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.update_portfolio`;

      const payload = {
        data:
          values?.filter_id === data?.filter_obj?.filter_name
            ? {
                portfolio: values?.riskProfile,
                description: "",
                categories: categoryData,
              }
            : {
                portfolio: values?.riskProfile,
                filter_id: values?.filter_id,
                description: "",
                categories: categoryData,
              },
      };

      payload.doc_id = PortfolioId;

      const headers = {
        "X-Custom-Token": "frappe",
        Authorization: `Bearer ${accessToken}`,
      };

      // const data = {
      //   data: {
      //     portfolio: values?.riskProfile,
      //     description: "",
      //     categories: categoryData,
      //   },
      // };

      let res = await axios.post(url, payload, { headers });
      message.success(res?.data?.message?.msg);

      setOpenPortfolioModal(false);
    } catch (error) {
      message.error(error?.response?.data?.message?.msg);
    }
  };

  useEffect(() => {
    // Fetching data from the API
    let accessToken = Cookies.get("AccessToken");

    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    axios
      .get(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.fetch_all_filters`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const data = response?.data?.message?.data;
        // Extracting filter_name from the API response
        const filterOptions = data.map((item) => ({
          value: item.name, // unique identifier
          label: item.filter_name, // display name
        }));
        setOptions(filterOptions);
      })
      .catch((error) => {
        message.error("Error fetching data");
      });
  }, []);

  const onCancel = () => {
    setOpenPortfolioModal(false);
  };

  const handleValuesChange = (_, allValues) => {
    calculateTotalWeightage(allValues);
  };

  useEffect(() => {
    // Initialize total weightage when modal opens
    const initialValues = form.getFieldsValue();
    calculateTotalWeightage(initialValues);

    if (data) {
      const initialCategories = {};
      data?.categories?.forEach((cat, index) => {
        initialCategories[`weightage_${index}`] = cat?.weightage;
        initialCategories[`stocks_${index}`] = cat?.no_of_stocks;
      });

      form.setFieldsValue({
        riskProfile: data?.portfolio,
        ...initialCategories,
      });
    }
  }, [form, data]);

  return (
    <Modal
      open={openPortfolioModal}
      //   okText="Save"
      //   cancelText="Cancel"
      //   onOk={handleOk}
      footer={null}
      title={"Edit Model Portfolio"}
      className="portfolioModal"
      onCancel={onCancel}
    >
      {contextHolder}
      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        onValuesChange={handleValuesChange}
        initialValues={{
          riskProfile: data?.portfolio,
          filter_id: data?.filter_obj?.filter_name,
        }}
      >
        <Form.Item
          label={
            <p style={{ display: "flex", gap: "3px" }}>
               Protfolio name
              <span
                style={{
                  color: "red",
                  fontSize: "16px",
                }}
              >
                *
              </span>
            </p>
          }
          name="riskProfile"
          className="riskProfileLabel"
          rules={[
            {
              required: true,
              message: "Please enter risk profile name",
            },
          ]}
        >
          <Input
            placeholder="Enter risk profile name"
            className="riskProfileInput"
          />
        </Form.Item>
        <Form.Item
          className="Filter"
          label="Assign filter"
          name="filter_id"
        >
          <Select
            placeholder="Select filter combination"
            style={{ width: "100%" }}
            allowClear
            className="riskProfileInput"
            // loading={loading}
          >
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={50} className="portfolioRow">
          {/* Categories and Weights */}
          <Col span={9}>
            <Form.Item label="Category" className="columnLabel">
              {categories.map((category, index) => (
                <Input
                  key={index}
                  value={category.name}
                  readOnly
                  className="categoryInput"
                />
              ))}
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={5}>
            <Form.Item
              label="Weightage"
              className="columnLabel"
              initialValues={{ weightage: data?.portfolio || "" }}
            >
              {categories.map((category, index) => (
                <Form.Item
                  key={index}
                  name={`weightage_${index}`}
                  noStyle
                  rules={[
                    {
                      pattern: /^100(\.0{1,2})?$|^(\d{1,2})(\.\d{1,2})?$/,
                      message: "Enter a valid percentage (0-100)",
                    },
                  ]}
                >
                  <Input className="weightageInput" type="number" max={100} />
                </Form.Item>
              ))}
            </Form.Item>
            <div
              style={{
                marginBottom: "30px",
                color: "#077255",
                fontSize: "14px",
              }}
            >
              <p>Total: {totalWeightage}%</p>
              {totalWeightage > 100 && (
                <Text type="danger">Total weightage must be 100%</Text>
              )}
            </div>
          </Col>
          <Col span={2}></Col>

          <Col span={6}>
            <Form.Item label="No. of Stocks" className="columnLabel">
              {categories.map((category, index) => (
                <Form.Item key={index} name={`stocks_${index}`} noStyle>
                  <Input className="stocksInput" />
                </Form.Item>
              ))}
            </Form.Item>
          </Col>
        </Row>

        {/* Total */}

        {/* Buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            padding: "0px 20px",
          }}
        >
          <Button
            onClick={onCancel}
            style={{ borderColor: "#00A971", color: "#00A971", width: "25%" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={onSave}
            style={{
              backgroundColor: "#00A971",
              borderColor: "#00A971",
              width: "25%",
            }}
          >
            {"Update"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default EditPortfolioModal;
