import { Button, Card, Col, Divider, Row, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./customer.module.scss";
import "./customer.css";
import Cookies from "js-cookie";
import axios from "axios";
import EditPortfolioModal from "./editPortfolio";
import { render } from "@testing-library/react";
import CONSTANTS from "../../constants/variables";

function ModalPortfolio({ CustomerId, name, PortfolioId }) {
  let accessToken = Cookies.get("AccessToken");
  const [allApiData, setAllApiData] = useState({
    currentPortfolio: [],
    customerStocks: [],
    modalStocks: [],
    modelPortfolio: {},
    loading: false,
  });
  const {
    currentPortfolio,
    customerStocks,
    modalStocks,
    modelPortfolio,
    loading,
  } = allApiData;
  // const [currentPortfolio, setCurrentPortfolio] = useState([]);
  // const [customerStocks, setcustomerStocks] = useState([]);
  // const [modalStocks, setModalStocks] = useState([]);
  // const [modelPortfolio, setModelPortfolio] = useState({});
  const [tableHeight, setTableHeight] = useState(0);

  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);


  console.log("Portfolio ID", PortfolioId)

  const customerStocksColumn = [
    {
      title: "Name",
      key: "stock_name",
      dataIndex: "stock_name",
      fixed: "left",
      width: 150,
      // ellipsis: true,
    },
    {
      title: "Stock ID",
      key: "nse_code",
      dataIndex: "nse_code",
      // ellipsis: true,
    },
    {
      title: "Curr.Value",
      key: "current_price",
      dataIndex: "current_price",
      // ellipsis: true,
    },
    {
      title: "Qty",
      key: "qty",
      dataIndex: "qty",
      render: (_, rec) => rec?.qty + " unit",
      // ellipsis: true,
    },
    {
      title: "Inv.Amount",
      key: "inv_amt",
      dataIndex: "inv_amt",
      render: (text) => text.toFixed(2),
    },
    {
      title: "Weightage",
      key: "wght",
      dataIndex: "wght",
      // ellipsis: true,
      render: (_, rec) => parseFloat(rec?.wght).toFixed(2),
    },
  ];

  const customerStocksColumn1 = [
    {
      title: "Name",
      key: "stock_name",
      dataIndex: "stock_name",
      fixed: "left",
      width: 150,
    },
    {
      title: "Stock ID",
      key: "nse_code",
      dataIndex: "nse_code",
      // ellipsis: true,
    },
    {
      title: "Curr.Value",
      key: "current_price",
      dataIndex: "current_price",
      // ellipsis: true,
    },
    {
      title: "Qty",
      key: "qty_held",
      dataIndex: "qty_held",
      render: (_, rec) => rec?.qty_held + " unit",
      // ellipsis: true,
    },
    {
      title: "Inv.Amount",
      key: "inv_amt",
      dataIndex: "inv_amt",
      // ellipsis: true,
    },
    {
      title: "Weightage",
      key: "weightage",
      dataIndex: "weightage",
      // ellipsis: true,
      render: (_, rec) => parseFloat(rec?.weightage).toFixed(2),
    },
  ];

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 360; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins
    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  const currentPortfolioData = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.fetch_customer_current_portfolio?filters={"doc_id": "${CustomerId}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(apiUrl, { headers });
      
      return response?.data?.message?.data || [];
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const fetchModelPortfolio = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_portfolio?filters={"doc_id":"${PortfolioId}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(apiUrl, { headers });
      
      return response?.data?.message?.data || {};
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
  };

  const getCurrentStocksWithCustomer = async () => {

    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.fetch_customer_stocks?filters={"doc_id": "${CustomerId}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(apiUrl, { headers });
      
      return response?.data?.message?.data || [];
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const getModalStocksWithCustomer = async () => {

    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_portfolio_based_stocks_data?filters={"doc_id": "${PortfolioId}", "customer_id": "${CustomerId}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(apiUrl, { headers });
      const stocksData = response?.data?.message?.data || [];

      let combinedStocks = [];
      stocksData.forEach((stockCategory) => {
        const stockKeys = Object.keys(stockCategory);
        stockKeys.forEach((key) => {
          combinedStocks = [...combinedStocks, ...stockCategory[key]];
        });
      });

      return combinedStocks;
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const fetchAllData = async () => {
    setAllApiData({
      currentPortfolio: [],
      modelPortfolio: {},
      customerStocks: [],
      modalStocks: [],
      loading: true,
    });

    try {
      const [
        currentPortfolioRes,
        modelPortfolioRes,
        customerStocksRes,
        modalStocksRes,
      ] = await Promise.all([
        currentPortfolioData(),
        fetchModelPortfolio(),
        getCurrentStocksWithCustomer(),
        getModalStocksWithCustomer(),
      ]);

      

      setAllApiData({
        currentPortfolio: currentPortfolioRes || [],
        modelPortfolio: modelPortfolioRes || {},
        customerStocks: customerStocksRes || [],
        modalStocks: modalStocksRes || [],
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setAllApiData((prevData) => ({
        ...prevData,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    // if (openPortfolioModal) {
    if (CustomerId) {
      fetchAllData();
    }
    // }
  }, [openPortfolioModal, CustomerId]);

  const handleClick = () => {
    setOpenPortfolioModal(true);
  };

  return (
    <>
      {loading ? (
        <Spin className="customSpinner" size="large" tip="Loading...">
          <div style={{ minHeight: "400px" }}></div>
        </Spin>
      ) : (
        <div className={styles.customer_div}>
          <Row gutter={[16, 16]} style={{ marginBottom: "12px" }}>
            <Col md={12} sm={22} xs={24} span={12}>
              <p className={styles.portfolio_text}>
                Model Portfolio Comparison
              </p>
              <h4
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  marginBottom: "9px",
                }}
              >
                Customer Name: <strong>{name}</strong> | Customer ID:{" "}
                <strong>{CustomerId}</strong>
              </h4>
            </Col>
            <Col md={8} sm={22} xs={24}></Col>
            <Col md={4} sm={24} xs={24}>
              <Button
                className="editModelButton"
                onClick={handleClick}
                disabled={
                  Object.keys(modelPortfolio).length === 0 ? true : false
                }
              >
                Edit Model Portfolio
              </Button>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className={styles.row_div}>
            <Col md={12} sm={22} xs={24}>
              <Card className="modelPortfolioCard" title="Model Portfolio">
                <span className={styles.category_label}>
                  Category Distribution:
                </span>
                <Row
                  gutter={[20, 4]}
                  style={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  <Col span={8} className={styles.table_label}>
                    Category
                  </Col>
                  <Col span={8} className={styles.table_label}>
                    Weightage
                  </Col>
                  <Col span={8} className={styles.table_label}>
                    No of Stocks
                  </Col>
                  <Divider />
                  {/* {modelPortfolio} */}
                  {Object.keys(modelPortfolio).length === 0 ? (
                    <Col span={24}>
                      <div className={styles.noData_text}> No Data</div>
                    </Col>
                  ) : (
                    modelPortfolio?.categories?.map((cat) => (
                      <>
                        <Col span={8} className={styles.table_data}>
                          {cat?.category}
                        </Col>
                        <Col span={8} className={styles.table_data}>
                          {cat?.weightage}%
                        </Col>
                        <Col span={8} className={styles.table_data}>
                          {cat?.no_of_stocks}
                        </Col>
                        <Divider />
                      </>
                    ))
                  )}

                  <Col span={20}></Col>
                </Row>
                <div style={{ marginTop: "10px" }}>
                  <span className={styles.stock_label}>
                    Stock wise Distribution:
                  </span>
                  <Table
                    columns={customerStocksColumn}
                    dataSource={modalStocks}
                    virtual
                    className="stockTable"
                    scroll={{
                      y: tableHeight,
                      x: 650,
                    }}
                  />
                </div>
              </Card>
            </Col>
            <Col md={12} sm={22} xs={24}>
              <Card className="modelPortfolioCard" title="Current Portfolio">
                <span className={styles.category_label}>
                  Category Distribution:
                </span>
                <Row
                  gutter={[20, 4]}
                  style={{ marginTop: "20px", marginLeft: "10px" }}
                >
                  <Col span={8} className={styles.table_label}>
                    Category
                  </Col>
                  <Col span={8} className={styles.table_label}>
                    Weightage
                  </Col>
                  <Col span={8} className={styles.table_label}>
                    No of Stocks
                  </Col>
                  <Divider />
                  {Object.keys(currentPortfolio).length === 0 ? (
                    <Col span={24}>
                      <div className={styles.noData_text}> No Data</div>
                    </Col>
                  ) : (
                    currentPortfolio?.map((cat) => (
                      <>
                        <Col span={8} className={styles.table_data}>
                          {cat?.category}
                        </Col>
                        <Col span={8} className={styles.table_data}>
                          {cat?.weightage}%
                        </Col>
                        <Col span={8} className={styles.table_data}>
                          {cat?.no_of_stocks}
                        </Col>
                        <Divider />
                      </>
                    ))
                  )}

                  <Col span={20}></Col>
                </Row>
                <div style={{ marginTop: "10px" }}>
                  <span className={styles.stock_label}>
                    Stock wise Distribution:
                  </span>
                  <Table
                    columns={customerStocksColumn1}
                    dataSource={customerStocks}
                    virtual
                    className="stockTable"
                    scroll={{
                      y: tableHeight,
                      x: 650,
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>

          {openPortfolioModal && (
            <EditPortfolioModal
              openPortfolioModal={openPortfolioModal}
              setOpenPortfolioModal={setOpenPortfolioModal}
              data={modelPortfolio}
              PortfolioId={PortfolioId}
            />
          )}
        </div>
      )}
    </>
  );
}

export default ModalPortfolio;
