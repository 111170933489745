const ENV = "dev";

let CONSTANTS = {};
CONSTANTS.ENV = ENV;
if (ENV === "local") {
  //
} else if (ENV === "dev") {
  CONSTANTS.BACKEND_API_BASEURL = "https://advisory.winwizeresearch.in";
  CONSTANTS.FRONTEND_BASEURL = "https://advisory.winwizeresearch.in";
} else if (ENV === "qa") {
  //
} else if (ENV === "uat") {
  //
} else if (ENV === "prod") {
  CONSTANTS.BACKEND_API_BASEURL = "";
}

export default CONSTANTS;
