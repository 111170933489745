import React, { useEffect, useState } from "react";
import { ReactComponent as FilterComponent } from "../../Image/assets/filterCombine.svg";
import { LeftOutlined } from "@ant-design/icons";
import styles from "./requestManager.module.scss";
import {
  Row,
  Col,
  Tabs,
  Card,
  Table,
  Input,
  Button,
  Tag,
  message,
  Spin,
  Tooltip,
  Modal,
  Divider,
} from "antd";
import "./request.css";
import { ReactComponent as Pending } from "./img/Pending.svg";
import { ReactComponent as Excecuted } from "./img/Excecuted.svg";
import { ReactComponent as Approve } from "../../Image/assets/approveReq.svg";
import { ReactComponent as Failed } from "./img/Group.svg";
import { SearchOutlined } from "@ant-design/icons";
import { EyeOutlined, BellOutlined } from "@ant-design/icons";
import { render } from "@testing-library/react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { capitalizeFirstLetter, dateFormat, formatNumber } from "../utils/auth";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import NewRequest from "./newRequest";
import CONSTANTS from "../../constants/variables";

function RequestManager() {
  const [activeTab, setActiveTab] = useState("1");
  const [adhocTransactions, setAdhocTransactions] = useState([]);
  const [sipTransactions, setSipTransactions] = useState([]);
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filteredTransaction, setFilteredTransaction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newReq, setNewReq] = useState({
    view: null,
    show: false,
  });
  const [openCount, setOpenCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [executedCount, setExecutedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState({
    open: false,
    name: null,
    id: null,
  });

  const { open, name, id } = isModalVisible;
  let accessToken = Cookies.get("AccessToken");
  const items = [
    {
      key: "1",
      label: "Adhoc Request",
      //   children: 'Content of Tab Pane 1',
    },
    {
      key: "2",
      label: "SIP Request",
    },
    {
      key: "3",
      label: "Rebalance Request",
      disabled: true,
    },
  ];

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 200; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins
    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);
    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  const getTransactions = async () => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    setLoading(true);
    try {
      const response = await axios.get(
        `${
          CONSTANTS?.BACKEND_API_BASEURL
        }/api/method/stock_portfolio_management.portfolio_master.doctype.adhoc_and_sip_transactions.api.fetch_adhoc_requests?filters={"request_type":"${
          activeTab === "1" ? "adhoc" : "sip"
        }"}`,
        {
          headers: headers,
        }
      );

      const data = response?.data?.message?.data || [];
      if (activeTab === "1") {
        setAdhocTransactions(data);
      } else if (activeTab === "2") {
        setSipTransactions(data);
      }

      setFilteredTransaction(data);
      setLoading(false);
      setOpenCount(data.filter((req) => req.status === "open").length);
      setApprovedCount(data.filter((req) => req.status === "approved").length);
      setExecutedCount(data.filter((req) => req.status === "executed").length);
      setFailedCount(data.filter((req) => req.status === "failed").length);
    } catch (err) {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (activeTab === "1") {
  //     getTransactions("adhoc");
  //   } else if (activeTab === "2") {
  //     getTransactions("sip");
  //   }
  // }, [activeTab]);

  const handleSearchBar = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = (
      activeTab === "1" ? adhocTransactions : sipTransactions
    )?.filter(
      (t) =>
        // t.customer_id.toLowerCase().includes(value) ||
        t?.customer_name?.toLowerCase().includes(value || null) ||
        t?.id?.toLowerCase().includes(value || null)
    );
    setFilteredTransaction(filteredData);
  };

  const statusColors = {
    approved: "#E3F6E5",
    Success: "#E3F6E5",
    failed: "#FDEBEC",
    executed: "#E3F6E5",
    closed: "#E2E2E2",
    open: "#FFF4D9",
    "approval sent": "#D6F0FF",
  };

  const statusTextColors = {
    approved: "#0FA958",
    Success: "#0FA958",

    failed: "#E92F39",
    executed: "#0FA958",
    closed: "#848484",
    open: "#F49C05",
    "approval sent": "#1A97DF",
  };

  const column1 = [
    {
      title: "Request ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 50,
      sorter: (a, b) => a?.id?.localeCompare(b?.id),
    },

    {
      title: "Request Date",
      dataIndex: "request_time",
      key: "request_time",

      width: 50,
      render: (date) => {
        return dateFormat(date);
      },
      sorter: (a, b) => new Date(a?.request_time) - new Date(b?.request_time),
    },
    {
      title: "Customer ID",
      dataIndex: "customer_id",
      key: "customer_id",
      width: 50,
      render: (text) => (text ? text : "-"),
      sorter: (a, b) => {
        const numA = parseInt(a?.customer_id?.replace(/\D/g, ""), 10) || 0; // Handle NaN cases
        const numB = parseInt(b?.customer_id?.replace(/\D/g, ""), 10) || 0;
        return numA - numB;
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 100,
      render: (text) => (text ? text : "-"),
      sorter: (a, b) => a?.customer_name?.localeCompare(b?.customer_name),
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "action",
      key: "action",
      width: 30,
      render: (action) => (
        <span style={{ color: action === "buy" ? "#0FA958" : "#EF4D56" }}>
          {capitalizeFirstLetter(action)}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 40,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      filters: [
        {
          text: "Approved",
          value: "approved",
        },
        {
          text: "Approval sent",
          value: "approval sent",
        },
        {
          text: "Executed",
          value: "executed",
        },
        {
          text: "Failed",
          value: "failed",
        },
        {
          text: "Open",
          value: "open",
        },
      ],
      onFilter: (value, record) => record.status === value,

      render: (status, record) => {
        const transactionId = record?.id || "N/A";
        const transactionDate = dateFormat(record?.request_time || new Date());
        return (
          <Tooltip
            color="#FFF"
            title={
              <div
                style={{ color: "#000", fontSize: "12px", fontWeight: "500" }}
              >
                <p>{`Transaction ID: ${transactionId}`}</p>
                <p>{`Transaction Date: ${transactionDate}`}</p>
              </div>
            }
            placement="rightTop"
          >
            <div
              style={{
                backgroundColor: statusColors[status] || "#F5F5F5",
                color: statusTextColors[status] || "#000000",
                padding: "3px",
                borderRadius: "12px",
                textAlign: "center",
                width: "100px",
              }}
            >
              {capitalizeFirstLetter(status)}
            </div>
          </Tooltip>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: (data, r) => (
        <span>
          <EyeOutlined
            style={{ marginRight: 16, color: "#B6B6B6", cursor: "pointer" }}
            onClick={() => {
              setNewReq({
                view: r,
                show: true,
              });
              // navigate("/initiate-adhoc-transaction", {
              //   state: { view: true, id: r?.customer_id, req_id: r?.id },
              // });
            }}
          />
          <Tooltip
            color="#FFF"
            placement="rightTop"
            title={
              <p
                style={{
                  fontSize: "12px",
                  color: "#1D1D1D",
                  fontWeight: "500",
                }}
              >
                Send Notification
              </p>
            }
          >
            <Button
              type="text"
              className="notification_button"
              onClick={() => {
               
                setIsModalVisible({
                  open: true,
                  name: r?.customer_name,
                  id: r?.customer_id,
                  doc_id:r?.id
                });
              }}
              disabled={r?.status === "open" ? false : true}
            >
              <BellOutlined style={{ color: "#B6B6B6" }} />
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  const bellClick = async (id) => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.adhoc_and_sip_transactions.api.send_transaction_approve_email?filters={"doc_id": "${id}"}`,
        {
          headers: headers,
        }
      );
      message.success(response?.data?.message?.msg);
      console.log("Model oid", isModalVisible?.doc_id)
      getTransactions();
    } catch (err) {
      message.error(err?.response?.data?.message?.msg);
    }
  };

  const column2 = [
    {
      title: "Request ID",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      width: 100,
      sorter: (a, b) => a?.id?.localeCompare(b?.id),
    },

    {
      title: "Request Date",
      dataIndex: "request_time",
      key: "request_time",
      ellipsis: true,
      width: 120,
      render: (date) => {
        return dateFormat(date);
      },
      sorter: (a, b) => new Date(a?.request_time) - new Date(b?.request_time),
    },
    {
      title: "Transaction ID",
      dataIndex: "order_id",
      key: "order_id",

      render: (text) => (text ? text : "-"),
      width: 120,
    },
    {
      title: "Type",
      dataIndex: "action",
      key: "action",
      render: (text) => (
        <span style={{ color: text === "buy" ? "#0FA958" : "#EF4D56" }}>
          {capitalizeFirstLetter(text)}
        </span>
      ),
      width: 80,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      width: 200,
    },
    {
      title: "Quantity",
      dataIndex: "no_of_stocks",
      key: "no_of_stocks",
      width: 80,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 120,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Approved",
          value: "approved",
        },
        {
          text: "Approval sent",
          value: "approval sent",
        },
        {
          text: "Executed",
          value: "executed",
        },
        {
          text: "Failed",
          value: "failed",
        },
        {
          text: "Open",
          value: "open",
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, record) => {
        const transactionId = record?.id || "N/A";
        const transactionDate = dateFormat(record?.request_time || new Date());
        return (
          <div
            style={{
              backgroundColor: statusColors[status] || "#F5F5F5",
              color: statusTextColors[status] || "#000000",
              padding: "3px",
              borderRadius: "12px",
              textAlign: "center",
              width: "100px",
            }}
          >
            {capitalizeFirstLetter(status)}
          </div>
        );
      },
      width: 180,
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      width: 80,
      render: (data, r) => (
        <span>
          <EyeOutlined
            style={{ marginRight: 16, color: "#B6B6B6", cursor: "pointer" }}
            onClick={() => {
              setNewReq({
                view: r,
                show: true,
              });
              // navigate("/initiate-adhoc-transaction", {
              //   state: { view: true, id: r?.customer_id, req_id: r?.id },
              // });
            }}
          />
        </span>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      requestId: "ID1093",
      requestDate: "12.05.2024",
      customerId: "WR1123",
      customerName: "Rajeev",
      action: "Buy",
      amount: "₹2,450",
      status: "Approved",
    },
    {
      key: "2",
      requestId: "ID1093",
      requestDate: "12.05.2024",
      customerId: "WR1123",
      customerName: "Sameer",
      action: "Sell",
      amount: "₹2,000",
      status: "Failed",
    },
  ];

  useEffect(() => {
    if (!newReq?.show) {
      getTransactions();
      // getPendingRequests();
    }
    // fetchAdhocId();
  }, [activeTab, newReq]);

  const sendNotification = async() => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    try {
      const payload = {
        data: {
          req_ids : [sipTransactions.filter((req) => req?.status === "Open")]
        }
      }
      console.log("payload",payload)
      // const response = await axios.post("https://advisory.winwizeresearch.in/api/method/stock_portfolio_management.portfolio_master[…]adhoc_and_sip_transactions.api.send_bulk_approve_emails", {
      //   headers: headers
      // })
    } catch (err) {

    }

  }

  const buttonsUi = [
    <Input
      prefix={<SearchOutlined style={{ color: "#A49F9F" }} />}
      placeholder="Search by customer ID, name"
      className="search_input"
      onChange={handleSearchBar}
      // onChange={handleSearchBar}
    />,
    <Button
      className="request-btn"
      onClick={() => {
        // navigate("/initiate-adhoc-transaction");
        setNewReq({
          view: null,
          show: true,
        });
      }}
    >
      New Request
    </Button>,
  ];


  console.log("adhoc id", adhocTransactions)

  return (
    <>
      {/* <div className={styles.header}>
        <LeftOutlined />
        <FilterComponent />
        <span> Request Manager | Adhoc Request</span>
      </div> */}

      {!newReq?.show && (
        <BreadCrumb
          data={[
            {
              name: "Request Manager ",
              active: false,
            },
            {
              name:
                activeTab === "1"
                  ? "Adhoc Request"
                  : activeTab === "2"
                  ? "SIP Request"
                  : "Rebalance Request",
              active: true,
            },
          ]}
        />
      )}
      {newReq?.show ? (
        <NewRequest
          activeTab={activeTab}
          newReqData={newReq}
          setNewReq={setNewReq}
        />
      ) : (
        <div className={styles.main_div}>
          <Row>
            <Col span={8} md={8} sm={22} xs={24}>
              <p className={styles.request_text}>Request Manager</p>
            </Col>
          </Row>
          <Tabs
            className="tab-col"
            activeKey={activeTab}
            items={items}
            onChange={(key) => setActiveTab(key)}
          />

          <div className="div_scroll">
            <Row gutter={[25, 25]}>
              <Col span={6} md={6} sm={22} xs={24}>
                <Card className="Card">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "25px",
                    }}
                  >
                    <div
                      style={{
                        padding: "14px 11px 9px 14px",
                        backgroundColor: "#6F6AF81C",
                        borderRadius: "10px",
                      }}
                    >
                      <Pending />
                    </div>
                    <div>
                      <h1 style={{ fontSize: "40px" }}>{openCount}</h1>
                      <p style={{ fontSize: "14px", color: "#5E5E5E" }}>
                        Open Requests
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col span={6} md={6} sm={22} xs={24}>
                <Card className="Card">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "25px",
                    }}
                  >
                    <div
                      style={{
                        padding: "14px 11px 9px 14px",
                        backgroundColor: "#0D80C21C",
                        borderRadius: "10px",
                      }}
                    >
                      <Approve />
                    </div>
                    <div>
                      <h1 style={{ fontSize: "40px" }}>{approvedCount}</h1>
                      <p style={{ fontSize: "14px", color: "#5E5E5E" }}>
                        Approved Requests
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col span={6} md={6} sm={22} xs={24}>
                <Card className="Card">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "25px",
                    }}
                  >
                    <div
                      style={{
                        padding: "14px 11px 9px 14px",
                        backgroundColor: "#00A9711C",
                        borderRadius: "10px",
                      }}
                    >
                      <Excecuted />
                    </div>
                    <div style={{ alignSelf: "flex-start" }}>
                      <h1 style={{ fontSize: "40px" }}>{executedCount}</h1>
                      <p style={{ fontSize: "14px", color: "#5E5E5E" }}>
                        Executed Requests
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col span={6} md={6} sm={22} xs={24}>
                <Card className="Card">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "25px",
                    }}
                  >
                    <div
                      style={{
                        padding: "14px 11px 9px 14px",
                        backgroundColor: "#EF4D561C",
                        borderRadius: "10px",
                      }}
                    >
                      <Failed />
                    </div>
                    <div style={{ alignSelf: "flex-start" }}>
                      <h1 style={{ fontSize: "40px" }}>{failedCount}</h1>
                      <p style={{ fontSize: "14px", color: "#5E5E5E" }}>
                        Failed Requests
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>

              {/* <Row> */}
              <Col span={24}>
                <Card
                  className="requestData"
                  title={
                    activeTab === "1"
                      ? "Adhoc Requests"
                      : activeTab === "2"
                      ? "SIP Request"
                      : "Rebalance List"
                  }
                  // style={{ marginTop: "18px" }}
                  extra={
                    activeTab === "2"
                      ? [
                          ...buttonsUi,
                          <Button
                            className="request-btn"
                            onClick={sendNotification}
                          >
                            Send Notification
                          </Button>,
                        ]
                      : buttonsUi
                  }
                >
                  {activeTab === "1" && (
                    <Table
                      virtual
                      className="RequestTable"
                      columns={column1}
                      dataSource={
                        searchText ? filteredTransaction : adhocTransactions
                      } // Use filtered data if searching
                      pagination={{ pageSize: 20 }}
                      // pagination={{pageSize: 20}}
                      scroll={{ y: tableHeight }}
                      loading={loading}

                      // scroll={{ y: tableHeight, x: 1300 }}
                    />
                  )}
                  {activeTab === "2" && (
                    <Table
                      virtual
                      className="RequestTable"
                      columns={column1}
                      dataSource={
                        searchText ? filteredTransaction : sipTransactions
                      }
                      pagination={{ pageSize: 20 }}
                      scroll={{ y: tableHeight }}
                      loading={loading}
                    />
                  )}
                  {activeTab === "3" && (
                    <Table className="RequestTable" columns={column2} />
                  )}
                </Card>
              </Col>
              {/* </Row> */}
            </Row>
          </div>
        </div>
      )}
      <Modal
        open={open} // This controls the modal's visibility
        title={"Send Notification"}
        okText="Send"
        cancelText="Cancel"
        onCancel={() => setIsModalVisible({ ...isModalVisible, open: false })} // Close modal on cancel
        onOk={() => {
          bellClick(isModalVisible?.doc_id) 
          setIsModalVisible({...isModalVisible, open: false })}}
        className="confirm_model"
        width={650}
      >
        <Divider style={{ margin: "14px 0px" }} />
        <p className={styles.deactivate_msg}>
          {`Are you sure you want to send this notification to ${name} (${id})?`}
        </p>
      </Modal>
    </>
  );
}

export default RequestManager;
