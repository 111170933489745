import React, { useEffect, useState } from "react";
import styles from "./setPassword.module.scss";

import Logo from "./img/Vector.png";
import MailLogo from "./img/email.png";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CONSTANTS from "../../constants/variables";

// import { Flex, Input, Typography } from 'antd';
import { Form, Input, Tooltip, message } from "antd";
import Password from "antd/es/input/Password";

function SetPassword({ setCurrentPage }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(false);
  const doc_id = localStorage.getItem("doc_id");

  const handleUpdateClick = async () => {
    const headers = {
      "X-Custom-Token": "frappe",
    };

    try {
      if (newPassword === confirmPassword) {
        const response = await axios.post(
          `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.login.api.update_password`,

          {
            doc_id,
            password: newPassword,
          },
          {
            headers: headers,
          }
        );

        message.success("Password updated sucessfully");
        setError(false);
        setStatus(true);
        setCurrentPage("login");
      } else {
        setError(true);
        setStatus(false);
        // alert('Password Did not matched')
      }
    } catch (err) {
      setError(true);
      setStatus(false);
      alert("Password not updated");
    }
  };

  return (
    <div className={styles.main_div}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.left_content}>
            <div className={styles.logo}>
              <img src={Logo} />
              <p>Winsight</p>
            </div>
            <p className={styles.left_paragraph}>
              Manage Portfolios, Optimise Investments, & Drive Performance
            </p>
          </div>

          <div className={styles.small}></div>
          <div className={styles.big}></div>
        </div>

        <div className={styles.right}>
          {/* <Tooltip
                  title={status ? "Password Chnages Sucessfully" : ""}
                  visible={error}
                  placement=""
                  className={styles.toolpit}
                >
       </Tooltip> */}

          {/* <img src={MailLogo} /> */}
          <h1>Change Password</h1>

          {/* <p>Enter your new password below to reset your account</p> */}

          {
            <Form onFinish={handleUpdateClick} className={styles.form}>
              <Form.Item
                className={styles.formItem}
                style={{ marginBottom: "1rem" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  // style={{fontSize:'16px', height:'48px'}}
                  className={styles.input_btn}
                />
              </Form.Item>

              <Form.Item
                className={styles.formItem}
                style={{ marginBottom: "3rem" }}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password",
                  },
                ]}
              >
                <Input.Password
                  length={6}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{
                    borderColor: error ? "red" : "",
                    width: "100%",
                    height: "48px",
                    fontSize: "16px",
                  }}
                  prefix={
                    error && (
                      <ExclamationCircleOutlined
                        style={{
                          color: "red",
                          height: "15px",
                          width: "15px",
                          marginRight: "5px",
                        }}
                      />
                    )
                  }
                  className={styles.input_btn}
                />
                <div className={styles.invalid_pass}>
                  {error && "Please make sure your password match"}
                </div>
              </Form.Item>

              <Form.Item className={styles.formItem}>
                <button
                  type="submit"
                  className={`${styles.login_btn} green_button`}
                >
                  Save
                </button>
              </Form.Item>
            </Form>
          }
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
