import React, { useState, useEffect } from "react";
import styles from "./login.module.scss";
import Logo from "./img/logo_transparent.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import "./login.css";
import { Card } from "antd";
import CONSTANTS from "../../constants/variables";

import { Form, Input, message, Tooltip } from "antd";

const MAX_ATTEMPTS = 3;
const LOCKOUT_TIME = 10 * 60 * 1000;

function Login({ setCurrentPage }) {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = data;
  // const [attempts, setAttempts] = useState(0);
  // const [lockoutTime, setLockoutTime] = useState(null);
  const [error, setError] = useState(false);
  // const [remainingTime, setRemainingTime] = useState(null);

  const navigate = useNavigate("/");

  // useEffect(() => {
  //   const savedLockoutTime = localStorage.getItem("lockoutTime");
  //   if (savedLockoutTime) {
  //     // setLockoutTime(parseInt(savedLockoutTime, 10));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (lockoutTime) {
  //     const interval = setInterval(() => {
  //       const remaining = LOCKOUT_TIME - (Date.now() - lockoutTime);
  //       if (remaining <= 0) {
  //         clearInterval(interval);
  //         setLockoutTime(null);
  //         setRemainingTime(null);
  //         localStorage.removeItem("lockoutTime");
  //       } else {
  //         setRemainingTime(remaining);
  //       }
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   }
  // }, [lockoutTime]);

  const handleLogin = async () => {
    // if (lockoutTime && Date.now() - lockoutTime < LOCKOUT_TIME) {
    //   message.error(`Too many failed attempts. Please try again after ${formatTime(remainingTime)}.`);
    //   return;
    // }
    const headers = {
      "X-Custom-Token": "frappe",
    };

    try {
      const response = await axios.get(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.login.api.validate_login`,

        {
          headers: headers,
          params: {
            email: email,
            password: password,
          },
        }
      );

      Cookies.set(
        "RefreshToken",
        response?.data?.message?.tokens?.refresh_token
      );
      Cookies.set("AccessToken", response?.data?.message?.tokens?.access_token);

      message.success("Login Sucessful!");
      setError(false);
      navigate("/customers");
    } catch (err) {
      setError(true);

      // setAttempts((prev) => prev + 1);

      // if (attempts + 1 >= MAX_ATTEMPTS) {
      //   const lockoutTimestamp = Date.now();
      //   // setLockoutTime(lockoutTimestamp);
      //   // localStorage.setItem("lockoutTime", lockoutTimestamp);
      //   // message.error(`Too many failed attempts. Please try again after ${formatTime(LOCKOUT_TIME)}.`);
      // } else {
      //   message.error(`Incorrect email or password. ${MAX_ATTEMPTS - attempts - 1} attempts left`);
      // }
    }
  };

  // const formatTime = (milliseconds) => {
  //   const totalSeconds = Math.floor(milliseconds / 1000);
  //   const minutes = Math.floor(totalSeconds / 60);
  //   const seconds = totalSeconds % 60;
  //   return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  // };

  return (
    <div className={styles.main_div}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.left_content}>
            <div className={styles.logo}>
              <img src={Logo} />
              {/* <p>Winsight</p> */}
            </div>
            <p className={styles.left_paragraph}>
              Manage Portfolios, Optimise Investments, & Drive Performance
            </p>
          </div>

          <div className={styles.small}></div>
          <div className={styles.big}></div>
        </div>

        <div className={styles.right}>
          <h1>Login</h1>

          <Form
            className={styles.form}
            initialValues={{
              remember: true,
            }}
            onFinish={handleLogin}
            autoComplete="off"
          >
            <Form.Item
              className={styles.formItem}
              style={{ marginBottom: "1rem" }}
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
              validateStatus={error ? "error" : ""}
              help={
                error &&
                "The email address that you've entered doesn't match any account."
              }
            >
              {/* <Tooltip
                title={error ? "The email address that you've entered doesn't match any account." : ""}
                visible={error}
                placement="top"
              > */}
              <Input
                placeholder="Email"
                value={email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                prefix={
                  error && (
                    <ExclamationCircleOutlined
                      style={{
                        color: "red",
                        height: "15px",
                        width: "15px",
                        marginRight: "5px",
                      }}
                    />
                  )
                }
                style={{ borderColor: error ? "red" : "" }}
              />
              {/* </Tooltip> */}
            </Form.Item>

            <Form.Item
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                className="PasswordInput"
                placeholder="Password"
                value={password}
                type="password"
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />

              <a
                className={styles.forgot_btn}
                onClick={() => setCurrentPage("resetPassword")}
                style={{ marginBottom: "4rem" }}
              >
                Forgot Password?
              </a>
            </Form.Item>
            <Form.Item className={styles.formItem}>
              <button className={`${styles.login_btn} green_button`}>
                LOGIN
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
