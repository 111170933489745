import React, { useState, useEffect } from "react";
import styles from "./portfolio.module.scss";
import "./addPortfolioModal.css";
import { Button, Card, Divider, Input, message, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as NoDataImage } from "../../Image/assets/Nodata.svg";
import { ReactComponent as PortfolioContent } from "../../Image/assets/portfolioContent.svg";
import AddPortfolioModal from "./addPortfolioModal";
import AssignPortfolioModel from "./portfolioAssignModal";
import axios from "axios";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { json, useLocation, useNavigate } from "react-router-dom";
import { Table, Row, Col, Typography } from "antd";
import EditLogo from "../../Image/editLogo.svg";
import Cookies from "js-cookie";
import { isTokenExpired, refreshAccessToken, logoutUser } from "../utils/auth";
import CONSTANTS from "../../constants/variables";

// import AddPortfolioModal from "./addPortfolioModal";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

const { Title, Text } = Typography;

function ModelPortfolioDetails() {
  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);
  const [openPortfolioAssignModal, setOpenPortfolioAssignModal] =
    useState(false);
  const [edit, setEdit] = useState(false);
  const [assign, setAssign] = useState(false);
  const location = useLocation();
  const { portfolioid, portfoliotitle, portfoliofilter } = location.state || {};
  const navigate = useNavigate();
  const [graphData, setGraphData] = useState([]);
  const [portfolioList, setPortfolioList] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [editData, setEditData] = ([])

  useEffect(() => {
    let accessToken = Cookies.get("AccessToken");

    if (!portfolioid) return;
    const fetchDataHandle = async () => {
      const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_portfolio_based_stocks_data?filters={"doc_id":"${portfolioid}"}`;
      const headers = {
        "X-Custom-Token": "frappe",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      setLoading(true);

      try {
        const res = await axios.get(apiUrl, { headers });

        let responseData = res?.data;

        // responseData = responseData.replace(/NaN/g, 0);

        const chartData = [
          {
            year: "1 Year",
            value: isNaN(responseData?.message?.return_over_1year)
              ? 0
              : responseData?.message?.return_over_1year,
          },
          {
            year: "3 Year",
            value: isNaN(responseData?.message?.return_over_3years)
              ? 0
              : responseData?.message?.return_over_3years,
          },
          {
            year: "5 Year",
            value: isNaN(responseData?.message?.return_over_5years)
              ? 0
              : responseData?.message?.return_over_5years,
          },
        ];

        setData(responseData?.message?.data);
        setGraphData(chartData);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        message.error(error?.response?.data?.message?.msg);
      }
    };

    fetchDataHandle();
  }, [portfolioid]);

  const fetchPortfolioData = () => {
    let accessToken = Cookies.get("AccessToken");

    if (!portfolioid) return;
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_portfolio?filters={"doc_id":"${portfolioid}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    setLoading(true);

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setPortfolioList(response?.data?.message?.data);
        // setEditData(response?.data?.message);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPortfolioData();
  }, [openPortfolioModal]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <p>{label}</p>
          <p>{`Value: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Weightage",
      dataIndex: "weightage",
      key: "weightage",
    },
    {
      title: "No.of Stocks",
      dataIndex: "no_of_stocks",
      key: "no_of_stocks",
    },
  ];

  const handleAssign = () => {
    setAssign(true);
    setOpenPortfolioAssignModal(true);
  };

  const handleClick = () => {
    setEdit(true);
    setOpenPortfolioModal(true);
    fetchPortfolioData();
  };

  return (
    <>
      <>
        {/* <div className={styles.header}>
        <LeftOutlined onClick={() => navigate(-1)} />
        <PortfolioContent />
        <span> Portfolio Management | Model Portfolio Details</span>
        </div> */}
        <BreadCrumb
          data={[
            {
              name: "Portfolio Management",
              url: "/portfolio-management",
              active: false,
            },
            {
              name: "Model Portfolio Details",
              url: "",
              active: true,
            },
          ]}
        />
        <div className={styles.main_div}>
          <Row gutter={[16, 16]} style={{ marginBottom: "12px" }}>
            {/* <div className={styles.portfolioList_div}> */}
            <Col md={12} sm={22} xs={24} span={12}>
              <p className={styles.portfolio_text}>Model Portfolio List</p>
              {/* <Input
          placeholder="Search for filters"
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          className={styles.searchbar}
          /> */}
            </Col>
            <Col md={8} sm={22} xs={24} span={8}></Col>
            <Col md={4} sm={22} xs={24} span={4}>
              <Button
                className="portfolio_assign_button"
                onClick={handleAssign}
              >
                Assign Portfolio
              </Button>
            </Col>
          </Row>

          {loading ? (
            <Spin tip="Loading..." size="large" className="customSpinner">
              <div style={{ minHeight: "400px" }}></div>
            </Spin>
          ) : (
            <div className="viewScrollableDiv">
              {portfolioList?.categories?.length > 0 ? (
                <Row gutter={[20, 20]}>
                  <Col md={14} sm={22} xs={24} span={14}>
                    <Card className="graphCard">
                      <h3
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          padding: "10px",
                        }}
                      >
                        Portfolio Return Over Time
                      </h3>
                      <br />

                      <AreaChart
                        width={600}
                        height={340}
                        data={graphData}
                        margin={{ top: 10, bottom: 10 }}
                        className="chart-container"
                      >
                        <defs>
                          <linearGradient
                            id="colorValue"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#00A971"
                              stopOpacity={0.6}
                            />
                            <stop
                              offset="95%"
                              stopColor="#00A971"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis
                          dataKey="year"
                          tickMargin={15}
                          axisLine={false}
                          tickLine={false}
                          domain={["dataMin", "dataMax"]}
                          // hide={true}
                        />
                        <YAxis
                          dataKey="value"
                          padding={{ top: 0 }}
                          axisLine={false}
                          tickLine={false}
                          tickMargin={15}
                          stroke="#121212"
                          // hide={true}
                        />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="value"
                          stroke="#00A971"
                          fillOpacity={19}
                          fill="url(#colorValue)"
                        />
                      </AreaChart>
                    </Card>
                  </Col>
                  <Col md={10} sm={22} xs={24} span={10}>
                    <Card
                      className="viewDetailCard"
                      title={
                        <div>
                          <h5
                            style={{
                              margin: "0px",
                              color: "#2E2E2E",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {portfolioList?.portfolio}
                          </h5>
                          <p
                            style={{
                              margin: "0px",
                              color: "#0FA958",
                              fontSize: "10px",
                              lineHeight: "12.13px",
                              fontWeight: "500",
                            }}
                          >
                            filter : {portfolioList?.filter_obj?.filter_name}
                          </p>
                        </div>
                      }
                      extra={
                        <Button className="editButton" onClick={handleClick}>
                          <img src={EditLogo}></img>
                        </Button>
                      }
                    >
                      <div className="table-container">
                        <Row>
                          <Col
                            md={8}
                            sm={22}
                            xs={24}
                            span={8}
                            className="table-label"
                          >
                            Category
                          </Col>
                          <Col
                            md={8}
                            sm={22}
                            xs={24}
                            span={8}
                            className="table-label"
                          >
                            Weightage
                          </Col>
                          <Col
                            md={8}
                            sm={22}
                            xs={24}
                            span={8}
                            className="table-label"
                          >
                            No of Stocks
                          </Col>
                          <Divider />
                          {portfolioList?.categories?.map((cat, index) => (
                            <React.Fragment key={index}>
                              <Col
                                md={8}
                                sm={22}
                                xs={24}
                                span={8}
                                className={styles.table_data}
                              >
                                {cat?.category}
                              </Col>
                              <Col
                                md={8}
                                sm={22}
                                xs={24}
                                span={8}
                                className={styles.table_data}
                              >
                                {cat?.weightage}%
                              </Col>
                              <Col
                                md={8}
                                sm={22}
                                xs={24}
                                span={8}
                                className={styles.table_data}
                              >
                                {cat?.no_of_stocks}
                              </Col>
                              <Divider />
                            </React.Fragment>
                          ))}
                        </Row>
                      </div>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Card className={styles.empty_card}>
                  <NoDataImage />
                  <p className="message">No data has been added.</p>
                </Card>
              )}
              <p
                style={{
                  marginTop: "1rem",
                  fontSize: "16px",
                  color: "#1D1D1D",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                Stock Details
              </p>
              <Divider className="bottomTable-header" />
              <div className="stock-details-table-header">
                <div className="stock-details-table">
                  <Row>
                    <Col md={4} sm={22} xs={24} span={4}>
                      Stock Name
                    </Col>
                    <Col md={3} sm={22} xs={24} span={3}>
                      BSE Code
                    </Col>
                    <Col md={3} sm={22} xs={24} span={3}>
                      NSE Code
                    </Col>
                    <Col md={4} sm={22} xs={24} span={5}>
                      Sector
                    </Col>
                    <Col md={3} sm={22} xs={24} span={3}>
                      Current Price
                    </Col>
                    <Col md={3} sm={22} xs={24} span={3}>
                      Market Cap
                    </Col>
                    <Col md={2} sm={22} xs={24} span={2}>
                      P/E Ratio
                    </Col>
                    <Col md={2} sm={22} xs={24} span={2}>
                      EPS
                    </Col>
                    {/* <Divider /> */}
                  </Row>
                </div>
              </div>
              <div className="bottomTableCard">
                {/* <div
              className="viewDetailTableScrollBar"
              // style={{ height: "900px", overflowY: "auto" }}
            > */}
                <Row className="row-header">
                  <Col span={24}>
                    Large Cap ({data[0]?.large_cap_stocks?.length})
                  </Col>
                </Row>

                {data[0]?.large_cap_stocks?.length > 0 ? (
                  data[0]?.large_cap_stocks?.map((stock, index) => (
                    <Row key={index} className="table-data">
                      <Col span={4}>{stock?.stock_name}</Col>
                      <Col span={3}>{stock?.bse_code}</Col>
                      <Col span={3}>{stock?.nse_code}</Col>
                      <Col span={5}>
                        <Text ellipsis>{stock?.industry}</Text>
                      </Col>{" "}
                      <Col span={3}>{stock?.current_price}</Col>
                      <Col span={2}>{stock?.market_capitalization}</Col>
                      <Col span={2}>{stock?.price_to_earning}</Col>
                      <Col span={2}>{stock?.eps}</Col>
                      <Divider />
                    </Row>
                  ))
                ) : (
                  <p className="message">No Large cap stocks available.</p>
                )}

                <Row className="row-header">
                  <Col span={24}>
                    Mid Cap ({data[1]?.mid_cap_stocks?.length})
                  </Col>
                </Row>
                {data[1]?.mid_cap_stocks?.length > 0 ? (
                  data[1]?.mid_cap_stocks?.map((stock, index) => (
                    <Row key={index} className="table-data">
                      <Col span={4}>{stock?.stock_name}</Col>
                      <Col span={3}>{stock?.bse_code}</Col>
                      <Col span={3}>{stock?.nse_code}</Col>
                      <Col span={5}>
                        <Text ellipsis>{stock?.industry}</Text>
                      </Col>{" "}
                      <Col span={3}>{stock?.current_price}</Col>
                      <Col span={2}>{stock?.market_capitalization}</Col>
                      <Col span={2}>{stock?.price_to_earning}</Col>
                      <Col span={2}>{stock?.eps}</Col>
                      <Divider />
                    </Row>
                  ))
                ) : (
                  <p className="message">No Mid cap stocks available.</p>
                )}
                <Row className="row-header">
                  <Col span={24}>
                    Small Cap ({data[2]?.small_cap_stocks?.length})
                  </Col>
                </Row>
                {data[2]?.small_cap_stocks?.length > 0 ? (
                  data[2]?.small_cap_stocks?.map((stock, index) => (
                    <Row key={index} className="table-data">
                      <Col span={4}>{stock?.stock_name}</Col>
                      <Col span={3}>{stock?.bse_code}</Col>
                      <Col span={3}>{stock?.nse_code}</Col>
                      <Col span={5}>
                        <Text ellipsis>{stock?.industry}</Text>
                      </Col>{" "}
                      <Col span={3}>{stock?.current_price}</Col>
                      <Col span={2}>{stock?.market_capitalization}</Col>
                      <Col span={2}>{stock?.price_to_earning}</Col>
                      <Col span={2}>{stock?.eps}</Col>
                      <Divider />
                    </Row>
                  ))
                ) : (
                  <p className="message">No Small cap stocks available.</p>
                )}

                <Row className="row-header">
                  <Col span={24}>
                    Micro Cap ({data[3]?.micro_cap_stocks?.length})
                  </Col>
                </Row>
                {data[3]?.micro_cap_stocks?.length > 0 ? (
                  data[3]?.micro_cap_stocks?.map((stock, index) => (
                    <Row key={index} className="table-data">
                      <Col span={4}>{stock?.stock_name}</Col>
                      <Col span={3}>{stock?.bse_code}</Col>
                      <Col span={3}>{stock?.nse_code}</Col>
                      <Col span={5}>
                        <Text ellipsis>{stock?.industry}</Text>
                      </Col>{" "}
                      <Col span={3}>{stock?.current_price}</Col>
                      <Col span={2}>{stock?.market_capitalization}</Col>
                      <Col span={2}>{stock?.price_to_earning}</Col>
                      <Col span={2}>{stock?.eps}</Col>
                      <Divider />
                    </Row>
                  ))
                ) : (
                  <p className="message">No Micro cap stocks available.</p>
                )}
                {/* </div> */}
              </div>
            </div>
          )}
        </div>

        {openPortfolioModal && (
          <AddPortfolioModal
            edit={edit}
            data={portfolioList}
            openPortfolioModal={openPortfolioModal}
            setOpenPortfolioModal={setOpenPortfolioModal}
          />
        )}

        {openPortfolioAssignModal && (
          <AssignPortfolioModel
            openPortfolioAssignModal={openPortfolioAssignModal}
            setOpenPortfolioAssignModal={setOpenPortfolioAssignModal}
            portfolioid={portfolioid}
            portfoliotitle={portfoliotitle}
          />
        )}
      </>
    </>
  );
}

export default ModelPortfolioDetails;
