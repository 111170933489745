import React, { useEffect, useState } from "react";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { ReactComponent as CustomerContent } from "../../Image/assets/customerContent.svg";
import styles from "./customer.module.scss";
import {
  Col,
  Row,
  Button,
  Card,
  Table,
  Input,
  message,
  Spin,
  Skeleton,
  Tag,
  Switch,
  Modal,
  Divider
} from "antd";
import { ReactComponent as EyeIcon } from "../../Image/assets/eyeicon.svg";
import { ReactComponent as EditIcon } from "../../Image/assets/editicon.svg";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import axios from "axios";
import Cookies from "js-cookie";
import { render } from "@testing-library/react";
import ModalPortfolio from "./modalPortfolio";
import TransactionHistory from "./transactionHistory";
import EditCustomerModel from "./editCustomerDetail";
import EditPortfolioOverviewModel from "./editPortfolioOverview";
import EditAdhocModel from "./editAdhocTransaction";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import "./customer.css";
import CONSTANTS from "../../constants/variables";

// Pie Chart

const RADIAN = Math.PI / 180;

// Custom label function
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);


  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function CustomerDetails() {
  const navigate = useNavigate();
  const loaction = useLocation();
  const path = loaction?.pathname.split("/")[2];

  const portfolioProp = path === "portfolio";
  const transaction = path === "transaction";
  let accessToken = Cookies.get("AccessToken");
  const [stockData, setStockData] = useState({
    tableData: [],
    loading: false,
  });
  const { tableData, loading } = stockData;
  const [customerData, setCustomerData] = useState({
    customer_data: {},
    skeleton: false,
  });

  const { skeleton } = customerData;
  const customer_data = {
    ...customerData?.customer_data,
    model_portfolio: customerData?.customer_data?.portfolio_type?.portfolio,
  };
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  // const [openModalPortfolio, setOpenModalPortfolio] = useState(false);
  // const [transaction, settransaction] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [openAdhocModel, setOpenAdhocModel] = useState(false);

  const [modalType, setModalType] = useState({
    view: false,
    edit: false,
    portfolio: false,
    information: false,
  });
  const { view, edit, portfolio, information } = modalType;
  const CustomerId = searchParams.get("id");
  const [customerId, setcustomerId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isActive, setIsActive] = useState("");


  const data = [
    {
      name: "Large-cap",
      value: customer_data?.portfolio_distribution?.large_cap,
      color: "#22CFB5",
    },
    {
      name: "Small-cap",
      value: customer_data?.portfolio_distribution?.small_cap,
      color: "#FD566E",
    },
    {
      name: "Mid-cap",
      value: customer_data?.portfolio_distribution?.mid_cap,
      color: "#1A97DF",
    },
    {
      name: "Micro-cap",
      value: customer_data?.portfolio_distribution?.micro_cap,
      color: "#F8C92E",
    },
  ];

  const noData = [
    {
      name: "No Data",
      value: 1,
      color: "#a4aaa9",
    },
  ];

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 200; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins
    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  const allValuesZero = data.every((item) => item.value === 0);

  // Stock Data
  useEffect(() => {
    if (!CustomerId) return;
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customer_stocks.api.fetch_customer_stocks?filters={"customer_id": "${CustomerId}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    setStockData({ ...tableData, loading: true });

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setStockData({
          tableData: response?.data?.message?.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [CustomerId]);

  // Customer Data

  const getCustomerData = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.fetch_customer_details?filters={"doc_id": "${CustomerId}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    setCustomerData({ ...customer_data, skeleton: true });

    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        setCustomerData({
          customer_data: response?.data?.message,
          skeleton: false,
        });
      
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (CustomerId) {
      getCustomerData();
    }
  }, [CustomerId]);


  console.log("customer data", customer_data)

  const columns1 = [
    {
      title: "Stock ID",
      dataIndex: "stock_id",
      key: "stock_id",
      fixed: "left",
      width: 100,
      // ellipsis: true,
    },

    {
      title: "Qty Held",
      dataIndex: "qty_held",
      key: "qty_held",
      width: 80,
    },
    {
      title: "Total Amount",
      dataIndex: "total_val",
      key: "total_val",
      width: 100,
      // ellipsis: true,
    },
    {
      title: "Good",
      dataIndex: "good",
      key: "good",

      width: 100,
    },

    {
      title: "Poor",
      dataIndex: "poor",
      key: "poor",
      width: 100,
    },
    {
      title: "Bluechip",
      dataIndex: "bluechip",
      key: "bluechip",
      width: 100,
      // ellipsis: true,
    },
  ];

  const filteredStockData = tableData?.filter((item) => {
    const searchTextLower = searchText?.toLowerCase();
    return item.stock_id?.toLowerCase()?.includes(searchTextLower);
  });

  const handleModal = (name, view) => {
    setModalType({ ...modalType, [name]: true, [view]: true });
  };

  const closeModal = () => {
    getCustomerData();
    setModalType({
      portfolio: false,
      view: false,
      edit: false,
      information: false,
    });
  };

  const handleAdhocButton = () => {
    setOpenAdhocModel(true);
  };

  const handleCloseAdhocModal = () => {
    setOpenAdhocModel(false);
  };

  // const portfolioId =
  const breadCrumbData = [
    {
      name: "Customer Management",
      url:
        portfolioProp || transaction
          ? `/customer-details?id=${CustomerId}`
          : "/customers",
      active: false,
      callBack: () => {
        navigate("/customers");
      },
    },
    {
      name: "Customer Details",
      url: "",
      active: transaction ? false : portfolioProp ? false : true,
      callBack: () => {
        // settransaction(false);
        navigate(`/customer-details?id=${CustomerId}`);
      },
    },
  ];

  const customerStatusUpdate = async (values) => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.post(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.update_customer_details`,
        {
          doc_id: customerId,
          data: {
            customer_status: isActive === "Active" ? "InActive" : "Active",
          },
        },
        {
          headers: headers,
        }
      );
      message.success(response?.data?.message?.msg);
      // getCustomerData("", currentPage, pageSize);
      setIsModalVisible(false);
      getCustomerData()
    } catch (err) {
      message.error(err?.response?.data?.message?.msg);
    }
  };


const showModel = (id, text) => {
  setIsActive(text);
  setcustomerId(id);
  setIsModalVisible(true);
};

  return (
    <>
      <BreadCrumb
        data={
          transaction
            ? [
                ...breadCrumbData,
                {
                  name: "Transaction History",
                  url: "",
                  active: true,
                },
              ]
            : portfolioProp
            ? [
                ...breadCrumbData,
                {
                  name: "Model Portfolio Comparison",
                  url: "",
                  active: true,
                },
              ]
            : breadCrumbData
        }
      />
      {portfolioProp ? (
        <ModalPortfolio
          CustomerId={customer_data?.name}
          name={customer_data?.customer_name}
          PortfolioId={customer_data?.portfolio_type?.name}
        />
        
        
      ) : // portfolioId={portfolioId}
      transaction ? (
        <TransactionHistory
          id={customer_data?.name}
          name={customer_data?.customer_name}
        />
      ) : (
        <div className={styles.customer_div}>
          <Row gutter={[16, 16]} style={{ marginBottom: "12px" }}>
            <Col md={12} sm={22} xs={24} span={12}>
              <p className={styles.portfolio_text}>
                Customer Details
                
                  
                 
                  
              </p>
              <p style={{color:"#0FA958", fontSize:"14px", fontWeight:"500"}}>
                status: {" "}
              <Switch
                  className="status_toggle"
                  checked={customerData?.customer_data?.customer_status === "Active"}
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  onChange={() => {
                    showModel(customerData?.customer_data?.name,customerData?.customer_data?.customer_status);
                  }}

          // unCheckedChildren={text === "InActive" && text}
        />
              </p>
            </Col>
            <Col md={4} sm={22} xs={24} span={4}></Col>
            <Col md={4} sm={22} xs={24} span={4}>
              <Button
                className="customer_portfolio_button"
                onClick={() => {
                  // setOpenModalPortfolio(true);
                  navigate(`/customer-details/portfolio?id=${CustomerId}`);
                }}
              >
                Model Portfolio
              </Button>
            </Col>
            <Col md={4} sm={22} xs={24} span={4}>
              <Button
                className="customer_portfolio_button"
                onClick={() => {
                  // settransaction(true);
                  navigate(`/customer-details/transaction?id=${CustomerId}`);
                }}
              >
                Transaction History
              </Button>
            </Col>
          </Row>

          <div className="cards">
            <Row gutter={[16, 16]}>
              <Col md={8} sm={22} xs={24} span={8}>
                <Card className="customer-detail-card">
                  {skeleton ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <Row>
                        <Col
                          span={14}
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          Basic Information
                        </Col>
                        <Col span={6}></Col>
                        <Col span={1}>
                          <EyeIcon
                            onClick={() => handleModal("information", "view")}
                            style={{ fill: "#84828A", cursor: "pointer" }}
                          />
                        </Col>
                        <Col span={1}></Col>
                        <Col span={1}>
                          <EditIcon
                            onClick={() => handleModal("information", "edit")}
                            style={{ fill: "#84828A", cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">
                            {customer_data?.customer_name}
                          </p>
                          <span className="info">Name</span>
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">{customer_data?.name}</p>
                          <span className="info">ID</span>
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">
                            {customer_data?.email}
                          </p>
                          <span className="info">Email</span>
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">
                            {customer_data?.mobile}
                          </p>
                          <span className="info">Mobile</span>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>

              <Col md={8} sm={22} xs={24} span={8}>
                <Card className="customer-detail-card">
                  {skeleton ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <Row>
                        <Col
                          span={20}
                          style={{ fontSize: "16px", fontWeight: "500" }}
                        >
                          Portfolio Overview{" "}
                        </Col>
                        <Col span={1}>
                          <EyeIcon
                            onClick={() => handleModal("portfolio", "view")}
                            style={{ fill: "#84828A", cursor: "pointer" }}
                          />
                        </Col>
                        <Col span={1}></Col>
                        <Col span={1}>
                          <EditIcon
                            onClick={() => handleModal("portfolio", "edit")}
                            style={{
                              fill: "#84828A",
                              cursor: "pointer",
                              position: "relative",
                              top: "1px",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">
                            {customer_data?.portfolio_val}
                          </p>
                          <span className="info">Portfolio Value</span>
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">
                            {customer_data?.sip_amount}
                          </p>
                          <span className="info">SIP Monthly Investment</span>
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">
                            {customer_data?.risk_profile
                              ? customer_data?.risk_profile
                              : "Not assigned"}
                          </p>
                          <span className="info">Risk Profile</span>
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">
                            {customer_data?.portfolio_type?.portfolio
                              ? customer_data?.portfolio_type?.portfolio
                              : "Not assigned"}
                          </p>
                          <span className="info">Model Portfolio</span>
                        </Col>
                      </Row>
                      <Row className="card-row">
                        <Col md={24} sm={22} xs={24} span={24}>
                          <p className="customer-info">
                            {customer_data?.invest_sip_into}
                          </p>
                          <span className="info">Invest SIP into</span>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
              <Col md={8} sm={22} xs={24} span={8}>
                <Card className="pie-card">
                  <Row>
                    <Col
                      md={24}
                      sm={22}
                      xs={24}
                      span={24}
                      style={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      Portfolio Distribution
                    </Col>
                  </Row>
                  {/* <Row> */}
                  {/* Render PieChart directly inside here */}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {allValuesZero ? (
                      <PieChart width={260} height={270} className="piechart">
                        <Pie
                          data={noData}
                          cx={130}
                          cy={110}
                          labelLine={false}
                          // label={renderCustomizedLabel}
                          innerRadius={50}
                          outerRadius={100}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {noData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>

                        {/* <Tooltip /> */}
                        <Legend
                          className="pie-legend"
                          layout="horizontal"
                          align="center"
                          verticalAlign="bottom"
                          iconSize={15}
                          content={(props) => {
                            const { payload } = props;
                            return (
                              <ul
                                style={{
                                  display: "grid",
                                  // gridTemplateColumns: "repeat(2, 1fr)",
                                  justifyContent: "center",
                                  marginTop: "16px",
                                }}
                              >
                                {payload.map((entry, index) => (
                                  <li
                                    key={`item-${index}`}
                                    style={{
                                      textAlign: "center",
                                      listStyleType: "none",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: entry.color,
                                        marginRight: 5,
                                      }}
                                    >
                                      ■
                                    </span>
                                    <span style={{ color: "black" }}>
                                      {entry.value}
                                    </span>{" "}
                                    {/* Set the text color to black */}
                                  </li>
                                ))}
                              </ul>
                            );
                          }}
                        />
                      </PieChart>
                    ) : (
                      <PieChart width={260} height={270} className="piechart">
                        <Pie
                          data={data}
                          cx={130}
                          cy={110}
                          labelLine={false}
                          // label={renderCustomizedLabel}
                          innerRadius={50}
                          outerRadius={100}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>

                        <Tooltip />
                        <Legend
                          className="pie-legend"
                          layout="horizontal"
                          align="center"
                          verticalAlign="bottom"
                          iconSize={15}
                          content={(props) => {
                            const { payload } = props;
                            return (
                              <ul
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(2, 1fr)",
                                  justifyContent: "center",
                                  marginTop: "16px",
                                }}
                              >
                                {payload.map((entry, index) => (
                                  <li
                                    key={`item-${index}`}
                                    style={{
                                      textAlign: "center",
                                      listStyleType: "none",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: entry.color,
                                        marginRight: 5,
                                      }}
                                    >
                                      ■
                                    </span>
                                    <span style={{ color: "black" }}>
                                      {entry.value}
                                    </span>{" "}
                                    {/* Set the text color to black */}
                                  </li>
                                ))}
                              </ul>
                            );
                          }}
                        />
                      </PieChart>
                    )}
                  </div>
                  {/* </Row> */}
                </Card>
              </Col>

              <Col md={24} sm={22} xs={24} span={24}>
                <Card
                  className="customerDetailDataTable"
                  title="Stock Holdings"
                  extra={[
                    <Input
                      prefix={<SearchOutlined style={{ color: "#A49F9F" }} />}
                      placeholder="Search by Stock ID, Market Cap, or Industry"
                      className="search_input"
                      onChange={(e) => setSearchText(e.target.value)} // Update search input
                      value={searchText} // Set input value
                    />,
                  ]}
                >
                  {loading ? (
                    <Spin
                      tip="Loading..."
                      size="large"
                      className="customSpinner"
                    >
                      <div style={{ minHeight: "200px" }}></div>
                    </Spin>
                  ) : (
                    <Table
                      virtual
                      columns={columns1}
                      className="customer_detail_table"
                      dataSource={filteredStockData}
                      pagination={false}
                      scroll={{
                        y: tableHeight,
                        // x: 1300,
                      }}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {information && (
        <EditCustomerModel
          visible={edit || view}
          onClose={closeModal}
          customer={customer_data}
          CustomerId={customer_data?.name}
          viewOnly={view}
        />
      )}

      {portfolio && (
        <EditPortfolioOverviewModel
          visible={edit || view}
          onClose={closeModal}
          customer={customer_data}
          CustomerId={customer_data?.name}
          viewOnly={view}
        />
      )}
      {openAdhocModel && (
        <EditAdhocModel
          openAdhocModal={openAdhocModel}
          setOpenAdhocModal={handleCloseAdhocModal}
        />
      )}

<Modal
        open={isModalVisible}
        title={
          isActive === "Active"
            ? "Deactivate the customer ?"
            : "Activate the customer?"
        }
        okText="Confirm"
        cancelText="Cancel"
        onOk={customerStatusUpdate}
        onCancel={() => setIsModalVisible(false)}
        className="confirm_model"
        width={650}
      >
        <Divider style={{ margin: "14px 0px" }} />
        {isActive === "Active" ? (
          <p className={styles.deactivate_msg}>
            This action will disable their account, and they will no longer have
            access to our services. You can reactivate the account at any time.
          </p>
        ) : (
          <p className={styles.deactivate_msg}>
            This action will restore their account, granting them full access to
            our services. You can deactivate the account at any time.
          </p>
        )}
      </Modal>
    </>
  );
}

export default CustomerDetails;
