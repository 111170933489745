import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./filterManagement.module.scss";
import { LeftOutlined } from "@ant-design/icons";
import { ReactComponent as FilterComponent } from "../../Image/assets/filterCombine.svg";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
} from "antd";
import axios from "axios";
import { render } from "@testing-library/react";
import Cookies from "js-cookie";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import "./filterManagement.css";
import "../Request Manager/request.css";
import CONSTANTS from "../../constants/variables";
import {
  Cell,
  Pie,
  PieChart,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const { TextArea } = Input;

const columns1 = [
  {
    title: "SN",
    key: "key",
    width: 80,
    // fixed: "left",
    render: (text, record, index) => index + 1, // Add 1 to the index to start from 1
  },
  {
    title: "Stock Name",
    key: "stock_name",
    dataIndex: "stock_name",
    // ellipsis: true,
    // fixed: "left",
    sorter: (a, b) => a.stock_name.localeCompare(b.stock_name),
  },

  {
    title: "NSE Code",
    key: "nse_code",
    dataIndex: "nse_code",
    width: 130,
    // ellipsis: true,
  },
  {
    title: "Market Cap",
    dataIndex: "market_cap",
    key: "market_cap",
    width: 130,
    sorter: (a, b) => a.market_cap - b.market_cap,
    render: (value) => {
      if (value > 20000) {
        return <div className="large">Large Cap</div>;
      } else if (value >= 5000 && value <= 20000) {
        return <div className="mid">Mid Cap</div>;
      } else if (value >= 500 && value <= 5000) {
        return <div className="small">Small Cap</div>;
      } else if (value < 500) {
        return <div className="micro">Micro Cap</div>;
      } else {
        return "Value out of range";
      }
    },
  },
];

function CreateFilters() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const formRef = useRef();
  const [filterParameter, setFilterParameter] = useState([]);
  const [stockDetails, setStockDetails] = useState([]);
  const [assignedToData, setAssignedToData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [toggleChecked, setToggleChecked] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [latestValues, setLatestValues] = useState({});
  const [tableDataSource, setTableDataSource] = useState([]);
  const [stockColumns, setStockColumns] = useState(columns1);
  const [clickComputeResult, setClickComputeResult] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [apiSector, setApiSector] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  const filterId = searchParams.get("id");
  let accessToken = Cookies.get("AccessToken");

  const { edit, view } = state || {};

  const data = [
    {
      name: "Large-cap",
      value: 10000,
      color: "#634CBD",
    },
    {
      name: "Small-cap",
      value: 1000,
      color: "#FFCA3A",
    },
    {
      name: "Mid-cap",
      value: 5000,
      color: "#90BE6D",
    },
    {
      name: "Micro-cap",
      value: 2000,
      color: "#E55934",
    },
  ];

  const noData = [
    {
      name: "No Data",
      value: 1,
      color: "#a4aaa9",
    },
  ];
  //   const filterid

  // Add filterData as a dependency so this effect runs only when filterData changes
  useEffect(() => {
    getFilterConfiguration();
    getSectorsData();
    getAssignedToDetails();
  }, []);

  const handleValueChange = (key, field, value) => {
    setLatestValues({
      ...latestValues,
      [key]: {
        ...latestValues[key],
        [field]: value,
      },
    });
  };

  const handleReset = () => {
    if (filterId) {
      getSingleFilterDetails();
    } else {
      getFilterConfiguration();
    }
    formRef.current.resetFields();
    setLatestValues({});

    setSelectedSector([]);

    setToggleChecked(false); // Reset the toggle switches
  };

  const initializeOriginalValues = (data) => {
    return data.map((item) => ({
      ...item,
      originalMinValue: item.min_value,
      originalMaxValue: item.max_value,
    }));
  };

  const columns = [
    {
      title: "Filters",
      key: "parameter",
      dataIndex: "label",
      // ellipsis: true,
      width: 300,
    },
    {
      title: "Absolute Value",
      key: "abs_val",
      dataIndex: "abs_val",
      render: (_, record) => (
        <Switch
          className="toggle"
          checked={record?.abs_val ? true : toggleChecked[record.key] || false} // Check if toggle is on for this row
          onChange={(checked) => handleToggleChange(record.key, checked)}
        />
      ),
      align: "center",
    },
    {
      title: "Minimum Value",
      key: "min_value",
      dataIndex: "min_value",
      render: (_, rec) => (
        <Form.Item
          className="input_box"
          name={`min_value_${rec.key}`}
          initialValue={rec.min_value} // Set initial value from the record
        >
          <InputNumber
            className="input_number"
            disabled={rec?.abs_val ? true : toggleChecked[rec.key] || false}
            readOnly={view ? true : false}
            placeholder={`${rec?.min_val}` || "min_val"}
            onChange={(value) => handleValueChange(rec.key, "min_value", value)}
          />
        </Form.Item>
      ),
      align: "center",
    },
    {
      title: "Maximum Value",
      key: "max_value",
      dataIndex: "max_value",
      render: (_, rec) => (
        <Form.Item
          className="input_box"
          name={`max_value_${rec.key}`}
          initialValue={rec.max_value} // Set initial value from the record
        >
          <InputNumber
            className="input_number"
            disabled={rec?.abs_val ? true : toggleChecked[rec.key] || false}
            readOnly={view ? true : false}
            placeholder={`${rec?.max_val}` || "max_val"}
            onChange={(value) => handleValueChange(rec.key, "max_value", value)}
          />
        </Form.Item>
      ),
      align: "center",
    },
    {
      title: "Equal To",
      key: "equal_to",
      dataIndex: "equal_to",
      render: (_, rec) => (
        <Form.Item
          className="input_box"
          name={`equal_to_${rec.key}`}
          initialValue={rec.equal_to} // Set initial value from the record
        >
          <InputNumber
            className="input_number"
            disabled={rec?.abs_val ? false : !toggleChecked[rec.key]}
            readOnly={view ? true : false}
            onChange={(value) => handleValueChange(rec.key, "equal_to", value)}
          />
        </Form.Item>
      ),
      align: "center",
    },
  ];

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;

    const headerHeight = 210; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins

    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  const handleToggleChange = (key, checked) => {
    setToggleChecked((prev) => ({
      ...prev,
      [key]: checked,
    }));
  };

  const handleSave = async (values) => {
    try {
      if (filterId) {
        const filterArrays = tableDataSource?.reduce((acc, current, idx) => {
          const currentChangedValue = latestValues[idx];

          const {
            isFetchedFilter,
            max_val: maxPlaceholder,
            min_val: minPlaceholder,
            min_value: minValue,
            max_value: maxValue,
            parameter,
          } = current;

          //
          if (!currentChangedValue && isFetchedFilter) {
            acc.push({
              min_val: minValue,
              max_val: maxValue,
              name: parameter,
            });
          } else if (currentChangedValue && isFetchedFilter) {
            const {
              min_value: currentlyChangedMinValue,
              max_value: currentlyChangedMaxValue,
            } = currentChangedValue;

            if (
              currentlyChangedMinValue !== null ||
              currentlyChangedMaxValue !== null
            ) {
              acc.push({
                min_val:
                  typeof currentlyChangedMinValue === "undefined"
                    ? minValue
                    : currentlyChangedMinValue ?? minPlaceholder,
                max_val:
                  typeof currentlyChangedMaxValue === "undefined"
                    ? maxValue
                    : currentlyChangedMaxValue ?? maxPlaceholder,
                name: parameter,
              });
            }
          } else if (currentChangedValue && !isFetchedFilter) {
            const {
              min_value: currentlyChangedMinValue,
              max_value: currentlyChangedMaxValue,
            } = currentChangedValue;

            if (
              typeof currentlyChangedMinValue === "number" ||
              typeof currentlyChangedMaxValue === "number"
            ) {
              acc.push({
                min_val: currentlyChangedMinValue ?? minPlaceholder,
                max_val: currentlyChangedMaxValue ?? maxPlaceholder,
                name: parameter,
              });
            }
          }

          return acc;
        }, []);

        const filterDataMap = filterData?.assigned_to?.reduce((acc, item) => {
          acc[item.portfolio] = item.name; // Assuming 'portfolio' is the label and 'name' is the id
          return acc;
        }, {});

        // Map through values.assigned_to and get the corresponding 'id' from filterData
        const updatedAssignedTo = values?.assigned_to?.map((name) => {
          return filterDataMap[name] ? { name: filterDataMap[name] } : { name }; // Map the name to id, if exists
        });

        const payload = {
          doc_id: filterId,
          data: {
            filter_name: values.filter_name,
            assigned_to: updatedAssignedTo,
            description: values.description,
            sector: values?.sector || undefined,
            filters_array: filterArrays,
          },
        };

        const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.update_filter`;
        const headers = {
          "X-Custom-Token": "frappe",
          Authorization: `Bearer ${accessToken}`,
        };
        const res = await axios.post(apiUrl, payload, { headers });
        message.success(res?.data?.message?.msg);

        navigate("/filter-management");
      } else {
        const filtersArray = Object.keys(latestValues).reduce(
          (acc, current, idx) => {
            const {
              min_value: currentlyChangedMinValue,
              max_value: currentlyChangedMaxValue,
            } = latestValues[current];

            const {
              max_val: maxPlaceholder,
              min_val: minPlaceholder,
              parameter,
            } = filterParameter[current];

            if (currentlyChangedMinValue || currentlyChangedMaxValue) {
              acc.push({
                min_val: currentlyChangedMinValue ?? minPlaceholder,
                max_val: currentlyChangedMaxValue ?? maxPlaceholder,
                name: parameter,
              });
            }

            return acc;
          },
          []
        );
        const payload = {
          data: {
            filter_name: values.filter_name,
            assigned_to: values.assigned_to?.map((name) => ({
              name: name,
            })),
            description: values.description,
            sector: values?.sector || undefined,
            filters_array: filtersArray,
          },
        };

        const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.create_filter`;
        const headers = {
          "X-Custom-Token": "frappe",
          Authorization: `Bearer ${accessToken}`,
        };
        const res = await axios.post(apiUrl, payload, { headers });
        message.success(res?.data?.message?.msg);
        navigate("/filter-management");
      }
    } catch (error) {
      message.error(error?.response?.data?.message?.msg);
    }
  };

  const getFilterConfiguration = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.fetch_filter_parameters`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        const dataWithKeys = response?.data?.message?.data?.map(
          (item, index) => ({
            // ...item,
            min_val: item?.min_value,
            max_val: item?.max_value,
            parameter: item?.parameter,
            label: item?.label,
            key: index, // Use index as unique key for each row
          })
        );

        setFilterParameter(dataWithKeys);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const addDynamicColumns = (data) => {
    // Get the keys from the response data that aren't already in the columns
    const existingKeys = stockColumns.map((col) => col.key);
    const additionalColumns = Object.keys(data[0] || {}) // Get keys from the first item in the data
      .filter(
        (key) =>
          !existingKeys.includes(key) &&
          key !== "name" &&
          key !== "rank" &&
          key !== "bse_code"
      ) // Only include keys not already in the columns
      .map((key) => ({
        title: key.replace(/_/g, " ").toUpperCase(), // Format the title
        key: key,
        dataIndex: key,
      }))
      .map((col) => {
        // Add sorter function specifically for the "sector" column
        if (col.key === "sector") {
          return {
            ...col,
            sorter: (a, b) => {
              if (a[col.dataIndex] < b[col.dataIndex]) return -1;
              if (a[col.dataIndex] > b[col.dataIndex]) return 1;
              return 0;
            },
          };
        }
        return col;
      });

    // Find the index of the "NSE Code" column
    const nseCodeIndex = stockColumns.findIndex(
      (col) => col.key === "market_cap"
    );

    // Insert the new columns after the "NSE Code" column
    const newColumns = [
      ...stockColumns.slice(0, nseCodeIndex + 1),
      ...additionalColumns,
      ...stockColumns.slice(nseCodeIndex + 1),
    ];

    // Update the columns state
    setStockColumns(newColumns);
  };

  const getStocksDetailswithFilter = async () => {
    setLoading(true);
    if (filterId) {
      if (
        Object.keys(latestValues).length === 0 &&
        selectedSector?.length === 0
      ) {
        const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.filter_stocks?filters={"doc_id":"${filterId}"}`;
        const headers = {
          "X-Custom-Token": "frappe",
          Authorization: `Bearer ${accessToken}`,
        };
        await axios
          .get(apiUrl, { headers })
          .then((response) => {
            setLoading(false);
            setClickComputeResult(true);
            addDynamicColumns(response?.data?.message?.data);
            setStockDetails(response?.data?.message?.data);
            message.success(
              `${response?.data?.message?.data.length} stocks found!`
            );
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error:", error);
            message.error(error?.response?.data?.message?.msg);
          });
      } else {
        const filterArrays = tableDataSource?.reduce((acc, current, idx) => {
          const currentChangedValue = latestValues[idx];

          const {
            isFetchedFilter,
            max_val: maxPlaceholder,
            min_val: minPlaceholder,
            min_value: minValue,
            max_value: maxValue,
            parameter,
          } = current;

          //
          if (!currentChangedValue && isFetchedFilter) {
            acc.push({
              min_val: minValue,
              max_val: maxValue,
              name: parameter,
            });
          } else if (currentChangedValue && isFetchedFilter) {
            const {
              min_value: currentlyChangedMinValue,
              max_value: currentlyChangedMaxValue,
            } = currentChangedValue;

            if (
              currentlyChangedMinValue !== null ||
              currentlyChangedMaxValue !== null
            ) {
              acc.push({
                min_val:
                  typeof currentlyChangedMinValue === "undefined"
                    ? minValue
                    : currentlyChangedMinValue ?? minPlaceholder,
                max_val:
                  typeof currentlyChangedMaxValue === "undefined"
                    ? maxValue
                    : currentlyChangedMaxValue ?? maxPlaceholder,
                name: parameter,
              });
            }
          } else if (currentChangedValue && !isFetchedFilter) {
            const {
              min_value: currentlyChangedMinValue,
              max_value: currentlyChangedMaxValue,
            } = currentChangedValue;

            if (
              typeof currentlyChangedMinValue === "number" ||
              typeof currentlyChangedMaxValue === "number"
            ) {
              acc.push({
                min_val: currentlyChangedMinValue ?? minPlaceholder,
                max_val: currentlyChangedMaxValue ?? maxPlaceholder,
                name: parameter,
              });
            }
          }

          return acc;
        }, []);

        try {
          const values = formRef.current.getFieldsValue();

          if (filterArrays?.length > 0) {
            const payload = {
              filters: filterArrays,
              sector: values?.sector,
            };

            const response = await axios.post(
              `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.filter_stocks_without_filter_id`,
              { data: payload },
              {
                headers: {
                  "X-Custom-Token": "frappe",
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            setLoading(false);
            setClickComputeResult(true);
            // columns1.push();

            addDynamicColumns(response?.data?.message?.data);
            setStockDetails(response?.data?.message?.data);
            message.success(
              `${response?.data?.message?.data.length} stocks found!`
            );
          } else {
            setLoading(false);
            message.error("Please enter min or max value");
          }
        } catch (err) {
          setLoading(false);
          message.error(err?.response?.data?.message?.msg);
        }
      }
    } else {
      const values = formRef.current.getFieldsValue();
      try {
        const filtersArray = Object.keys(latestValues).reduce(
          (acc, current) => {
            const {
              min_value: currentlyChangedMinValue,
              max_value: currentlyChangedMaxValue,
            } = latestValues[current];

            const {
              max_val: maxPlaceholder,
              min_val: minPlaceholder,
              parameter,
            } = filterParameter[current];

            if (currentlyChangedMinValue || currentlyChangedMaxValue) {
              acc.push({
                min_val: currentlyChangedMinValue ?? minPlaceholder,
                max_val: currentlyChangedMaxValue ?? maxPlaceholder,
                name: parameter,
              });
            }

            return acc;
          },
          []
        );

        if (filtersArray?.length > 0) {
          const payload = {
            filters: filtersArray,
            sector: selectedSector || values?.sector,
          };

          const response = await axios.post(
            `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.filter_stocks_without_filter_id`,
            { data: payload },
            {
              headers: {
                "X-Custom-Token": "frappe",
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          setLoading(false);
          setClickComputeResult(true);
          // columns1.push();

          addDynamicColumns(response?.data?.message?.data);
          setStockDetails(response?.data?.message?.data);
          message.success(
            `${response?.data?.message?.data.length} stocks found!`
          );
        } else {
          setLoading(false);
          message.error("Please enter min or max value");
        }
      } catch (err) {
        setLoading(false);
        message.error(err?.response?.data?.message?.msg);
      }
    }
  };

  const getSectorsData = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.stocks.api.fetch_sectors`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        const sectors = response?.data?.message?.data?.map((sector) => ({
          label: sector,
          value: sector,
        }));
        setSectorData(sectors || []);
        // setStockDetails(response?.data?.message?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getAssignedToDetails = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_all_portfolios`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        const assignedOptions = response?.data?.message?.data?.map(
          (portfolio) => ({
            label: `${portfolio?.portfolio} ${
              portfolio?.filter_obj?.filter_name
                ? `(Filter Assigned : ${portfolio?.filter_obj?.filter_name})`
                : ""
            }`,
            value: portfolio?.name,
          })
        );

        const filterNotAssignedData = assignedOptions?.filter((label) =>
          label?.label.includes("Not Assigned")
        );
        const filterAssignedData = assignedOptions?.filter(
          (label) => !label?.label.includes("Not Assigned")
        );

        setAssignedToData([...filterNotAssignedData, ...filterAssignedData]);
        // setStockDetails(response?.data?.message?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getSingleFilterDetails = async () => {
    // getFilterConfiguration();
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.fetch_filter?filters={"doc_id":"${filterId}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        const jsonString = response?.data?.message?.data?.filters_array;
        setFilterData({ ...response?.data?.message?.data });
        setApiSector(response?.data?.message?.data?.sector || []);

        // const validateString = jsonString.replace(/'/g, '"');

        // const parsedArray = JSON.parse(validateString);
        const formattedDataSource = jsonString.map((item, index) => {
          const min_value = item.min_val != null ? item.min_val : 0; // Handle null by setting to 0
          const max_value = item.max_val != null ? item.max_val : 0; // Handle null by setting to 0

          // If min_value and max_value are the same, show equal_to
          if (min_value === max_value) {
            return {
              key: index,
              label: item.name,
              equal_to: item.equal_to != null ? item.equal_to : min_value, // Default to min_value or equal_to

              min_value: null, // Hide min_value
              max_value: null, // Hide max_value
              abs_val: true, // Assuming this is a boolean or can be derived
            };
          } else {
            return {
              key: index,
              label: item.name,
              min_value: min_value, // Show min_value
              max_value: max_value, // Show max_value
              equal_to: null, // Hide equal_to
              abs_val: false, // Assuming this is a boolean or can be derived
            };
          }
        });

        const mergerArray = filterParameter?.map((param) => {
          const matchingFilter = formattedDataSource?.find(
            (filter) =>
              filter?.label.toLowerCase() === param?.label.toLowerCase() ||
              filter?.label.toLowerCase() === param?.parameter.toLowerCase()
          );

          if (matchingFilter) {
            return {
              ...param,
              // ...matchingFilter,
              min_value: matchingFilter?.min_value,
              max_value: matchingFilter?.max_value,
              isFetchedFilter: true,
            };
          }
          return param;
        });

        const initializedDataSource = initializeOriginalValues(mergerArray);

        setTableDataSource(initializedDataSource);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (filterId) {
      getSingleFilterDetails();
    }
  }, [filterParameter]);

  useEffect(() => {
    if (filterData && formRef.current) {
      // Set form fields dynamically when filterData is updated
      formRef.current.setFieldsValue({
        filter_name: filterData?.filter_name,
        description: filterData?.description,
        assigned_to: filterData?.assigned_to?.map((item) => item?.portfolio),
        sector: filterData?.sector,
      });
    }
  }, [filterData]);

  const selectSector = (e) => {
    setSelectedSector(e);
    formRef.current.setFieldsValue({ sector: e });
  };

  const tabItems = [
    {
      key: "1",
      label: "Total Stocks",
      //   children: 'Content of Tab Pane 1',
    },
    {
      key: "2",
      label: "Stock Distribution",
    },
  ];

  return (
    <>
      {/* <div className={styles.header}>
        <LeftOutlined onClick={() => navigate(-1)} />
        <FilterComponent />
        <span>
          Filter Management |
          {edit
            ? "Edit Filter Combination"
            : view
            ? "View Filter Combination"
            : "New Filter Combination"}
        </span>
      </div> */}

      <BreadCrumb
        data={[
          {
            name: "Filter Management",
            url: "/filter-management",
            active: false,
          },
          {
            name: edit
              ? "Edit Filter Combination"
              : view
              ? "View Filter Combination"
              : "New Filter Combination",

            url: "/create-filter-management",
            active: true,
          },
        ]}
      />
      <div className={styles.portfolio_div}>
        <Row>
          <Col span={24}>
            <p className={styles.portfolio_text}>
              {edit
                ? "Edit Filter Combination"
                : view
                ? "View Filter Combination"
                : "New Filter Combination"}
            </p>
            <div style={{ paddingTop: "10px" }}></div>

            <Form
              ref={formRef}
              layout="vertical"
              onFinish={handleSave}
              requiredMark={false}
              // initialValues={
              //   edit || view
              //     ? {
              //         filter_name: filterData?.filter_name,
              //         description: filterData?.description,
              //         assigned_to: filterData?.assigned_to?.map(
              //           (item) => item.name
              //         ),
              //       }
              //     : {}
              // }
            >
              <div className={styles.scrollableDiv}>
                <Card className="createFilterCard">
                  <Row gutter={20}>
                    <Col span={12} md={12} sm={22} xs={24}>
                      <Form.Item
                        label={
                          <p style={{ display: "flex", gap: "3px" }}>
                            Name
                            <span
                              style={{
                                color: "red",
                                fontSize: "16px",
                              }}
                            >
                              *
                            </span>
                          </p>
                        }
                        className="filter_input_label"
                        name="filter_name"
                        initialValue={filterData?.filter_name}
                        rules={[
                          {
                            required: true,
                            message: "Please enter filter name",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter filter combination name"
                          className="name_input"
                          readOnly={view ? true : false}
                          // initialValue={filterId ? filterData?.filter_name : ""}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Sector"
                        className="filter_input_label"
                        name="sector"
                      >
                        {view ? (
                          <Input readOnly className="name_input" />
                        ) : (
                          <Select
                            className="assign_select_input"
                            mode="multiple"
                            allowClear
                            // initialValue={
                            //   filterId
                            //     ? filterData?.filterData?.assigned_to?.map(
                            //         (item) => item.name
                            //       )
                            //     : ""
                            // }
                            onChange={selectSector}
                            placeholder="Select from the sectors"
                            options={sectorData}
                            // readOnly={view?true:false}
                            // disabled={view ? true : false}
                          />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={12} md={12} sm={22} xs={24}>
                      <Form.Item
                        label={
                          <p style={{ display: "flex", gap: "3px" }}>
                            Description
                            <span
                              style={{
                                color: "red",
                                fontSize: "16px",
                              }}
                            >
                              *
                            </span>
                          </p>
                        }
                        className="filter_input_label"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter description",
                          },
                        ]}
                      >
                        <Input
                          // rows={1}
                          placeholder="Enter decription"
                          className="name_input"
                          readOnly={view ? true : false}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Assign to Model Portfolio"
                        className="filter_input_label"
                        name="assigned_to"
                      >
                        {view ? (
                          <Input readOnly className="name_input" />
                        ) : (
                          <Select
                            className="assign_select_input"
                            mode="multiple"
                            allowClear
                            // initialValue={
                            //   filterId
                            //     ? filterData?.filterData?.assigned_to?.map(
                            //         (item) => item.name
                            //       )
                            //     : ""
                            // }
                            placeholder="Select model portfolio"
                            options={assignedToData}
                            // readOnly={view?true:false}
                            // disabled={view ? true : false}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <Card
                  className="filterConfigurationCard"
                  title="Filter Configuration"
                  extra={
                    <Space>
                      <Button className="reset_filter" onClick={handleReset}>
                        Reset Filter
                      </Button>
                      <Button
                        onClick={() => {
                          getStocksDetailswithFilter();
                        }}
                        // disabled={latestValues ? false : true}
                        className="compute_result"
                      >
                        Compute Results
                      </Button>
                    </Space>
                  }
                >
                  {loading ? (
                    <Spin
                      tip="Loading..."
                      size="large"
                      className="customSpinner"
                    >
                      <div style={{ minHeight: "200px" }}></div>
                    </Spin>
                  ) : (
                    <Table
                      virtual
                      columns={columns}
                      className="configTable"
                      dataSource={
                        edit || view ? tableDataSource : filterParameter
                        // filterParameter
                      }
                      pagination={false}
                      scroll={{
                        y: tableHeight,
                      }}
                    />
                  )}
                </Card>

                <div style={clickComputeResult ? {} : { display: "none" }}>
                  <h3 className={styles.computedresultsTitle}>
                    Computed Results
                  </h3>
                  <Divider style={{ borderBlockStart: "2px solid #ece7e7" }} />

                  <Card
                    className="computedResultsCard"
                    title="Total Stocks Matching Criteria:"
                  >
                    <Table
                      virtual
                      columns={stockColumns}
                      className="configTable"
                      dataSource={stockDetails}
                      pagination={false}
                      scroll={{
                        y: tableHeight,
                        x: 1300,
                      }}
                    />
                  </Card>
                </div>
              </div>
              <Space
                style={
                  view
                    ? { display: "none" }
                    : {
                        paddingTop: "15px",
                        float: "right",
                        paddingRight: "35px",
                      }
                }
              >
                <Button
                  className="cancel_button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>

                <Button className="save_button" htmlType="submit">
                  {edit ? "Update" : "Save"}
                </Button>
              </Space>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CreateFilters;
