import React, { useEffect, useState } from "react";
import styles from "./customer.module.scss";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { ReactComponent as CustomerContent } from "../../Image/assets/customerContent.svg";
import { ReactComponent as TotalCustomer } from "../../Image/assets/totalCustomer.svg";
import { ReactComponent as Asset } from "../../Image/assets/asset.svg";
import { ReactComponent as PendingSIP } from "../../Image/assets/pendingSip.svg";
import { ReactComponent as Rebalance } from "../../Image/assets/rebalance.svg";
import { ReactComponent as Adhoc } from "../../Image/assets/pendingAdhoc.svg";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Row,
  Table,
  Spin,
  Switch,
  Modal,
  Divider,
} from "antd";
import "./customer.css";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../customHooks/Debounce";
import { Breadcrumb } from "antd";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import * as XLSX from "xlsx";
import { dateFormat } from "../utils/auth";
import CONSTANTS from "../../constants/variables";

function Customers() {
  let accessToken = Cookies.get("AccessToken");
  const getCustApi = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.fetch_customers_data`;
  const [totalCustomerData, setTotalCustomerData] = useState({
    total_sip: "",
    total_aum: "",
    total_customers: "",
  });
  const headers = {
    "X-Custom-Token": "frappe",
    Authorization: `Bearer ${accessToken}`,
  };
  const [apiStockRes, setApiStockRes] = useState({
    stockData: [],
    loading: false,
  });

  const { stockData, loading } = apiStockRes;
  // const [loading, setLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const [pageSize, setPageSize] = useState(20); // Page size
  const [totalItems, setTotalItems] = useState(0); // Total number of items for pagination
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [exporting, setExporting] = useState(false);
  const [isActive, setIsActive] = useState("");
  const [customerId, setcustomerId] = useState("");

  // Debounced search term
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const getCustomerData = async (searchQuery = "") => {
    const apiUrl = `${getCustApi}?${
      searchQuery ? `filters={"name":"${searchQuery}"}&` : ""
    }`;
    setApiStockRes({ ...stockData, loading: true });
    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        if (!searchQuery) {
          setTotalItems(response?.data?.message?.data?.total_customers || 0);
          // setTotalCustomerData(response?.data?.message?.data);
          setApiStockRes({
            stockData: response?.data?.message?.data?.customers || [],
            loading: false,
          });
        } else {
          // setTotalCustomerData({
          //   ...totalCustomerData,
          //   total_customers: response?.data?.message?.data,
          // });
          setApiStockRes({
            stockData: response?.data?.message?.data || [],
            loading: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getTotalNumberOfCustomer = () => {
    axios
      .get(getCustApi, { headers })
      .then((response) => {
        setTotalCustomerData(response?.data?.message?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const showModel = (id, text) => {
    setIsActive(text);
    setcustomerId(id);
    setIsModalVisible(true);
  };

  const customerStatusUpdate = async (values) => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.post(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.update_customer_details`,
        {
          doc_id: customerId,
          data: {
            customer_status: isActive === "Active" ? "InActive" : "Active",
          },
        },
        {
          headers: headers,
        }
      );
      message.success(response?.data?.message?.msg);
      getCustomerData("");
      setIsModalVisible(false);
    } catch (err) {
      message.error(err?.response?.data?.message?.msg);
    }
  };

  function currencyFormat(num) {
    return num
      ? num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      : null;
  }

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 260; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins
    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    getCustomerData("");
    calculateTableHeight();
    getTotalNumberOfCustomer();
    window.addEventListener("resize", calculateTableHeight);

    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      getCustomerData(debouncedSearchTerm); // Reset to page 1 on search
    } else {
      getCustomerData(""); // Fetch all data when search is cleared
    }
  }, [debouncedSearchTerm]);

  const handleSearchBar = (e) => {
    setSearchTerm(e.target.value); // Update search term
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const statusColors = {
    Conservative: "#FEE7CC",
    Balanced: "#E3F6E5",
    Aggressive: "#FDEBEC",
    High: "#FDEBEC",
    "Moderately Balanced": "#E3F6E5",
    "Moderately Aggressive": "#FDEBEC",
    "Not Assigned": "#E2E2E2",
    Inactive: "#FDEBEC",
    Active: "#E3F6E5",
    Less: "#E3F6E5",
  };

  const statusTextColors = {
    Conservative: "#F2941E",
    Balanced: "#0FA958",
    Aggressive: "#E92F39",
    high: "#E92F39",
    "Moderately Balanced": "#0FA958",
    "Moderately Aggressive": "#E92F39",
    "Not Assigned": "#848484",
    Inactive: "#E92F39",
    Active: "#0FA958",
    Less: "#0FA958",
  };

  const columns1 = [
    {
      title: "Customer ID",
      key: "name",
      dataIndex: "name",
      fixed: "left",
      sorter: (a, b) => {
        const numA = parseInt(a.name.replace(/\D/g, ""), 10) || 0; // Handle NaN cases
        const numB = parseInt(b.name.replace(/\D/g, ""), 10) || 0;
        return numA - numB; // ascending order: if numA < numB, return negative
      },
      width: 150,
      render: (text) => {
        return (
          <a
            onClick={() => {
              navigate(`/customer-details?id=${text}`);
            }}
            className="customer-id"
          >
            {text}
          </a>
        );
      },
    },
    {
      title: "Customer Name",
      key: "customer_name",
      dataIndex: "customer_name",
      width: 300,
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      // ellipsis: true,
    },
    {
      title: "Status",
      key: "customer_status",
      dataIndex: "customer_status",
      width: 150,
      render: (text, record) => (
        <Switch
          className="status_toggle"
          checked={text === "Active"}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => {
            showModel(record?.name, text);
          }}

          // unCheckedChildren={text === "InActive" && text}
        />
      ),
      sorter: (a, b) => a.customer_status.localeCompare(b.customer_status),
    },
    {
      title: "Risk Profile",
      key: "risk_profile",
      dataIndex: "risk_profile",
      width: 150,
      filters: [
        { text: "Aggressive", value: "Aggressive" },
        { text: "Balanced", value: "Balanced" },
        { text: "Conservative", value: "Conservative" },
        { text: "Low", value: "less" },
        { text: "Moderately Aggressive", value: "Moderately Aggressive" },
        { text: "Moderately Balanced", value: "Moderately Balanced" },
        { text: "Not Assigned", value: "not assigned" },
      ],
      onFilter: (value, record) => record?.risk_profile === value,
      render: (text) => {
        return (
          <div
            style={{
              backgroundColor: statusColors[text] || "#F5F5F5",
              color: statusTextColors[text] || "#000000",
              padding: "3px",
              borderRadius: "12px",
              textAlign: "center",
            }}
          >
            {text}
          </div>
        );
      },
      // ellipsis: true,
    },
    {
      title: "Portfolio Value",
      key: "portfolio_val",
      dataIndex: "portfolio_val",
      sorter: (a, b) => a.portfolio_val - b.portfolio_val,
      width: 150,
      // ellipsis: true,
      render: (value) => `₹${value?.toLocaleString("en-IN")}`,
    },
    {
      title: "SIP Amount",
      key: "sip_amount",
      dataIndex: "sip_amount",
      width: 150,
      // ellipsis: true,
      sorter: (a, b) => a.sip_amount - b.sip_amount,
      render: (value) => `₹${value?.toLocaleString("en-IN")}`,
    },
    {
      title: "Adhoc Investment",
      key: "adhoc_amt",
      dataIndex: "adhoc_amt",
      width: 150,
      render: (value) => (value ? `₹${value?.toLocaleString("en-IN")}` : "₹0"),
    },
    {
      title: "Model Portfolio",
      key: "portfolio_type",
      dataIndex: "portfolio_type",
      width: 160,
      filters: [
        { text: "Balanced", value: "Balanced" },
        { text: "Conservative", value: "conservative" },
        { text: "Not Assigned", value: "Not assigned" },
      ],
      onFilter: (value, record) =>
        (record?.portfolio_type?.portfolio ?? "Not assigned") === value,
      render: (portfolio_type) => {
        const portfolio = portfolio_type?.portfolio
          ? portfolio_type?.portfolio
          : "Not assigned";

        return (
          portfolio.charAt(0).toUpperCase() + portfolio.slice(1).toLowerCase()
        );
      },
    },
    {
      title: "Thematic Investment",
      key: "_assign",
      dataIndex: "_assign",
      width: 160,
      filters: [
        { text: "EV Vehicles", value: "EV Vehicles" },
        { text: "Not Assigned", value: "Not assigned" },
      ],
      // onFilter: (value, record) =>
      //   (record._assign ?? "Not assigned") === value,
      render: (text) => (text ? text : "Not assigned"),
      // ellipsis: true,
    },

    {
      title: "Last Updated",
      key: "modified",
      dataIndex: "modified",
      width: 120,
      render: (text) => {
        const date = new Date(text);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      },
      // ellipsis: true,
    },
  ];
  const downloadCSV = () => {
    setExporting(true);
    axios
      .get(getCustApi, { headers })
      .then((res) => {
        const resData = res?.data?.message?.data?.customers || [];
        const columnsToExport = columns1.map((col) => col.title); // Extract column titles

        const rowsToExport = resData.map((row) => ({
          "Customer ID": row.name,
          "Customer Name": row.customer_name,
          "Risk Profile": row.risk_profile,
          "Portfolio Value": `₹${row.portfolio_val?.toLocaleString("en-IN")}`,
          "SIP Amount": `₹${row.sip_amount?.toLocaleString("en-IN")}`,
          "Adhoc Investment": row.adhoc_investment || "-",
          "Model Portfolio": row.portfolio_type?.portfolio || "Not assigned",
          "Thematic Investment": row._assign || "Not assigned",
          "Last Updated": dateFormat(row.modified),
        }));

        const worksheet = XLSX.utils.json_to_sheet(rowsToExport, {
          header: columnsToExport,
        });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "CustomerList.csv");
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message ||
          err.message ||
          "Something went wrong...";
        message.error(errorMessage);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  return (
    <>
      <BreadCrumb
        data={[
          {
            name: "Customer Management",
            url: "/customers",
            active: true,
          },
        ]}
      />
      <div className={styles.customer_div}>
        <Row gutter={[16, 16]}>
          <Col md={12} sm={22} xs={24} span={12}>
            <p className={styles.portfolio_text}>Customer Management</p>
          </Col>
          <Col span={6} md={6} sm={22} xs={24}></Col>
          <Col span={3} md={3} sm={22} xs={24}>
            <Button className="import_button">Import</Button>
          </Col>
          <Col span={3} md={3} sm={22} xs={24}>
            <Button
              className="export_button"
              onClick={downloadCSV}
              disabled={exporting}
              loading={exporting}
            >
              Export
            </Button>
          </Col>
        </Row>
        <Card className="totalCustomerCard">
          <Row>
            <Col md={4} sm={22} xs={24} style={{ paddingRight: "30px" }}>
              <div className={styles.customer_tab}>
                <TotalCustomer className="customer_logo" />
                <span className={styles.customers_icon}>
                  {totalCustomerData?.total_customers
                    ? totalCustomerData?.total_customers
                    : 0}
                  <p
                    className={styles.customer_text}
                    // style={{ marginLeft: "17px" }}
                  >
                    Total Customer
                  </p>
                </span>
              </div>
            </Col>
            <Col md={6} sm={22} xs={24} style={{ paddingRight: "30px" }}>
              <div className={styles.customer_tab}>
                <Asset className="customer_logo" />
                <span className={styles.customers_icon}>
                  {totalCustomerData?.total_aum
                    ? `₹${currencyFormat(totalCustomerData?.total_aum)}`
                    : 0}
                  <p
                    className={styles.customer_text}
                    // style={{ marginLeft: "26px" }}
                  >
                    Total AUM
                  </p>
                </span>
              </div>
            </Col>
            <Col md={5} sm={22} xs={24} style={{ paddingRight: "30px" }}>
              <div className={styles.customer_tab}>
                <PendingSIP className="customer_logo" />
                <span className={styles.customers_icon}>
                  {totalCustomerData?.sip_approvals
                    ? totalCustomerData?.sip_approvals
                    : 0}
                  <p
                    className={styles.customer_text}
                    // style={{ marginLeft: "17px" }}
                  >
                    SIP Approvals
                  </p>
                </span>
              </div>
            </Col>
            <Col md={5} sm={22} xs={24} style={{ paddingRight: "30px" }}>
              <div className={styles.customer_tab}>
                <Rebalance className="customer_logo" />
                <span className={styles.customers_icon}>
                  {totalCustomerData?.sip_approval
                    ? totalCustomerData?.sip_approval
                    : 0}
                  <p
                    className={styles.customer_text}
                    // style={{ marginLeft: "17px" }}
                  >
                    Rebalance Approvals
                  </p>
                </span>
              </div>
            </Col>
            <Col md={4} sm={22} xs={24}>
              <div className={styles.customer_tab}>
                <Adhoc className="customer_logo" />
                <span className={styles.customers_icon}>
                  {totalCustomerData?.adhoc_approvals
                    ? totalCustomerData?.adhoc_approvals
                    : 0}
                  <p
                    className={styles.customer_text}
                    // style={{ marginLeft: "17px" }}
                  >
                    Adhoc Approvals
                  </p>
                </span>
              </div>
            </Col>
          </Row>
        </Card>

        <Card
          className="customerDataTable"
          title="Customer List"
          style={{ marginTop: "15px" }}
          extra={
            <Input
              prefix={<SearchOutlined style={{ color: "#A49F9F" }} />}
              placeholder="Search by customer ID, name"
              className="customer_search_input"
              onChange={handleSearchBar}
            />
          }
        >
          {loading ? (
            <Spin tip="Loading..." size="large" className="customSpinner">
              <div style={{ minHeight: "200px" }}></div>
            </Spin>
          ) : (
            <Table
              virtual
              columns={columns1}
              className="table"
              dataSource={stockData}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                // total: totalItems,
                onChange: (page, pageSize) =>
                  handleTableChange({ current: page, pageSize }),
              }}
              scroll={{
                y: tableHeight,
                x: 1300,
              }}
              // scroll={{
              //   x: 'max-content', // This will allow the table to scroll horizontally based on content size
              // }}
            />
          )}
        </Card>
      </div>
      <Modal
        open={isModalVisible}
        title={
          isActive === "Active"
            ? "Deactivate the customer ?"
            : "Activate the customer?"
        }
        okText="Confirm"
        cancelText="Cancel"
        onOk={customerStatusUpdate}
        onCancel={() => setIsModalVisible(false)}
        className="confirm_model"
        width={650}
      >
        <Divider style={{ margin: "14px 0px" }} />
        {isActive === "Active" ? (
          <p className={styles.deactivate_msg}>
            This action will disable their account, and they will no longer have
            access to our services. You can reactivate the account at any time.
          </p>
        ) : (
          <p className={styles.deactivate_msg}>
            This action will restore their account, granting them full access to
            our services. You can deactivate the account at any time.
          </p>
        )}
      </Modal>
    </>
  );
}

export default Customers;
