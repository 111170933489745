import React, { useEffect, useState } from "react";
import styles from "./approval.module.scss";
// import ApprovalImage from "../Image/approvalImage.png";
import winSightLogo from "../Image/winsight.png";
import "../App.css";
import CONSTANTS from "../constants/variables";
import { Button, Card, Col, Row, Space, Table, Empty, message } from "antd";
import { ReactComponent as ApprovalImage } from "../Image/assets/Financial analytics Illustration 1.svg";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Winsight } from "../Image/assets/winsight.svg";

const Approval = () => {
  const [stockList, setStockList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [status, setStatus] = useState(0);
  const [expiryTime, setExpiryTime] = useState();
  const [isApprovalEnabled, setIsApprovalEnabled] = useState(false);
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const adhoc_id = params.get("id");
  const navigate = useNavigate();
  const location = useLocation();

  localStorage.setItem("adhoc_id", adhoc_id);
  localStorage.setItem("status", status);

  useEffect(() => {
    if (!adhoc_id) {
      navigate("/");
    }
  }, [location, navigate]);

  const handleApprove = () => {
    if (!isApprovalEnabled) {
      message.warning(
        "Approval is only allowed on weekdays between 9:15 AM and 3:15 PM."
      );
      return;
    }

    if (expiryTime > 24) {
      message.warning(
        "Approval request has expired. Approvals must be completed within 24 hours."
      );
    } else {
      setStatus(1);
      const newTab = window.open(
        "https://smartapi.angelbroking.com/publisher-login?api_key=5otN9bGe",
        "_blank"
      );
      if (newTab) {
        window.close();
      } else {
        window.location.href =
          "https://smartapi.angelbroking.com/publisher-login?api_key=5otN9bGe";
      }
    }
  };
  const handleCancel = () => {
    setStatus(0);
    navigate("/thankyou");
  };

  useEffect(() => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.adhoc_and_sip_transactions.api.fetch_stocks_for_approval_page?filters={"doc_id": "${adhoc_id}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      // Authorization: `Bearer ${accessToken}`,
    };

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setStockList(response?.data?.message?.data || []);
        const givenDateString = response?.data?.message?.creation;
        const givenDate = new Date(givenDateString);

        const currentDate = new Date();

        const diffInMs = currentDate - givenDate;

        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        setExpiryTime(diffInHours);
        setTotalAmount(response?.data?.message?.req_amt);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const currentHour = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();

    const isWeekday = currentDay >= 1 && currentDay <= 5;
    const isWithinTime =
      (currentHour === 9 && currentMinutes >= 15) ||
      (currentHour > 9 && currentHour < 17) ||
      (currentHour === 15 && currentMinutes <= 15);

    setIsApprovalEnabled(isWeekday && isWithinTime);
  }, []);

  return (
    <main className={styles.login_main}>
      {/* <div md={10} sm={22} xs={24}> */}

      <div className={styles.login_form}>
        {/* <img
          className={styles.login_logo}
          alt=""
          src={winSightLogo}
          width={169}
          height={36}
        /> */}
        <div className={styles.winsight_div}>
          <Winsight className={styles.winsight_logo} />
          <span
            style={{ color: "#076F56", fontSize: "20px", fontWeight: "500" }}
          >
            Winwize
          </span>
        </div>
        <p className={styles.request_amt}>Request Amount</p>
        <span className={styles.amount}>₹{totalAmount.toFixed(2)}</span>
        <div style={{ marginTop: "1rem" }}>
          <p className={styles.stock_paragraph}>Stock Details :</p>
          <div className={styles.approve_stock_rows}>
            <Row gutter={[16, 16]}>
              {stockList.length > 0 ? (
                stockList.map((stock) => (
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    // lg={6}
                    // xl={4}
                  >
                    <Card className={styles.stock_card}>
                      <p>{stock.stock_name}</p>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <Empty />
                </Col>
              )}
            </Row>
          </div>
          {/* <Space
            style={{ position: "absolute", right: "2rem", bottom: "2px",width:"41.5%"}}
            className={styles.space_div}
          > */}

          {/* </Space> */}
        </div>
      </div>

      <div className={styles.backgroundImage_div}>
        <div className={styles.background_div}>
          {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
          <ApprovalImage />
          {/* </div> */}

          <h2 className={styles.Approve_text}>Approve Sign-In Request</h2>
          <p className={styles.Approve_paragraph}>
            To continue, please approve this sign-in request. You will be
            redirected to the login page.
          </p>
        </div>
        {/* <img
          src={ApprovalImage}
          // width={555}
          // height={555}
          alt="approval"
          style={{ marginLeft: "1rem" }}
          className={styles.background_img}
        /> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "91%",
          }}
        >
          <Button
            style={{ width: "46%" }}
            className="cancel_btn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "46%" }}
            className="approve_btn"
            onClick={handleApprove}
            // disabled={!isApprovalEnabled}
          >
            Approve
          </Button>
        </div>
      </div>
      {/* </Col> */}
      {/* <Col md={14} sm={22} xs={24}> */}
      {/* <section className={styles.login_form_container}>
        <article className={styles.login_form_main}> */}

      {/* </article>
      </section> */}
      {/* </Col> */}
    </main>
  );
};

export default Approval;
