import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { ReactComponent as PortfolioContent } from "../../Image/assets/portfolioContent.svg";
import { ReactComponent as Customers } from "../../Image/assets/customerContent.svg";
import { ReactComponent as StockListing } from "../../Image/assets/stockListingGreen.svg";
import { ReactComponent as Filters } from "../../Image/assets/filtergreen.svg";
import { ReactComponent as RequestManager } from "../../Image/assets/request mangr.svg";
import { ReactComponent as DocumentManager } from "../../Image/assets/Breadcrumb Doc Mangt.svg";
import { useNavigate } from "react-router-dom";
import styles from "./BreadCrumb.module.scss";

function BreadCrumb({ data }) {
  const navigate = useNavigate();
  const dataLen = data.length;

  const renderPortfolioContent = (contentType) => {
    switch (contentType) {
      case "Customer Management":
        return <Customers />;
      case "Stock Listing":
        return <StockListing />;
      case "Portfolio Management":
        return <PortfolioContent />;
      case "Filter Management":
        return <Filters />;
      case "Request Manager ":
        return <RequestManager />;
      case "Document Management":
        return <DocumentManager />
      default:
        return <PortfolioContent />;
    }
  };

  return (
    <div className={styles.header}>
      <LeftOutlined
        onClick={() => {
          if (data[0].url) navigate(data[0].url);
          else if (data[0].callBack) data[0].callBack();
        }}
      />
      {renderPortfolioContent(data[0]?.name)}
      {data?.map((page, ind) => (
        <span key={ind}>
          <span
            style={page.active ? {} : { opacity: ".7" }}
            onClick={() => {
              if (page.callBack) {
                page.callBack();
              } else if (page.url) navigate(page.url);
            }}
          >
            {page.name}
          </span>
          {ind + 1 !== dataLen && <span> |</span>}
        </span>
      ))}
    </div>
  );
}

export default BreadCrumb;
