import React, { useEffect } from "react";
import { Button } from "antd";
import styles from "./thankyou.module.scss";
import ThumbImage from "../Image/Thumb.png";
import winSight from "../Image/assets/logo_transparent.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../constants/variables";

function Thankyou() {
  const adhoc_id = localStorage.getItem("adhoc_id");
  const status = localStorage.getItem("status");
  const navigate = useNavigate();

  function getQueryParams(url) {
    let queryParams = {};
    let queryString = url.split("?")[1];
    if (queryString) {
      queryString = queryString.split("&");
      queryString.forEach((param) => {
        let [key, value] = param.split("=");
        queryParams[key] = decodeURIComponent(value);
      });
    }
    return queryParams;
  }

  const queryParams = getQueryParams(window.location.search);
  const postData = async () => {
    try {
      const url = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.adhoc_and_sip_transactions.api.execute_transactions`;
      const headers = {
        "X-Custom-Token": "frappe",
      };
      const params = {
        data:
          status === "1"
            ? {
                auth_token: queryParams?.auth_token,
                refresh_token: queryParams?.refresh_token,
                id: adhoc_id,
                status: parseFloat(status),
              }
            : {
                id: adhoc_id,
                status: parseFloat(status),
              },
      };

      const response = await axios.post(url, params, { headers });
      localStorage.removeItem("adhoc_id");
      navigate("/thankyou");
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  useEffect(() => {
    postData();
  }, []);

  return (
    <main className={styles.login_main}>
      {/* <div md={10} sm={22} xs={24}> */}
      <div className={styles.backgroundImage_div}>
        <img src={ThumbImage} alt="approval" />
      </div>
      {/* </Col> */}
      {/* <Col md={14} sm={22} xs={24}> */}
      <section className={styles.login_form_container}>
        <article className={styles.login_form_main}>
          <div className={styles.login_form}>
            <img
              className={styles.login_logo}
              alt=""
              src={winSight}
              width={500}
              height={150}
              style={{ marginLeft: "2rem", marginTop: "2rem" }}
            />
            <div className={styles.middle_div}>
              <h1 className={styles.welcome_text}>Thank You!</h1>
              <p className={styles.login_caption}>
                Your login was successful. You can now access your account.
              </p>
              {/* <Button className={styles.approve_button}>
                Proceed to Dashboard
              </Button> */}
            </div>
            {/* <p className={styles.bottom_text}>
              You will be redirected to the login page to enter your
              credentials.
            </p> */}
          </div>
        </article>
        <footer className={styles.login_footer}>
          <div className={styles.footer_text}>
            <p>Privacy Policy</p>
            <p>Terms of Service</p>
          </div>
        </footer>
      </section>
      {/* </Col> */}
    </main>
  );
}

export default Thankyou;
