import React from "react";

function FooterBtn({ onSave, onCancel, submit = false, name }) {
  return (
    <div className="footer_btn">
      <button onClick={onCancel} className="cancel_btn">
        Cancel
      </button>
      <button
        type={submit ? "submit" : "button"}
        onClick={onSave}
        className="save_btn"
      >
        {name ? "Update" : "Save"}
      </button>
    </div>
  );
}

export default FooterBtn;
