import React, { useState } from "react";
import styles from "./forgotPassword.module.scss";
import Logo from "./img/Vector.png";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CONSTANTS from "../../constants/variables";
import { Form, Input, Spin, message, Tooltip } from "antd";
import axios from "axios";
import { Color } from "antd/es/color-picker";

const contentStyle = {
  padding: 50,
  borderRadius: 4,
  color: "green",
};

const content = <div style={contentStyle} />;

function ForgotPassword({ setCurrentPage }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoaing] = useState(false);

  const handleOnFinish = async () => {
    if (!email) {
      // Simple client-side validation for email
      setError(true);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(true);
      return;
    }
    // else {
    //   setError(false);
    // }
    setError(false);

    setLoaing(true);
    try {
      const headers = {
        "X-Custom-Token": "frappe",
      };

      const response = await axios.post(
        `${CONSTANTS.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.login.api.forgot_password`,
        {
          email: email,
        },

        {
          headers: headers,
        }
      );

      localStorage.setItem("email", email);
      setError(false);
      setCurrentPage("verifyEmail");
    } catch (err) {
      setError(true);
    } finally {
      setLoaing(false);
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Simple email format validation
  };

  return (
    <div className={styles.main_div}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.left_content}>
            <div className={styles.logo}>
              <img src={Logo} />
              <p>Winsight</p>
            </div>
            <p className={styles.left_paragraph}>
              Manage Portfolios, Optimise Investments, & Drive Performance
            </p>
          </div>

          <div className={styles.small}></div>
          <div className={styles.big}></div>
        </div>

        <div className={styles.right}>
          <h1>Reset Password</h1>
          <p>
            Enter your registration email to receive a OTP to reset your
            password.
          </p>

          {loading ? (
            <div className={styles.loading}>
              <Spin
                tip="Sending OTP..."
                size="large"
                style={{ color: "#1a8c4df5" }}
                className={styles.spinner}
              >
                {content}
              </Spin>
            </div>
          ) : (
            <Form
              onFinish={handleOnFinish}
              className={styles.form}
              validateStatus={error ? "error" : ""}
              help={
                error &&
                "The email address that you've entered doesn't match any account."
              }
            >
              <Form.Item
                className={styles.formItem}
                validateStatus={error ? "error" : ""}
                help={
                  error &&
                  "The email address that you've entered doesn't match any account."
                }
              >
                {/* <Tooltip
                  title={error ? "The email address that you've entered doesn't match any account." : ""}
                  visible={error}
                  placement="bottom"
                  className={styles.toolpit}
                > */}
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                  prefix={
                    error && (
                      <ExclamationCircleOutlined
                        style={{
                          color: "red",
                          height: "15px",
                          width: "15px",
                          marginRight: "5px",
                        }}
                      />
                    )
                  }
                  style={{ borderColor: error ? "red" : "" }}
                  className={styles.input_btn}
                />
                {/* </Tooltip>                */}
              </Form.Item>

              <Form.Item className={styles.formItem}>
                <button
                  className={`${styles.login_btn} green_button`}
                  type="submit"
                >
                  CONTINUE
                </button>
              </Form.Item>
            </Form>
          )}

          {}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
