import React, { useEffect, useState } from "react";
import styles from "./filterManagement.module.scss";
import { ReactComponent as FilterComponent } from "../../Image/assets/filterCombine.svg";
import { ReactComponent as EyeIcon } from "../../Image/assets/eyeicon.svg";
import { ReactComponent as EditIcon } from "../../Image/assets/editicon.svg";
import { ReactComponent as DeleteIcon } from "../../Image/assets/deleteicon.svg";
import {
  Button,
  Col,
  Input,
  Row,
  Table,
  Divider,
  Card,
  message,
  Spin,
  Popconfirm,
} from "antd";
import { EditOutlined, LeftOutlined, SearchOutlined } from "@ant-design/icons";
import "./filterManagement.css";
import { render } from "@testing-library/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import CONSTANTS from "../../constants/variables";

function FilterManagement() {
  const navigate = useNavigate();
  const [filterList, setFilterList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);

  // const [view, setView] = useState(false)
  // const [stockDetails, setStockDetails] = useState([]);
  // const [filterId, setFilterID] = useState("")

  // localStorage.setItem("edit", edit)

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 130; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins
    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  const columns = [
    {
      title: "SN",
      key: "key",
      render: (_, record) => record?.key + 1,
      width: 50,
    },
    {
      title: "Filter Name",
      key: "filter_name",
      dataIndex: "filter_name",
      width: 150,
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Assigned to Model Portfolio",
      key: "assigned_to",
      dataIndex: "assigned_to",
      render: (_, record) => (
        <div>
          {record?.assigned_to?.map((rec) => rec?.portfolio).join(",  ")}
        </div>
      ),
    },
    {
      title: "Last Updated",
      key: "last_updated",
      dataIndex: "last_updated",
      width: 120,
      // render: (_, record) => new Date(record?.modified).toLocaleDateString("DE"),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      width: 120,
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: "14px" }}>
          <EditIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/create-filter-management?id=${record?.name}`, {
                state: { edit: true },
              });
            }}
          />
          <EyeIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/create-filter-management?id=${record?.name}`, {
                state: { view: true },
              });
            }}
          />
          <Popconfirm
            title="Delete Filter"
            description="Are you sure to delete this filter?"
            onConfirm={() => {
              handleDelete(record?.name);
            }}
            onCancel={() => {
              message.error("Click on No");
            }}
            placement="left"
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              style: {
                backgroundColor: "#00a971",
                borderColor: "red",
                boxShadow: "none",
                border: "none",
              },
            }}
            cancelButtonProps={{
              style: {
                // backgroundColor: "#d9d9d9",
                borderColor: "#d9d9d9",
                color: "#000",
              },
            }}
          >
            <DeleteIcon
              style={{ cursor: "pointer" }}
              // onClick={() => handleDelete(record?.name)}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleDelete = async (id) => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.delete_filter?filters={"doc_id":"${id}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    setLoading(true);
    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        message.success(response?.data?.message?.msg);
        getFilterData();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  let accessToken = Cookies.get("AccessToken");

  const getFilterData = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.fetch_all_filters`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    setLoading(true);
    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        const dataWithKeys = response?.data?.message?.data?.map(
          (item, index) => ({
            ...item,
            key: index, // Use index as unique key for each row
          })
        );

        setFilterList(dataWithKeys);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const filteredData = filterList.filter((filter) =>
    filter.filter_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // const getStocksDetailswithFilter = async () => {
  //   const apiUrl = `https://trade.winrich.in/api/method/stock_portfolio_management.portfolio_master.doctype.filters.api.filter_stocks?filters={"doc_id":"${filterId}"}`;
  //   const headers = {
  //     "X-Custom-Token": "frappe",
  //     Authorization: `Bearer ${accessToken}`,
  //   };
  //   await axios
  //     .get(apiUrl, { headers })
  //     .then((response) => {
  //       setStockDetails(response?.data?.message?.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };
  return (
    <>
      {/* <div className={styles.header}>
        <LeftOutlined />
        <FilterComponent />
        <span> Filter Management</span>
      </div> */}
      <BreadCrumb
        data={[
          {
            name: "Filter Management",
            url: "/filter-management",
            active: true,
          },
        ]}
      />
      <div className={styles.portfolio_div}>
        <Row gutter={16} className="filterManagement-header">
          <Col span={14} md={14} sm={22} xs={24}>
            <p className={styles.portfolio_text}>Filter Combinations</p>
          </Col>
          <Col span={7} md={7} sm={22} xs={24}>
            <Input
              prefix={<SearchOutlined style={{ color: "#A49F9F" }} />}
              placeholder="Search by filter"
              className={styles.search_input}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Col>
          <Col span={3} md={3} sm={22} xs={24}>
            <Button
              className="portfolio_button"
              // type="primary"
              onClick={() => navigate("/create-filter-management")}
            >
              Create New
            </Button>
          </Col>
        </Row>
        <div style={{ marginTop: "1rem", paddingRight: "20px" }}>
          {loading ? (
            <Spin tip="Loading..." size="large" className="customSpinner">
              <div style={{ minHeight: "300px" }}></div>
            </Spin>
          ) : (
            <Card className="filterCard">
              <Table
                virtual
                columns={columns}
                dataSource={filteredData}
                className="filterTable"
                pagination={false}
                scroll={{ y: tableHeight }}
              />
            </Card>
          )}
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default FilterManagement;
