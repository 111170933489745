import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FilterComponent } from "../../Image/assets/filterCombine.svg";
import { LeftOutlined } from "@ant-design/icons";
import styles from "./requestManager.module.scss";
import "./request.css";
import Cookies from "js-cookie";
import {
  Form,
  Col,
  Row,
  Input,
  Card,
  Radio,
  Select,
  InputNumber,
  Button,
  Divider,
  Table,
  message,
  Modal,
  Spin,
  Tabs,
} from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter, dateFormat, formatNumber } from "../utils/auth";
import FooterBtn from "../Footerbtn/FooterBtn";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import CONSTANTS from "../../constants/variables";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const { TextArea } = Input;
const { Option } = Select;

function NewRequest({ activeTab, newReqData, setNewReq }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [portfolioId, setPortfolioId] = useState();
  const [loading, setLoading] = useState(false);
  const [recommendStock, setRecommendStock] = useState([]);
  const [showTab, setShowTab] = useState("1");
  const [marketDistribution, setMarketDistribution] = useState([]);
  const [sectorDistribution, setSectorDistribution] = useState([]);
  const [showRecommendedStocks, setShowRecommendedStocks] = useState(false);
  const { view } = newReqData || {};
  const { id: req_id, stocks: viewStock, status } = view || {};

  const [isModalVisible, setIsModalVisible] = useState(false);

  const data = [
    {
      name: "Large-cap",
      value: 10000,
      color: "#634CBD",
    },
    {
      name: "Small-cap",
      value: 1000,
      color: "#FFCA3A",
    },
    {
      name: "Mid-cap",
      value: 5000,
      color: "#90BE6D",
    },
    {
      name: "Micro-cap",
      value: 2000,
      color: "#E55934",
    },
  ];

  useEffect(() => {
    if (view) {
      form.setFieldsValue({
        customerName: view?.customer_name || undefined,
        rationDescription: view?.description || undefined,
        transactionType: view?.action || "buy",
        investInto: view?.portfolio || "existing",
        amount: view?.amount || undefined,
        noOfStocks: view?.no_of_stocks || undefined,
      });
      // });
    }
  }, []);

  let accessToken = Cookies.get("AccessToken");

  //   Fteching Customer Data

  const getCustomerData = async () => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.fetch_customers_data?filters={"status": "Active"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    await axios
      .get(apiUrl, { headers })
      .then((response) => {
        const customerData = response?.data?.message?.data?.filter(
          (amt) => amt?.sip_amount > 0
        );
        if (activeTab === "2") {
          setCustomerData(customerData);
        } else {
          setCustomerData(response?.data?.message?.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const recommendStocks = async (values) => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.post(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.adhoc_and_sip_transactions.api.recommend_stocks`,
        {
          data: {
            customer_id: values.customerName,
            customer_name: customerData.find(
              (c) => c.name === values.customerName
            )?.customer_name,
            trans_type: values.transactionType,
            // portfolio: values?.portfolio,
            description: values.rationDescription,
            portfolio: values.investInto,
            amount: values.amount,
            no_of_stocks: values.noOfStocks,
          },
        },
        {
          headers: headers,
        }
      );
      const resData = response?.data?.message?.data;
      if (resData.length > 0) {
        setRecommendStock(response?.data?.message?.data);
        const marketCapData = resData?.map((stock) => {
          if (stock?.market_cap > 20000) {
            return {
              ...stock,
              market_caps: "Large Cap",
            };
          } else if (stock?.market_cap >= 5000 && stock?.market_cap <= 20000) {
            return {
              ...stock,
              market_caps: "Mid Cap",
            };
          } else if (stock?.market_cap >= 500 && stock?.market_cap <= 5000) {
            return {
              ...stock,
              market_caps: "Small Cap",
            };
          } else if (stock?.market_cap < 500) {
            return {
              ...stock,
              market_caps: "Micro Cap",
            };
          }
        });

        const marketCapCount = marketCapData?.reduce((acc, stock) => {
          const capType = stock.market_caps;
          const existingEntry = acc.find((item) => item.name === capType);

          if (existingEntry) {
            // If category exists, increment its value
            existingEntry.value += 1;
          } else {
            // Otherwise, create a new entry
            acc.push({
              name: capType,
              value: 1,
              color:
                capType === "Large Cap"
                  ? "#634CBD"
                  : capType === "Small Cap"
                  ? "#FFCA3A"
                  : capType === "Mid Cap"
                  ? "#90BE6D"
                  : capType === "Micro Cap"
                  ? "#E55934"
                  : "",
            });
          }
          return acc;
        }, []);

        const marketCapPercentage = marketCapCount.map((item) => ({
          ...item,
          value: parseFloat(
            ((item.value / marketCapData?.length) * 100).toFixed(2)
          ), // Calculate percentage and keep 2 decimal places
        }));
        setMarketDistribution(marketCapPercentage || []);

        const generateRandomColor = () => {
          const letters = "0123456789ABCDEF";
          let color = "#";
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        };

        // Object to store unique colors for each sector
        const sectorColors = {};

        const sectorTypeCount = marketCapData.reduce((acc, stock) => {
          const sector = stock.sector;
          const existingEntry = acc.find((item) => item.name === sector);

          if (existingEntry) {
            // If sector exists, increment its value
            existingEntry.value += 1;
          } else {
            // Assign a color to the sector if it doesn't already have one
            if (!sectorColors[sector]) {
              sectorColors[sector] = generateRandomColor();
            }

            // Create a new entry for the sector
            acc.push({
              name: sector,
              value: 1,
              color: sectorColors[sector],
            });
          }

          return acc;
        }, []);

        const sectorPercentage = sectorTypeCount.map((item) => ({
          ...item,
          value: parseFloat(
            ((item.value / marketCapData?.length) * 100).toFixed(2)
          ), // Calculate percentage and keep 2 decimal places
        }));
        setSectorDistribution(sectorPercentage || []);
        setShowRecommendedStocks(true);
        message.success("Recommended stocks fetched successfully");
      } else {
        setShowRecommendedStocks(false);
        message.error("No Data");
      }
    } catch (err) {
      message.error(
        err?.response?.data?.message?.message ||
          err?.response?.data?.message?.msg
      );
    }
  };

  const fetchStockData = async (value) => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      if (value === "existing") {
        const response = await axios.get(
          `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.fetch_customer_stocks?filters={"doc_id": "${customerId}"}`,
          {
            headers: headers,
          }
        );
        const stocks = response?.data?.message?.data;
        if (stocks?.length === 0) {
          message.error("No Stocks Available");
        } else {
          setStockData(stocks);
        }
      } else if (value === "model") {
        const response = await axios.get(
          `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_portfolio_based_stocks_data?filters={"doc_id": "${portfolioId}", "customer_id": "${customerId}"}`,
          {
            headers: headers,
          }
        );
        const stocksDetails = response?.data?.message?.data
          ?.flatMap((cap_stock) => Object.values(cap_stock))
          .flat();

        setStockData(stocksDetails);
      }
    } catch (err) {
      message.error(
        err?.response?.data?.message?.msg ||
          err?.response?.data?.message?.message
      );
    }
  };

  const updateStocksData = (id, newData) => {
    setLoading(true);
    const stockIndex = recommendStock.findIndex((stock) => stock?.name === id);
    if (stockIndex === -1) {
      console.error(`Stock with id ${id} not found.`);
      return;
    }

    const selectedStock = stockData?.find(
      (stock) => stock?.stock_name === newData?.stock_name
    );

    let updatedStocks = [...recommendStock];
    const currentPrice = selectedStock?.current_price;

    updatedStocks = [...recommendStock];

    updatedStocks[stockIndex] = {
      ...updatedStocks[stockIndex],
      ...newData,
      current_price: selectedStock?.current_price,
      sector: selectedStock?.sector,
      bse_code: selectedStock?.bse_code,
      name: selectedStock?.name,
      nse_code: selectedStock?.nse_code,
      industry: selectedStock?.industry,
      market_cap: selectedStock?.market_capitalization,
      amount: currentPrice * updatedStocks[stockIndex]?.qty,
      token_no: selectedStock?.token_no,
    };
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setRecommendStock(updatedStocks);
  };

  const tabItems = [
    {
      key: "1",
      label: "Total Stocks",
      //   children: 'Content of Tab Pane 1',
    },
    {
      key: "2",
      label: "Stock Distribution",
    },
  ];

  const changeQty = (id, newQty) => {
    const stockIndex = recommendStock.findIndex((stock) => stock?.name === id);

    const updatedStocks = [...recommendStock];
    updatedStocks[stockIndex].qty = newQty || 0;

    updatedStocks[stockIndex].amount =
      updatedStocks[stockIndex]?.qty * updatedStocks[stockIndex]?.current_price;
    setRecommendStock(updatedStocks);
  };

  const columns = [
    {
      title: "Stock Name",
      dataIndex: "stock_name",
      key: "stock_name",
      render: (_, record) => (
        <Select
          value={record.stock_name}
          style={{ width: 300 }}
          className="recommend-table-selector"
          onChange={(e) => {
            updateStocksData(record.name, { stock_name: e });
          }}
          disabled={view}
        >
          {stockData?.map((stocks) => (
            <Option value={stocks.stock_name}>{stocks.stock_name}</Option>
          ))}
          {/* Add more options here if needed */}
        </Select>
      ),
    },
    {
      title: "Market Cap",
      dataIndex: "market_cap",
      key: "market_cap",
      render: (value) => {
        if (value > 20000) {
          return <Input value={"Large cap"} />;
        } else if (value >= 5000 && value <= 20000) {
          return <Input value={"Mid cap"} />;
        } else if (value >= 500 && value <= 5000) {
          return <Input value={"Small cap"} />;
        } else if (value < 500) {
          return <Input value={"Micro cap"} />;
        } else {
          return "Value out of range";
        }
      },
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
    },
    {
      title: "Current Price",
      dataIndex: "current_price",
      key: "current_price",
      render: (_, record) => (
        <Input
          value={record?.current_price}
          className="recommend-table-input"
          readOnly
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      render: (_, record) => (
        <Input
          value={record?.qty || 0}
          className="recommend-table-input"
          onChange={(e) => {
            const newQty = parseInt(e.target.value);

            changeQty(record.name, newQty);
          }}
          // readOnly={view}
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => (
        <Input
          value={parseFloat(record?.amount)?.toFixed(2) || 0}
          className="recommend-table-input"
          readOnly={view}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!view) {
      getCustomerData();
      // fetchStockData();
    }
  }, []);
  const saveRequest = async () => {
    try {
      const formValues = await form.validateFields(); // Get form data
      const stocks = view ? viewStock.stocks : recommendStock; // Get stock data
      const payload = {
        data: {
          customer: formValues.customerName,
          customer_name: customerData.find(
            (c) => c.name === formValues.customerName
          )?.customer_name,
          trans_type: formValues.transactionType,
          description: formValues.rationDescription,
          portfolio: formValues.investInto,
          amount: formValues.amount,
          no_of_stocks: formValues.noOfStocks,
          req_type: activeTab === "2" ? "sip" : "adhoc",

          stocks: stocks.map((stock) => ({
            name: stock.name,
            stock_name: stock?.stock_name,
            bse_code: stock?.bse_code,
            nse_code: stock?.nse_code,
            industry: stock?.industry,
            current_price: stock?.current_price,
            qty: stock?.qty,
            amount: stock?.amount,
            sector: stock?.sector,
            market_cap: stock?.market_cap,
          })),
        },
      };

      const headers = {
        "X-Custom-Token": "frappe",
        Authorization: `Bearer ${accessToken}`,
      };

      const response = await axios.post(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.adhoc_and_sip_transactions.api.create_transaction`,
        payload,
        { headers }
      );
      message.success(
        activeTab === "2"
          ? "SIP Request created successfully"
          : "Adhoc Request created successfully"
      );
      setNewReq({
        view: null,
        show: false,
      });
    } catch (error) {
      message.error(
        error?.response?.data?.message?.msg ||
          error?.response?.data?.message?.message
      );
    }
  };

  const cancelRequest = () => {
    setNewReq({
      view: null,

      show: false,
    });
  };

  const showTotalAmt = view
    ? viewStock?.reduce((acc, stock) => acc + stock?.amount, 0)
    : recommendStock?.reduce(
        (accumulator, stock) => accumulator + stock.amount,
        0
      );

  const statusColors = {
    approved: "#E3F6E5", // light green for Approved
    failed: "#FDEBEC", // light red for Failed
    executed: "#E3F6E5", // light teal for Executed
    closed: "#E2E2E2", // light gray for Closed
    open: "#FFF4D9", // light yellow for Open
    "approval sent": "#D6F0FF", // light blue for Approval Sent
  };

  const statusTextColors = {
    approved: "#0FA958", // dark green for Approved
    failed: "#E92F39", // dark red for Failed
    executed: "#0FA958", // dark teal for Executed
    closed: "#848484", // dark gray for Closed
    open: "#F49C05", // dark yellow for Open
    "approval sent": "#1A97DF", // dark blue for Approval Sent
  };

  //   Mark As Closed

  const markAsClose = async () => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.adhoc_and_sip_transactions.api.update_adhoc_req_status?filters={"doc_id": "${req_id}"}`,
        {
          headers: headers,
        }
      );
      if (response?.data?.message?.status === "success") {
        message.success(response?.data?.message?.msg);
        setNewReq({
          view: { ...view, status: "closed" },
          show: newReqData?.show,
        });
      } else {
        message.error(
          response?.data?.message?.error ||
            "SomeThing Went Wrong , we can't close the request"
        );
      }
      setIsModalVisible(false);
    } catch (err) {
      message.error(
        err?.response?.data?.message?.msg ||
          "SomeThing Went Wrong , we can't close the request"
      );
      setIsModalVisible(false);
    }
  };

  const markAsClosed = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      {/* <div className={styles.header}>
        <LeftOutlined
          onClick={() => {
            navigate(-1);
          }}
        />
        <FilterComponent />
        <span>
          {view
            ? "Request Manager | Adhoc Request | View Adhoc Request"
            : "Request Manager | Adhoc Request | Initiate Adhoc Transaction"}
        </span>
      </div> */}

      <BreadCrumb
        data={[
          {
            name: "Request Manager ",
            url: "",
            active: false,
            callBack: () => {
              setNewReq({
                view: null,

                show: false,
              });
            },
          },
          {
            name: activeTab === "2" ? "SIP Request" : "Adhoc Request",
            url: "",
            active: false,
            callBack: () => {
              setNewReq({
                view: null,
                show: false,
              });
            },
          },
          {
            name: view
              ? activeTab === "2"
                ? "view SIP Request:"
                : "View Adhoc Request"
              : activeTab === "2"
              ? "Initiate SIP Request"
              : "Initiate Adhoc Transaction",
            url: "",
            active: true,
            callBack: () => {
              setNewReq({
                view: null,
                show: false,
              });
            },
          },
        ]}
      />

      <div className={styles.main_div}>
        {view ? (
          ""
        ) : (
          <Row>
            <Col span={8} md={8} sm={22} xs={24}>
              <h1 className={styles.request_text}>
                {activeTab === "2"
                  ? "Initiate SIP Request"
                  : "Initiate Adhoc Request"}
              </h1>
            </Col>
          </Row>
        )}

        <div className={view ? "recomment_stock_view" : "recommed_stocks"}>
          <Card className="newRequest">
            {view ? (
              <>
                <div className={styles.newReq_head}>
                  <div className={styles.newReq_leftHead}>
                    <h3>
                      {activeTab === "2"
                        ? `SIP Request ID: ${req_id}`
                        : `Adhoc Request ID: ${req_id}`}
                    </h3>
                    {/* <span
                      style={{
                        backgroundColor:
                          statusColors[viewStock?.status] || "#F5F5F5",
                        color: statusTextColors[viewStock?.status] || "#000000",
                        padding: "5px 6px",
                        borderRadius: "12px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    > */}
                    <span
                      style={{
                        backgroundColor: statusColors[status] || "#F5F5F5",
                        color: statusTextColors[status] || "#000000",
                        padding: "5px 6px",
                        borderRadius: "12px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      {capitalizeFirstLetter(view?.status)}
                    </span>
                  </div>
                  <Button
                    onClick={markAsClosed}
                    danger
                    disabled={
                      view?.status === "closed" ||
                      view?.status === "executed" ||
                      view?.status === "failed"
                    }
                  >
                    Mark As Closed
                  </Button>
                </div>
                {/* <p className={styles.newReq_date}>
                  Request Date:
                  {dateFormat(
                    transaction ? transaction[0].request_time : new Date()
                  )}
                </p> */}
              </>
            ) : (
              ""
            )}
            <Form
              form={form}
              layout="vertical"
              onFinish={recommendStocks}
              requiredMark={false}
              // initialValues={{
              //     customerName: view ? transaction?.customer_name : undefined,
              //     rationDescription: view ? transaction?.description : undefined,
              //     transactionType: view ? transaction?.action : "buy",
              //     investInto: view ? transaction?.portfolio : "existing",
              //     amount: view ? transaction?.amount : undefined,
              //     noOfStocks: view ? transaction?.no_of_stocks : undefined,
              //   }}
            >
              <Row gutter={16}>
                {/* Customer Name */}
                <Col span={12} md={12} sm={22} xs={24}>
                  <Form.Item
                    label={
                      <p style={{ display: "flex", gap: "3px" }}>
                        Customer Name
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                          }}
                        >
                          *
                        </span>
                      </p>
                    }
                    // required
                    name="customerName"
                    className="requestLabel"
                    rules={[
                      {
                        required: true,
                        message: "Please select customer name",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select Customer"
                      className="request-select"
                      //   disabled={isdisabled}
                      onChange={(e) => {
                        const portfolioId = customerData?.find(
                          (data) => data?.name === e
                        );

                        setPortfolioId(portfolioId?.portfolio_type?.name);
                        setCustomerId(e);
                        form.setFieldsValue({
                          portfolio:
                            portfolioId?.portfolio_type === "Not Assigned"
                              ? "Not Assigned"
                              : portfolioId?.portfolio_type?.portfolio,
                        });
                        if (activeTab === "2") {
                          form.setFieldsValue({
                            amount: portfolioId?.sip_amount,
                          });
                        }
                      }}
                      style={{ width: "100%" }}
                      disabled={view}
                    >
                      {customerData?.map((customer) => (
                        <Select.Option
                          key={customer.name}
                          value={customer.name}
                        >
                          {customer.customer_name} ({customer.name})
                        </Select.Option>
                      ))}
                    </Select>
                    {/* <Select
                      
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                    >
                     
                    </Select> */}
                  </Form.Item>
                </Col>
                <Col span={12} md={12} sm={22} xs={24}>
                  <Form.Item
                    label={
                      <p style={{ display: "flex", gap: "3px" }}>
                        Customer Model Portfolio
                      </p>
                    }
                    // required
                    name="portfolio"
                    className="requestLabel"
                  >
                    <Input disabled placeholder="Portfolio name" />
                    {/* <Select
                      
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      }
                    >
                     
                    </Select> */}
                  </Form.Item>
                </Col>

                {/* Ration Description */}
                <Col span={12} md={12} sm={22} xs={24}>
                  <Form.Item
                    label={
                      <p style={{ display: "flex", gap: "3px" }}>
                        Rational Description
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                          }}
                        >
                          *
                        </span>
                      </p>
                    }
                    name="rationDescription"
                    className="requestLabel"
                    rules={[
                      {
                        required: true,
                        message: "Please enter description",
                      },
                    ]}
                  >
                    <TextArea
                      className="rationDescription"
                      placeholder="Word counter with max limit 100"
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      maxLength={100}
                      readOnly={view}
                    />
                  </Form.Item>
                </Col>
                <Col span={6} md={6} sm={22} xs={24}>
                  <Form.Item
                    label={
                      <p style={{ display: "flex", gap: "3px" }}>
                        Transaction Type
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                          }}
                        >
                          *
                        </span>
                      </p>
                    }
                    name="transactionType"
                    className="requestLabel"
                    rules={[
                      {
                        required: true,
                        message: "Please select transaction type",
                      },
                    ]}
                  >
                    <Radio.Group className="transactionType" disabled={view}>
                      <Radio value="buy">Buy</Radio>
                      <Radio value="sell">Sell</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                {/* Invest Into */}
                <Col span={6} md={6} sm={22} xs={24}>
                  <Form.Item
                    label={
                      <p style={{ display: "flex", gap: "3px" }}>
                        Invest Into
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                          }}
                        >
                          *
                        </span>
                      </p>
                    }
                    name="investInto"
                    className="requestLabel"
                    rules={[
                      {
                        required: true,
                        message: "Please select invest into",
                      },
                    ]}
                  >
                    <Radio.Group
                      className="transactionType"
                      disabled={view}
                      onChange={(e) => {
                        // setInvestInto(e?.target?.value);
                        fetchStockData(e?.target?.value);
                      }}
                    >
                      <Radio value="existing">Existing Portfolio</Radio>
                      <Radio value="model">Model Portfolio</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                {/* Amount */}
                <Col span={12} md={12} sm={22} xs={24}>
                  <Form.Item
                    label={
                      <p style={{ display: "flex", gap: "3px" }}>
                        Amount
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                          }}
                        >
                          *
                        </span>
                      </p>
                    }
                    name="amount"
                    className="requestLabel"
                    rules={[
                      {
                        required: true,
                        message: "Please enter amount",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Enter"
                      style={{ width: "100%" }}
                      min={1}
                      // value={sipAmount}
                      readOnly={view}
                    />
                  </Form.Item>
                </Col>

                {/* No of Stocks */}
                <Col span={12} md={12} sm={22} xs={24}>
                  <Form.Item
                    label={
                      <p style={{ display: "flex", gap: "3px" }}>
                        No of Stocks
                        <span
                          style={{
                            color: "red",
                            fontSize: "16px",
                          }}
                        >
                          *
                        </span>
                      </p>
                    }
                    name="noOfStocks"
                    className="requestLabel"
                    rules={[
                      {
                        required: true,
                        message: "Please enter no of stocks",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Enter"
                      style={{ width: "100%" }}
                      min={1}
                      readOnly={view}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{ textAlign: "right", paddingTop: "23px" }}
                >
                  {!view && (
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="request-btn"
                      >
                        Recommend Stock
                      </Button>
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Form>
          </Card>
          {/* <div> */}

          {showRecommendedStocks || view ? (
            <>
              <h3 className={styles.recommendedTitle}>Recommended Stocks</h3>
              <Divider style={{ borderBlockStart: "2px solid #ece7e7" }} />
              <Card
                style={{ width: "100%", marginTop: 16 }}
                className="recommed-stock-card"
              >
                <Tabs
                  items={tabItems}
                  className="tab-col"
                  activeKey={showTab}
                  onChange={(key) => setShowTab(key)}
                />
                {showTab === "1" &&
                  (loading ? (
                    <Spin
                      tip="Loading..."
                      size="large"
                      className="customSpinner"
                    >
                      <div style={{ minHeight: "200px" }}></div>
                    </Spin>
                  ) : (
                    <Table
                      className="recommed-stock-table"
                      dataSource={view ? viewStock : recommendStock}
                      columns={columns}
                      pagination={false}
                      summary={() => (
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {showTotalAmt ? (
                              <strong style={{ color: "#077255" }}>
                                Total: ₹{formatNumber(showTotalAmt, 0)}
                              </strong>
                            ) : null}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    />
                  ))}

                {showTab === "2" && (
                  <div style={{ marginLeft: "22px" }}>
                    <Row gutter={80}>
                      <Col md={12} sm={22} xs={24}>
                        <Card
                          className="computedResultsCard"
                          style={{ padding: "10px" }}
                        >
                          <p className={styles.marketCapHeader}>
                            Market Capital Distribution
                          </p>
                          <ResponsiveContainer width="100%" height={230}>
                            <PieChart width={800} height={600}>
                              <Pie
                                data={marketDistribution}
                                cx="50%"
                                cy="50%"
                                innerRadius={30}
                                outerRadius={80}
                                fill="#8884d8"
                                // Adjust this for gaps between segments
                                dataKey="value"
                                labelLine={false}
                              >
                                {marketDistribution.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                  />
                                ))}
                              </Pie>
                              <Tooltip
                                formatter={(value) => `${value}`}
                                contentStyle={{
                                  background: "#fff",
                                  border: "1px solid #EEEEEE",
                                  padding: "10px",
                                  margin: "0px",
                                  borderRadius: "5px",
                                  boxShadow:
                                    "0px 1px 2px 0px rgba(24, 24, 60, 1)",
                                }}
                              />
                              <Legend
                                layout="vertical"
                                align="right"
                                verticalAlign="middle"
                                content={({ payload }) => (
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "10px",
                                    }}
                                  >
                                    {payload.map((entry, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          marginRight: 20,
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: 15,
                                            height: 15,
                                            borderRadius: "50%",
                                            backgroundColor: entry.color,
                                          }}
                                        />
                                        <span
                                          style={{
                                            color: "#202020",
                                            fontSize: "12px",
                                            lineHeight: "21px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {entry.value}
                                        </span>
                                        {/* <div
                                              style={{
                                                width: 15,
                                                height: 15,
                                                borderRadius: "50%",
                                                backgroundColor: entry.color,
                                              }}
                                            /> */}
                                        {/* {renderColorfulLegendText(entry.value, entry)} */}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              />
                            </PieChart>
                          </ResponsiveContainer>
                        </Card>
                      </Col>
                      <Col md={12} sm={22} xs={24}>
                        <Card
                          className="computedResultsCard"
                          style={{ padding: "10px" }}
                        >
                          <p className={styles.marketCapHeader}>
                            Industry wise Distribution
                          </p>
                          <ResponsiveContainer width="100%" height={230}>
                            <PieChart width={800} height={600}>
                              <Pie
                                data={sectorDistribution}
                                cx="50%"
                                cy="50%"
                                innerRadius={30}
                                outerRadius={80}
                                fill="#8884d8"
                                // Adjust this for gaps between segments
                                dataKey="value"
                                labelLine={false}
                              >
                                {sectorDistribution.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                  />
                                ))}
                              </Pie>
                              <Tooltip
                                formatter={(value) => `${value}`}
                                contentStyle={{
                                  background: "#fff",
                                  border: "1px solid #EEEEEE",
                                  padding: "10px",
                                  margin: "0px",
                                  borderRadius: "5px",
                                  boxShadow:
                                    "0px 1px 2px 0px rgba(24, 24, 60, 1)",
                                }}
                              />
                              <Legend
                                layout="vertical"
                                align="right"
                                verticalAlign="middle"
                                content={({ payload }) => (
                                  <ul
                                    style={{
                                      listStyleType: "none",
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "10px",
                                    }}
                                  >
                                    {payload.map((entry, index) => (
                                      <li
                                        key={index}
                                        style={{
                                          marginRight: 20,
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: 15,
                                            height: 15,
                                            borderRadius: "50%",
                                            backgroundColor: entry.color,
                                          }}
                                        />
                                        <span
                                          style={{
                                            color: "#202020",
                                            fontSize: "12px",
                                            lineHeight: "21px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {entry.value}
                                        </span>
                                        {/* <div
                                              style={{
                                                width: 15,
                                                height: 15,
                                                borderRadius: "50%",
                                                backgroundColor: entry.color,
                                              }}
                                            /> */}
                                        {/* {renderColorfulLegendText(entry.value, entry)} */}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              />
                            </PieChart>
                          </ResponsiveContainer>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}
              </Card>
            </>
          ) : null}

          {/* </div> */}
        </div>
      </div>
      <div
        className={styles.footer_btn}
        // style={view ? { bottom: "0.2rem" } : {}}
      >
        {view ? (
          <div className="footer_btn">
            <Button
              onClick={() => {
                setNewReq({
                  view: null,

                  show: false,
                });
              }}
              style={{
                border: "1px solid #00A971",
                color: "#00A971",
                width: "7.5rem",
                fontWeight: "500",
              }}
            >
              Back
            </Button>
          </div>
        ) : (
          <FooterBtn onSave={saveRequest} onCancel={cancelRequest} />
        )}
      </div>

      <Modal
        open={isModalVisible}
        title={"Mark as Closed"}
        okText="Confirm"
        cancelText="Cancel"
        onOk={markAsClose}
        onCancel={() => setIsModalVisible(false)}
        className="confirm_model"
        width={650}
      >
        <Divider style={{ margin: "14px 0px" }} />

        <p className={styles.deactivate_msg}>
          Are you sure you want to mark this issue as closed? Once closed, no
          further actions can be taken on this request
        </p>
      </Modal>
    </>
  );
}

export default NewRequest;
