import React, { useState, useEffect } from "react";
import styles from "./portfolio.module.scss";
import "./addPortfolioModal.css";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Row,
  Skeleton,
  Spin,
  Popconfirm
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ReactComponent as NoDataImage } from "../../Image/assets/Nodata.svg";
import { ReactComponent as PortfolioContent } from "../../Image/assets/portfolioContent.svg";
import AddPortfolioModal from "./addPortfolioModal";
import axios from "axios";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PortfolioAssignModal from "./portfolioAssignModal";
import ModelPortfolioDetails from "./portfolioViewDetails";
import { isTokenExpired, refreshAccessToken, logoutUser } from "../utils/auth";
import Cookies from "js-cookie";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { DeleteOutlined } from "@ant-design/icons";
import CONSTANTS from "../../constants/variables";

function PortfolioList() {
  const [openPortfolioModal, setOpenPortfolioModal] = useState(false);
  const [openPortfolioAssignModal, setOpenPortfolioAssignModal] =
    useState(false);
  const [openViewDetails, setOpenViewDetails] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const [apiResData, setApiResData] = useState({
    portfolioList: [],
    loading: false,
  });
  const [edit, setEdit] = useState(false);
  const { portfolioList, loading } = apiResData;
  const [portfolioid, setPortfolioId] = useState("");
  const [portfoliotitle, setPortfolioTitle] = useState("");
  const [portfoliofilter, setPortfoliofilter] = useState("");

  // const [title , setTitle] = useState('')
  const navigate = useNavigate();
  const accessToken = Cookies.get("AccessToken");
  const handleClick = () => {
    setOpenPortfolioModal(true);
  };

  const openAssignModal = (id, title) => {
    setOpenPortfolioAssignModal(true);
    setPortfolioId(id);
    setPortfolioTitle(title);
  };

  const viewDetails = (id, title, filter) => {
    setPortfoliofilter(filter);
    navigate("/model-portfolio-details", {
      state: {
        portfolioid: id,
        portfoliotitle: title,
        portfoliofilter: filter,
      },
    });
  };

  const fetchPortfolioData = (id) => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_portfolio?filters={"doc_id":"${
      id || portfolioid
    }"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setPortfolioData(response?.data?.message?.data);
        // setEditData(response?.data?.message);
       
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const editHandleClick = (id) => {
    if (id) {
      setEdit(true);
      setOpenPortfolioModal(true);
      setPortfolioId(id);
      fetchPortfolioData(id);
    }
  };

  // if(!openPortfolioModal) {
  //   setApiResData(updatedData)
  // }

  const fetchportfolio = () => {
    setApiResData({ ...portfolioList, loading: true });
    const apiUrl =
      `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.fetch_all_portfolios`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setApiResData({
          portfolioList: response?.data?.message?.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    fetchportfolio();
  }, [openPortfolioModal]);

  const deletePortfolio = async (id) => {
    const apiUrl = `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.portfolio_type.api.delete_portfolio?filters={"doc_id":"${id}"}`;
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.delete(apiUrl, { headers });
      message.success(response?.data?.message?.msg);
      fetchportfolio();
    } catch (err) {
      // console.log("Error")
      message.error(err?.response?.data?.message?.msg);
    }
  };

  console.log("portfolioid", portfolioid);

  return (
    <>
      <BreadCrumb
        data={[
          {
            name: "Portfolio Management",
            url: "/portfolio-management",
            active: true,
          },
        ]}
      />
      {/* <div className={styles.header}>
        <LeftOutlined />
        <PortfolioContent />
        <span> Portfolio Management</span>
      </div> */}
      <div className={styles.portfolio_div}>
        <Row
          gutter={[16, 16]}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "12px",
          }}
        >
          <Col md={12} sm={22} xs={24} span={12}>
            <p className={styles.portfolio_text}>Model Portfolio List</p>
            {/* <Input
          placeholder="Search for filters"
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          className={styles.searchbar}
        /> */}
          </Col>
          {/* <div className={styles.portfolioList_div}> */}

          {!loading && (
            <>
              <Col md={8} sm={22} xs={24} span={8}>
                <Input
                  prefix={<SearchOutlined style={{ color: "#A49F9F" }} />}
                  placeholder="Search for filters"
                  className={styles.search_input}
                />
              </Col>
              <Col md={4} sm={22} xs={24} span={4}>
                <Button
                  className={styles.portfolio_button}
                  onClick={handleClick}
                >
                  Add New Portfolio
                </Button>
              </Col>
            </>
          )}
        </Row>
        {/* </div> */}
        {loading && (
          <Spin className="customSpinner" size="large" tip="Loading...">
            <div
              style={{
                minHeight: "400px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          </Spin>
        )}

        {!loading && portfolioList?.length > 0 ? (
          <Row gutter={[20, 20]}>
            {portfolioList?.map((portfolio) => (
              <Col md={12} sm={22} xs={24} span={12}>
                <Card
                  className="portfolio-card"
                  title={
                    <div>
                      <h5
                        style={{
                          margin: "0px",
                          color: "#2E2E2E",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {portfolio?.portfolio}
                      </h5>

                      <p
                        style={{
                          margin: "0px",
                          color: "#0FA958",
                          fontSize: "10px",
                          lineHeight: "12.13px",
                          fontWeight: "500",
                        }}
                      >
                        Filter:{" "}
                        {portfolio?.filter_obj?.filter_name
                          ? portfolio?.filter_obj?.filter_name
                          : "Not assigned"}
                      </p>
                    </div>
                  }
                  extra={
                    <div style={{ display: "flex", gap: "4px" }}>
                      <Button
                        className="extraCardItems"
                        onClick={() => editHandleClick(portfolio?.name)}
                      >
                        Edit
                      </Button>
                      <Button
                        className="extraCardItems"
                        onClick={() =>
                          openAssignModal(portfolio?.name, portfolio?.portfolio)
                        }
                      >
                        Assign
                      </Button>
                     
                      <Popconfirm
                      
                        title="Delete the Portfolio"
                        description="Are you sure to delete this portfolio?"
                        onConfirm={() => deletePortfolio(portfolio?.name)}
                        onCancel={() => {
                          message.error('Click on No');
                        }}
                        
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ style: { backgroundColor: '#00a971', borderColor: 'red', boxShadow:"none", border:"none" } }}  // Change color here

                      >
                       <Button
                        className="extraCardItems"
                        style={{ width: "30px", padding: "0" }}
                        // onClick={}
                      >
                        <DeleteOutlined />
                      </Button>
                      </Popconfirm>
                    </div>
                  }
                >
                  <Row>
                    <Col span={8} className={styles.table_label}>
                      Category
                    </Col>
                    <Col span={8} className={styles.table_label}>
                      Weightage
                    </Col>
                    <Col span={8} className={styles.table_label}>
                      No of Stocks
                    </Col>
                    <Divider />
                    {portfolio?.categories?.map((cat) => (
                      <>
                        <Col span={8} className={styles.table_data}>
                          {cat?.category}
                        </Col>
                        <Col span={8} className={styles.table_data}>
                          {cat?.weightage}%
                        </Col>
                        <Col span={8} className={styles.table_data}>
                          {cat?.no_of_stocks}
                        </Col>
                        <Divider />
                      </>
                    ))}
                    <Col span={20}></Col>
                    {/* <Col
                        className={styles.view_text}
                        span={4}
                        onClick={() => viewDetails(portfolio?.name, portfolio?.portfolio)}
                      >
                        View details
                      </Col> */}
                  </Row>
                  <div
                    onClick={() =>
                      viewDetails(
                        portfolio?.name,
                        portfolio?.portfolio,
                        portfolio?.filter_obj?.filter_name
                      )
                    }
                    className={styles.view_text}
                  >
                    View details
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          !loading && (
            <Card className={styles.empty_card}>
              <NoDataImage />
              <p>No data has been added.</p>
            </Card>
          )
        )}

        { console.log("portfolio data", portfolioData)}

        {openPortfolioModal && (
          <AddPortfolioModal
            edit={edit}
            setEdit={setEdit}
            data={portfolioData}
            openPortfolioModal={openPortfolioModal}
            setOpenPortfolioModal={setOpenPortfolioModal}
          />
        )}
        {openPortfolioAssignModal && (
          <PortfolioAssignModal
            openPortfolioAssignModal={openPortfolioAssignModal}
            setOpenPortfolioAssignModal={setOpenPortfolioAssignModal}
            // setApiResData={setApiResData}
            portfolioid={portfolioid}
            portfoliotitle={portfoliotitle}
          />
        )}

        {openViewDetails && (
          <ModelPortfolioDetails
            portfolioid={portfolioid}
            portfoliotitle={portfoliotitle}
            portfoliofilter={portfoliofilter}
          />
        )}
      </div>
    </>
  );
}

export default PortfolioList;
