import React, { useEffect, useState } from "react";
import "./customer.css";
import { Row, Col, Card, Table, Input, Spin } from "antd";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import axios from "axios";
import { render } from "@testing-library/react";
import { dateFormat, formatTime } from "../utils/auth";
import CONSTANTS from "../../constants/variables";

function TransactionHistory({ id, name }) {
  let accessToken = Cookies.get("AccessToken");
  const [transactions, setTransaction] = useState({
    transactionData: [],
    loading: false,
  });
  const { transactionData, loading } = transactions;

  const [tableHeight, setTableHeight] = useState(0);

  const calculateTableHeight = () => {
    const windowHeight = window.innerHeight;
    const headerHeight = 200; // Adjust based on your actual header height
    const remainingHeight = windowHeight - headerHeight - 100; // Additional padding/margins
    setTableHeight(remainingHeight);
  };

  useEffect(() => {
    calculateTableHeight();
    window.addEventListener("resize", calculateTableHeight);

    return () => {
      window.removeEventListener("resize", calculateTableHeight);
    };
  }, []);

  const fetchTransactions = async () => {
    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    setTransaction({ ...transactionData, loading: true });

    try {
      const response = await axios.get(
        `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.adhoc_and_sip_transactions.api.fetch_transactions?filters={"doc_id": "${id}"}`,
        { headers: headers }
      );
      const resData = response?.data?.message?.data;
      const latestData = resData?.sort(
        (a, b) => new Date(b?.request_time) - new Date(a?.request_time)
      );
      setTransaction({ transactionData: latestData, loading: false });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTransactions();
    }
  }, [id]);

  const statusColors = {
    approved: "#E3F6E5",
    Opne: "#E3F6E5",
    Success: "#E3F6E5",
    Failure: "#FDEBEC",
    executed: "#E3F6E5",
    closed: "#E2E2E2",
    open: "#FFF4D9",
    "approval sent": "#EAEAF7",
  };

  const statusTextColors = {
    approved: "#0FA958",
    Success: "#0FA958",
    Open: "#0FA958",

    Failure: "#E92F39",
    executed: "#0FA958",
    closed: "#848484",
    open: "#F49C05",
    "approval sent": "#6F6AF8",
  };

  const columns1 = [
    {
      title: "Req. Date",
      dataIndex: "request_time",
      key: "request_time",
      render: (date) => {
        const res = dateFormat(date);
        return res;
      },
      fixed: "left",
      width: 100,
      //   ellipsis: true,
    },
    {
      title: "Request ID",
      dataIndex: "id",
      key: "id",
      // ellipsis: true,
    },
    {
      title: "Request Time",
      dataIndex: "request_time",
      key: "request_time",
      render: (date) => {
        const res = formatTime(date, "HH:mm:ss");
        return res;
      },
    },
    {
      title: "Transaction ID",
      dataIndex: "order_id",
      key: "order_id",
      render: (text) => (text ? text : "-"),
      // ellipsis: true,
      width: 350,
    },
    {
      title: "Stock ID",
      dataIndex: "tradingsymbol",
      key: "tradingsymbol",
      render: (text, r) => {
        return r?.order_details?.data?.tradingsymbol || "-";
      },
      // (r?.stocks?.stock_name ? r?.stocks?.stock_name : "-"),
      // ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => {
        return (
          <span style={{ color: text === "buy" ? "#0FA958" : "#EF4D56" }}>
            {text}
          </span>
        );
      },
    },
    {
      title: "Request Type",
      dataIndex: "req_type",
      key: "req_type",
      // ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      // ellipsis: true,
      render: (text, r) => {
        return r?.order_details?.data?.quantity || 0 + " " + "shares";
      },
    },
    {
      title: "Amount",
      dataIndex: "stc_amt",
      key: "stc_amt",
      render: (t, r) => {
        const price = r?.order_details?.data?.averageprice || 0;
        const quantity = r?.order_details?.data?.quantity || 0;
        return price * parseInt(quantity, 10);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // ellipsis: true,
      render: (text) => {
        return (
          <div
            style={{
              width: "120",
              backgroundColor: statusColors[text] || "#F5F5F5",
              color: statusTextColors[text] || "#000000",
              borderRadius: "20px",
              textAlign: "center",
              paddingBottom: "0.2rem 0",
            }}
          >
            {text}
          </div>
        );
      },
    },
  ];
  return (
    <div className="transaction_div">
      <h1 className="transaction-heading">Transaction History</h1>
      <h4 style={{ fontSize: "12px", fontWeight: "400" }}>
        Customer Name: <strong>{name}</strong> | Customer ID:{" "}
        <strong>{id}</strong>
      </h4>
      <Row>
        <Col md={24} sm={22} xs={24} span={24}>
          <Card
            className="customerDataTable transaction"
            style={{ overflow: "auto" }}
            extra={
              <Input
                prefix={<SearchOutlined style={{ color: "#A49F9F" }} />}
                placeholder="Search by Stock ID, Request ID & Transaction ID"
                className="search_input"
                //   onChange={(e) => setSearchText(e.target.value)} // Update search input
                //   value={searchText} // Set input value
              />
            }
          >
            {loading ? (
              <Spin tip="Loading..." size="large" className="customSpinner">
                <div style={{ minHeight: "200px" }}></div>
              </Spin>
            ) : (
              transactionData && (
                <Table
                  virtual
                  columns={columns1}
                  className="customer_detail_table"
                  dataSource={transactionData}
                  pagination={false}
                  scroll={{
                    y: tableHeight,
                    x: 1400,
                  }}
                />
              )
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TransactionHistory;
