import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Modal,
  Typography,
  message,
  Table,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import "./assignModel.css";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { isTokenExpired, refreshAccessToken, logoutUser } from "../utils/auth";
import CONSTANTS from "../../constants/variables";

const { Text } = Typography;

function PortfolioAssignModal({
  openPortfolioAssignModal,
  setOpenPortfolioAssignModal,
  portfolioid,
  portfoliotitle,
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
 
  

  useEffect(() => {
    let accessToken = Cookies.get("AccessToken");

    const fetchData = async () => {
      const headers = {
        "X-Custom-Token": "frappe",
        Authorization: `Bearer ${accessToken}`,
      };

      setLoading(true);

      try {
        const response = await axios.get(
          `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.fetch_all_customers?filters={"doc_id":"${portfolioid}"}`,

          {
            headers: headers,
          }
        );
        const customers = response?.data?.message?.data.map(
          (customer, index) => ({
            key: customer?.name, // Adding a unique key for each row
            id: customer?.name,
            name: customer?.customer_name,
            portfolio: customer?.portfolio?.portfolio_name,
            value: customer?.portfolio_val.toFixed(2), // Formatting portfolio value to 2 decimal places
            risk_profile: customer?.risk_profile, // Assuming you want to add a default risk value; this can be adjusted based on your logic
            is_matched: customer?.is_matched,
          })
        );
        const matchedCustomers = customers
          .filter((customer) => customer.is_matched) // Get all matched customers
          .map((customer) => customer.key);
        setData(customers);
        setSelectedRowKeys(matchedCustomers);
        setLoading(false);
      } catch (error) {
        message.error("Failed to fetch customer data");
      }
    };

    fetchData();
  }, []);

  const handleClickSave = async () => {
    let accessToken = Cookies.get("AccessToken");

    const headers = {
      "X-Custom-Token": "frappe",
      Authorization: `Bearer ${accessToken}`,
    };

    const matchedCustomers = data.filter(customer => customer.is_matched).map(customer => customer.key);

    const customersToUnassign = matchedCustomers.filter(customer => !selectedRowKeys.includes(customer));
  
    const customersToAssign = selectedRowKeys;
  
    try {
      // Unassign customers
      if (customersToUnassign.length > 0) {
        await axios.post(
          `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.update_customer_portfolio`,
          {
            data: {
              doc_ids: customersToUnassign,
              portfolio_id: "", 
            },
          },
          {
            headers: headers,
          }
        );
      }
  
      // Assign customers
      if (customersToAssign.length > 0) {
        const response = await axios.post(
          `${CONSTANTS?.BACKEND_API_BASEURL}/api/method/stock_portfolio_management.portfolio_master.doctype.customers.api.update_customer_portfolio`,
          {
            data: {
              doc_ids: customersToAssign,
              portfolio_id: portfolioid,
            },
          },
          {
            headers: headers,
          }
        );
        message.success(response?.data?.message?.msg);
      }
  
      setOpenPortfolioAssignModal(false);
    } catch (err) {
      message.error("Portfolio update failed for customers");
    }
  };

  const statusColors = {
    Conservative: "#FEE7CC",
    Balanced: "#E3F6E5",
    Aggressive: "#FDEBEC",
    High: "#FDEBEC",
    "Moderately Balanced": "#E3F6E5",
    "Moderately Aggressive": "#FDEBEC",
    "Not Assigned": "#E2E2E2",
    Inactive: "#FDEBEC",
    Active: "#E3F6E5",
    Less: "#E3F6E5",
  };

  const statusTextColors = {
    Conservative: "#F2941E",
    Balanced: "#0FA958",
    Aggressive: "#E92F39",
    high: "#E92F39",
    "Moderately Balanced": "#0FA958",
    "Moderately Aggressive": "#E92F39",
    "Not Assigned": "#848484",
    Inactive: "#E92F39",
    Active: "#0FA958",
    Less: "#0FA958",
  };

  const columns = [
    {
      title: "Customer ID",
      dataIndex: "id",
      key: "id",
      width: 120,
      sorter: (a, b) => a.id.localeCompare(b.id),
      fixed: "left",
    },
    { title: "Name", dataIndex: "name", key: "name", width: 350 },
    {
      title: "Portfolio",
      dataIndex: "portfolio",
      key: "portfolio",
      width: 200,
    },
    {
      title: "Portfolio Value",
      dataIndex: "value",
      key: "value",
      width: 150,
      sorter: (a, b) => a.value - b.value,
    },
    {
      title: "Risk Profile",
      dataIndex: "risk_profile",
      key: "risk_profile",
      width: 150,
      align: "Left",
      filters: [
        { text: "Conservative", value: "Conservative" },
        { text: "Moderately Conservative", value: "Moderately Conservative" },
        { text: "Balanced", value: "Balanced" },
        { text: "Moderately Aggressive", value: "Moderately Aggressive" },
        { text: "Aggressive", value: "Aggressive" },
        { text: "Not Assigned", value: "Not Assigned" },
      ],
      onFilter: (value, record) => record?.risk_profile === value,
      render: (_, record) => (
        <div
          style={{
            backgroundColor: statusColors[record?.risk_profile] || "#F5F5F5",
            color: statusTextColors[record?.risk_profile] || "#000000",
            padding: "3px",
            borderRadius: "12px",
            textAlign: "center",
            // width: "100px",
          }}
        >
          {/* {record?.risk_profile} */}
          {record?.risk_profile.charAt(0).toUpperCase() +
            record?.risk_profile.slice(1).toLowerCase()}
        </div>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const reorderedData = [
    ...data.filter((item) => selectedRowKeys.includes(item.key)),
    ...data.filter((item) => !selectedRowKeys.includes(item.key)),
  ];

  const filteredData = reorderedData?.filter(
    (item) =>
      item?.name.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.risk_profile.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.id.toLowerCase().includes(searchText.toLowerCase())
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      key: record.id, // Ensure the correct key is used
      // checked: record.is_matched,
    }),
  };

  const onCancel = () => {
    setOpenPortfolioAssignModal(false);
  };

  const onSave = () => {
    handleClickSave();
  };

  return (
    <Modal
      open={openPortfolioAssignModal}
      className="assignPortfolioModal"
      onCancel={onCancel}
      onOk={onSave}
      okText="Save"
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "90%",
          marginLeft: "2rem",
          // margin:"1rem auto",
          marginBottom: "1rem",
        }}
      >
        <div className="header">
          <h3>Assign Modal Portfolio to Customers</h3>
          <h6>Model Potfolio: {portfoliotitle}</h6>
        </div>
        <Input
          placeholder="Search by Name, ID, Risk Profile"
          prefix={
            <SearchOutlined
              style={{ color: "#bfbfbf", height: "12", widows: "12" }}
            />
          }
          className="searchbar"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>

      {/* <div className="scrollableDiv">  */}
      {loading ? (
        <Spin className="customSpinner" size="large" tip="Loading...">
          <div style={{ minHeight: "400px" }}></div>
        </Spin>
      ) : (
        <Table
          virtual
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          className="customTable"
          scroll={{
            y: 450,
            x: 1000,
          }}
        />
      )}
      {/* </div> */}
    </Modal>
  );
}

export default PortfolioAssignModal;
